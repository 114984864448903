import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import Modal from "react-modal";
import plusIcon from "../../assets/images/icons/Icon feather-plus.png";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import SideBarMenu from "../Common/SideBarMenu";

const Settings = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const [numberofClients, setnumberofClients] = useState();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [isSubmit, setisSubmit] = useState(false);
  const [error, seterror] = useState();
  const [loading, setLoading] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height: "255px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#FFF",
      borderRadius: "20px",
      paddingTop: "70px",
      width: "90%",
      maxWidth: "500px",
      boxShadow: "0px 0px 24px -10px #bdbdbd",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  // ==================

  useEffect(() => {
    if (isSubmit === true) {
      if (numberofClients === "") {
        seterror("Please enter no of Clients");
      }
      try {
        setLoading(true);
        API.Profile.UpdateProfileAPI({
          data: {
            maxClient: numberofClients,
          },
          UserId: UserData?.userdata?._id,
        }).then((response) => {
          // console.log(response?.data);
          // toast.success("update successfull");
          // navigate(`../${AdminRoute?.Auth?.Dashboard}`);
          setLoading(false);
          closeModal();
          setisSubmit(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setisSubmit(false);
      }
    }
  }, [isSubmit]);

  // ==========================

  const handlesubmit = (e) => {
    e.preventDefault();
    setisSubmit(true);
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header isBack={true} header={"Settings"}></Header>
        <div className="desc-container w-100" style={{ padding: 0 }}>
          <div
            className="settings-menu cursor-pointer"
            onClick={() =>
              navigate(`../${AdminRoute?.Auth?.DisplayMyCalender}`)
            }
          >
            My Calender
            <img
              style={{ top: "18px" }}
              src={rightArrow}
              className="right-arr"
            />
          </div>
          <div
            className="settings-menu cursor-pointer"
            onClick={() => openModal()}
          >
            Edit No. of Max Clients
            <img
              src={rightArrow}
              style={{ top: "18px" }}
              className="right-arr"
            />
          </div>
          <div
            className="settings-menu cursor-pointer"
            onClick={() => navigate(`../${AdminRoute?.Auth?.MyReferrel}`)}
          >
            My Referrels
            <img
              src={rightArrow}
              style={{ top: "18px" }}
              className="right-arr"
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button
          onClick={closeModal}
          className="close-btn "
          style={{ top: "23px", zIndex: "100", right: "27px" }}
        >
          <img src={plusIcon} />{" "}
        </button>
        <p className="pop-up-head-containter">Max Clients</p>
        <p className="my-3" style={{ fontSize: "14px" }}>
          Edit no. of max clients
        </p>
        <input
          type="number"
          placeholder="No of Clients"
          className="mb-3"
          name="Title"
          style={{ background: "#EFF2F2" }}
          onChange={(e) => setnumberofClients(e.target.value)}
        />
        <p className="text-danger mb-0">{error}</p>
        <button
          type="submit"
          className="button_common w-100 mt-2 mb-0 text-center"
          role="button"
          onClick={(e) => handlesubmit(e)}
        >
          Done
        </button>
        {loading ? <div className="loader_orange"></div> : <></>}
      </Modal>
    </div>
  );
};

export default Settings;
