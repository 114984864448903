import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import backarrow from "../../assets/images/icons/back-arrow.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import AdminRoute from "./../../Route/RouteDetails";
import userImg from "../../assets/images/icons/userIMG.jpg";
import baseApi from "../../Api/config";

const Registration1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailLoc = JSON.parse(queryParams.get("email"));
  const mobile_noLoc = JSON.parse(queryParams.get("mobile_no"));
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");

  const goBackFun = () => {
    navigate(-1);
    localStorage.removeItem("Mobileno");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("ProfileImage");
  };

  const initialValues = {
    Name: "",
    Email: emailLoc,
    PhoneNumber: mobile_noLoc,
    Age: "",
    Gender: "",
    Surname: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // ==========Upload image api===========

  const handleUpload = (e) => {
    // console.log(e.target.files[0].name);
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      // console.log(err.res);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
  };

  const RegisterValidate = (values) => {
    // console.log("===> ok", values);
    const errors = {};
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    const nameRegex = /^[A-Za-z\s-]+$/;
    const ageRegex = /^\d+$/;
    const minNumber = 1;
    const maxNumber = 120;

    if (!values?.Name) {
      errors.Name = "Please enter first name!";
    } else if (!nameRegex.test(values?.Name)) {
      errors.Name = "Please enter valid name!";
    }
    if (!values?.Surname) {
      errors.Surname = "Please enter last name!";
    } else if (!nameRegex.test(values?.Surname)) {
      errors.Surname = "Please enter valid last name!";
    }
    if (!values?.PhoneNumber) {
      errors.PhoneNumber = "Please enter mobile no!";
    }
    if (!values?.Email) {
      errors.Email = "Please enter email!";
    } else if (!emailRegrex.test(values?.Email)) {
      errors.Email = "Please enter valid email!";
    }
    // if (!values?.Age) {
    //   errors.Age = "Please enter age!";
    // } else if (!ageRegex.test(values?.Age)) {
    //   errors.Age = "Please enter valid age!";
    // } else if (values?.Age < minNumber || values?.Age > maxNumber) {
    //   errors.Age = "Please enter valid age!";
    // }

    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }
    if (!values?.Gender) {
      errors.Gender = "Please select gender!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      localStorage.setItem("RegUserData", JSON.stringify(formData));
      navigate(`../${AdminRoute?.Auth?.Registration2}`);
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  useEffect(() => {
    // console.log("======ProfileImage>", ProfileImage);
    // setFormData({ ...formData["ProfileImage"], ProfileImage });
    localStorage.setItem("ProfileImage", JSON.stringify(ProfileImage));
  }, [ProfileImage]);

  const disableFutureDate = () => {
    // const today = new Date().toISOString().split('T')[0];
    // return today;
    const currentDate = new Date();
    const maxDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return maxDate.toISOString().split("T")[0];
  };

  return (
    <>
      <div className="land-container">
        <div className="logo-container land-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div className="login-container regi-cont ">
          <div className="register-therapist-div register-therapist">
            <form>
              <h2 className="heading fw-600 py-2 mb-0">
                <span onClick={() => goBackFun()}>
                  <img className="back-arrow" src={backarrow} />
                </span>
                Registration
              </h2>

              <div className=" d-flex justify-content-center">
                {spinner ? (
                  <>
                    <span className="custom-drag-box">
                      <div className="m-5">
                        <div className="container_loader "></div>
                      </div>
                      <input
                        type="file"
                        id="uploadFile"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                    </span>
                  </>
                ) : ProfileImage ? (
                  <>
                    <span className="custom-drag-box">
                      <div className="d-flex flex-column align-items-center w-100 h-100">
                        <i
                          className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                          aria-hidden="true"
                        ></i>
                        <div
                          className="form-upload-photo-preview2"
                          style={{
                            backgroundImage: `url(${ProfileImage})`,
                          }}
                        ></div>
                        <span className="custom-drag-box-text d-none">
                          Image Uploaded
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          title=" "
                          onChange={(e) => handleUpload(e)}
                        />
                      </div>
                    </span>
                  </>
                ) : (
                  <>
                    <span className="custom-drag-box">
                      <img className="reg-user-img" src={userImg} />
                      <input
                        type="file"
                        id="uploadFile"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                    </span>
                  </>
                )}
              </div>

              <div className="row">
                <div className="mt-2 col-12 col-lg-6">
                  <label className="pb-2">* Enter your First name</label>
                  <input
                    type="text"
                    name="Name"
                    placeholder="Enter your First name"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                  />
                  <p className="text-danger mb-0">{formErrors?.Name}</p>
                </div>
                <div className="mt-2 col-12 col-lg-6">
                  <label className="pb-2">* Enter your Last Name</label>
                  <input
                    type="text"
                    name="Surname"
                    placeholder="Enter your Last Name"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                  />
                  <p className="text-danger mb-0">{formErrors?.Surname}</p>
                </div>
                <div className="mt-2 col-12 col-lg-6">
                  <label className="pb-2">* Email</label>
                  <input
                    type="email"
                    name="Email"
                    defaultValue={emailLoc}
                    placeholder="Enter your E-Mail ID"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                  />
                  <p className="text-danger mb-0">{formErrors?.Email}</p>
                </div>
                <div className="mt-2 col-12 col-lg-6">
                  <label className="pb-2">* Mobile No</label>
                  <input
                    type="tel"
                    id="myNumberInput"
                    name="PhoneNumber"
                    placeholder="Enter mobile no"
                    defaultValue={mobile_noLoc}
                    maxLength={10}
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                  />
                  <p className="text-danger mb-0">{formErrors?.PhoneNumber}</p>
                </div>
                {/* <div className="mt-2 col-12 col-lg-6">
                  <label className="pb-2">* Age</label>
                  <input
                    name="Age"
                    type="tel"
                    id="myNumberInput"
                    maxLength={3}
                    placeholder="Enter your Age"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                  />
                  <p className="text-danger mb-0">{formErrors?.Age}</p>
                </div> */}

                <div className="mt-2 col-12 col-lg-6">
                  <label className="pb-2">* Birth Date</label>
                  <input
                    type="date"
                    placeholder="Enter BirthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                    autoComplete="off"
                    max={disableFutureDate()}
                    required
                  />
                  <p className="text-danger">{formErrors?.birthDate}</p>
                </div>

                <div className="mt-2 col-12 col-lg-6">
                  <label className="pb-2">* Gender</label>
                  <select
                    name="Gender"
                    className="gender-select"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Your Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <p className="text-danger mb-0">{formErrors?.Gender}</p>
                </div>
              </div>

              <button
                type="submit"
                className="button_common w-100 mt-3 mb-3 text-center"
                role="button"
                disabled={
                  isSubmit === true && Object.keys(formErrors).length === 0
                    ? true
                    : false
                }
                onClick={(e) => handleSubmit(e)}
              >
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration1;
