import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import bellIcon from "../../assets/images/icons/Icon ionic-ios-notifications-outline.png";
import activityListIcon from "../../assets/images/icons/activityList.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import profileImg from "../../assets/images/icons/Icon awesome-user-circle.png";
import backBtn from "../../assets/images/icons/Icon ionic-ios-arrow-back.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import Setting from "../../assets/images/icons/Path 15092.png";
import Button from "react-bootstrap/Button";
import config from "../../agora-manager/config";
import baseApi from "../../Api/config";

const Header = (props) => {
  // console.log(props.isBack);
  const navigate = useNavigate();
  const onBackClick = () => {
    navigate(-1);
  };
  const [UserInfo, setUserInfo] = useState([]);

  useEffect(() => {
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          // console.log(response?.data?.data);
          setUserInfo(response?.data?.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // ========== Join Session ==========

  const renderActionButton = (SessionId) => {
    // return joined ? (
    //     <button onClick={handleLeaveClick}>Leave</button>
    // ) : (
    //     <button onClick={handleJoinClick}>Join</button>
    // );
    navigate(`../${AdminRoute.Auth.VideoCall}/${SessionId}`);
  };
  config.selectedProduct = "rtc";

  return (
    <div className="app-header">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "100%" }}
      >
        <div>
          {" "}
          <>
            {props.isBack ? (
              <span onClick={() => onBackClick()}>
                <img
                  className="cursor-pointer"
                  src={backBtn}
                  style={{
                    height: "30px",
                    height: "23px",
                    marginLeft: "16px",
                    opacity: "0.7",
                  }}
                />
              </span>
            ) : (
              <img
                onClick={() => navigate(`../${AdminRoute?.Auth?.Profile}`)}
                className="form-upload-photo-preview3  cursor-pointer"
                style={{ height: "30px" }}
                src={UserInfo?.image ? UserInfo?.image : profileImg}
              />
            )}
          </>
        </div>
        <div>
          {props.header ? (
            <>
              <label
                className="header-labl sub-heading"
                onClick={() =>
                  navigate(
                    `../${AdminRoute?.Auth.GroupDetails}/${props.GroupId}`
                  )
                }
              >
                {props.isGroupImg ? (
                  <img
                    className="header-group-img rounded-circle me-2"
                    src={`${baseApi.baseurl}images/${props.isGroupImg}`}
                  />
                ) : (
                  <img
                    className="header-group-img rounded-full me-2"
                    src={profileImg}
                  />
                )}

                {props.header}
              </label>
            </>
          ) : (
            <>
              <img style={{ height: "30px" }} src={rtylifeLogo} />
            </>
          )}
        </div>
        <div>
          {props.isSetting ? (
            <>
              <img
                onClick={() => navigate(`../${AdminRoute?.Auth?.Settings}`)}
                className="header-img setting_icon  cursor-pointer"
                style={{ height: "26px", width: "26px" }}
                src={Setting}
              />
            </>
          ) : (
            <>
              <img
                className="header-img cursor-pointer me-3"
                src={activityListIcon}
                onClick={() =>
                  navigate(`../${AdminRoute?.Auth?.MyActivityList}`)
                }
              />

              <img
                className="header-img cursor-pointer"
                onClick={() =>
                  navigate(`../${AdminRoute?.Auth?.Notifications}`)
                }
                style={{ height: "25px", marginLeft: "8px" }}
                src={bellIcon}
              />
            </>
          )}
          {props.isJoinSessionbtn ? (
            <span className="button">
              <Button
                type="submit"
                onClick={() => renderActionButton(1)}
                className="button_common m-0 p-2"
              >
                <span className="white-text">Join Group Session</span>
              </Button>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
