import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import profileImg from "../../assets/images/icons/Icon awesome-user-circle.png";
import groupIcon from "../../assets/images/icons/Group 21730.png";
import groupIcon2 from "../../assets/images/icons/Group 21756.png";

import followUpDashboard from "../../assets/images/dashboard/followups.jpg";
import playgroundDashboard from "../../assets/images/dashboard/playground.jpg";

import AdminRoute from "../../Route/RouteDetails";
import { useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import commoan from "../../Api/config";
import JoinSession from "../JoinSession";

const Dashboard = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  // const [UpcommingList, setUpcommingList] = useState([]);
  // const [ClientName, setClientName] = useState("");
  const [GroupList, setGroupList] = useState([]);
  const [ClientActiveList, setClientActiveList] = useState([]);
  const [loading, setLoading] = useState(true);

  //api call for upcommming session

  // const upcomingListfun = () => {
  //   try {
  //     API.Client.UpcomingListdashboard({}).then((response) => {
  //       console.log("Sessions====>", response?.data?.data_all);
  //       if (response.data.status === 1) {
  //         setUpcommingList(response?.data?.data_all[0]);
  //         setClientName(
  //           `${response?.data?.data_all[0]?.client[0]?.name} ${response?.data?.data_all[0]?.client[0]?.last_name}`
  //         );
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getGroupList = () => {
    setLoading(true);
    try {
      API.Group.GroupList({}).then((response) => {
        // console.log("Grouplist", response?.data?.data_all);
        setGroupList(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const ClientActiveListfun = () => {
    setLoading(true);
    try {
      API.Client.ClientActiveList({
        UserId: UserData?.userdata?._id,
        isApproved: "1",
      }).then((response) => {
        // console.log("ClientActiveList", response?.data?.data_all);
        setClientActiveList(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // upcomingListfun();
    getGroupList();
    ClientActiveListfun();
  }, []);

  //   console.log("UpcommingList", UpcommingList?.client[0]?.name);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>

      <div className="content">
        <Header></Header>
        <MobMenuBar></MobMenuBar>
        {loading ? (
          <div className="loader_orange"></div>
        ) : (
          <div
            className="dashboard-container pt-0"
            // style={{ maxHeight: "calc(100% - 160px)" }}
          >
            <div className="row px-2 pt-2">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <div className="dashboard-inner h-100">
                  <div className="sub-head px-3">
                    <p className="sub-heading">Sessions</p>
                    <p className="sub-lbl mb-2">
                      Here is an overview of your session's latest activity.
                    </p>
                  </div>
                  <div className="px-0">
                    <JoinSession />
                    {/* <div className="common-card"> */}
                    {/* <div className="d-flex justify-content-between mb-2">
                    <label className="sub-lbl">Today</label>
                    <label
                      className="sub-lbl cursor-pointer"
                      onClick={() => navigate(`../${AdminRoute.Auth.Sessions}`)}
                    >
                      View All
                    </label>
                  </div> */}
                    {/* {UpcommingList?.length &&
                  UpcommingList?.map((val, i) => {
                    return (
                      <>
                        {" "}
                        <div
                          key={i}
                          className="d-flex justify-content-between align-items-center session-cont"
                        >
                          <div className="time-div">
                            <strong>12:40 </strong> | PM
                          </div>
                          <label className="session-user-lbl ">
                            Suvarna Nandkrishna
                          </label>
                          <button className="common-btn">Join</button>
                        </div>
                        <br />
                      </>
                    );
                  })} */}
                    {/* {UpcommingList ? (
                    <>
                      {" "}
                      <div className="d-flex justify-content-between align-items-center session-cont">
                        <div className="time-div">
                          <strong>{UpcommingList?.timestart}</strong>
                        </div>
                        <label className="session-user-lbl ">
                          {ClientName}
                          
                        </label>
                        <button
                          className="common-btn"
                          onClick={() =>
                            navigate(
                              `../${AdminRoute?.Auth?.JoinSession}/${UpcommingList?._id}`
                            )
                          }
                        >
                          Join
                        </button>
                      </div>
                      <br />
                    </>
                  ) : (
                    <h3>No Sessions</h3>
                  )} */}

                    {/* ================================== */}

                    {/* </div> */}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <div className="dashboard-inner pb-3 h-100">
                  <div className="sub-head px-3">
                    <p className="sub-heading  d-flex align-items-center">
                      Clients{" "}
                      {ClientActiveList?.length ? (
                        <span>
                          <label className="common-count mx-2">
                            {ClientActiveList?.length}
                          </label>
                        </span>
                      ) : (
                        <></>
                      )}
                      <label
                        className="view-all-lbl cursor-pointer"
                        onClick={() =>
                          navigate(`../${AdminRoute.Auth.ClientList}`)
                        }
                      >
                        View All
                      </label>
                    </p>
                    <p className="sub-lbl mb-2">
                      Here is an overview of your session's latest activity.
                    </p>
                  </div>
                  <div className="common-list-hor px-3 ">
                    {!ClientActiveList?.length ? (
                      <h4>No active clients.</h4>
                    ) : (
                      //   <div className="alert alert-warning" role="alert">
                      //  User Not Available
                      // </div>
                      ClientActiveList?.length &&
                      ClientActiveList.slice(0, 4)?.map((val, i) => {
                        return (
                          <div
                            key={i}
                            className="client-list-item mx-2 cursor-pointer"
                            // onClick={() =>
                            //   navigate(
                            //     `${AdminRoute?.Auth?.ClientDetails}/${val?.client[0]?._id}`
                            //   )
                            // }
                          >
                            <img
                              style={{ height: "20px", margin: "10px" }}
                              src={profileImg}
                            />
                            <p className="mb-0 client-lbl">
                              <span className="fw-bold">
                                {val?.client[0]?.name}{" "}
                              </span>{" "}
                              {val?.client[0]?.last_name}
                            </p>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <div className="dashboard-inner pb-3">
                  <div className="sub-head px-3">
                    <p className="sub-heading">
                      Your Groups{" "}
                      <label
                        className="view-all-lbl cursor-pointer"
                        onClick={() => navigate(`../${AdminRoute.Auth.Groups}`)}
                      >
                        View All
                      </label>
                    </p>
                  </div>
                  <div className="common-list-hor px-3">
                    {!GroupList?.length ? (
                      <h4 className="mt-2">Groups not created.</h4>
                    ) : (
                      GroupList?.length &&
                      GroupList?.map((val, i) => {
                        return (
                          <div
                            className="group-card cursor-pointer col-4 col-md-2"
                            key={i}
                            onClick={() =>
                              navigate(
                                `../${AdminRoute.Auth.ChatBox}/${val?._id}`
                              )
                            }
                          >
                            <div className="group-image d-flex justify-content-center align-items-center">
                              <img
                                style={{}}
                                // src={groupIcon}
                                src={
                                  `${commoan.baseurl}` + `images/` + val?.logo
                                }
                              />
                            </div>
                            <p className="mb-0 mt-2 group-card-lbl">
                              {val?.name}
                            </p>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div className="sub-head mt-3 px-0">
                  <p className="sub-heading">Follow Ups</p>
                  <p className="sub-lbl m-0">
                    Here is an overview of latest clients assigned to you.
                  </p>
                </div>
                <div
                  className="info-div px-0 pb-0"
                  onClick={() =>
                    navigate(`../${AdminRoute.Auth.MyActivityList}`)
                  }
                >
                  <div className="info-card cursor-pointer dashboard-inner-boxes">
                    <img src={followUpDashboard} />

                    <p className="info-par px-2 mb-1">
                      Unleash your creativity and recharge your mind with
                      playful exercises designed for peak performance.
                    </p>
                    <p style={{ width: "100%" }} className="m-0 px-2">
                      {" "}
                      <strong>Know More</strong>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div className="sub-head mt-3 px-0">
                  <p className="sub-heading">Playground</p>
                  <p className="sub-lbl m-0">
                    Here is an overview of playground section.
                  </p>
                </div>
                <div className="info-div px-0">
                  <div
                    className="info-card cursor-pointer dashboard-inner-boxes"
                    onClick={() => navigate(`../${AdminRoute.Auth.Playground}`)}
                  >
                    <img src={playgroundDashboard} />

                    <p className="info-par px-2 mb-1">
                      Unleash your creativity and recharge your mind with
                      playful exercises designed for peak performance.
                    </p>

                    <p style={{ width: "100%" }} className="m-0 px-2">
                      {" "}
                      <strong>Know More</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
