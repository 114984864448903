import React from "react";
import ReactApexChart from "react-apexcharts";

const RadarChart = ({ options, series, type }) => {
  // const config = {
  //     options: {
  //         chart: {
  //             dropShadow: {
  //                 enabled: true,
  //                 blur: 1,
  //                 left: 1,
  //                 top: 1
  //             }
  //         },
  //         labels: [
  //             "Engagement",
  //             "Leadership",
  //             "Burnout"
  //         ],
  //     },
  //     series: [
  //         {
  //             name: "OHEP Index",
  //             data: [
  //                 4.9,
  //                 3.9,
  //                 3.6
  //             ]
  //         }
  //     ]
  // };

  return (
    <div style={{ height: "200px" }}>
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        height={type === "line" ? "200" : "300"}
        // width={300}
      />
    </div>
  );
};

export default RadarChart;
