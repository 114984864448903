const RouteName = {
  Auth: {
    Home: "/home",
    Login: "/login",
    Registration1: "/register1",
    Registration2: "/register2",
    VerifyOtp: "/VerifyOtp",
    verifyOtpMail: "/verifyotpmail",
    Dashboard: "/",
    Profile: "/profile",
    ProfileEdit: "/profile-edit",
    MyCalender: "/my-calender",
    DisplayMyCalender: "/displaycalender",
    MyActivityList: "/my-activity-list",
    ChatBox: "/ChatBox",
    Groups: "/groups",
    GroupDetails: "/group-details",
    MembersSelection: "/members-selection",
    ClientList: "/client-list",
    ClientDetails: "/client-details",
    ClientViewDetDemo: "/clien-vew-demo/client-view",
    Sessions: "/sessions",
    SessionDetails: "/session-details",
    Settings: "/settings",
    MyReferrel: "/my-referrel",
    SelectReferral1: "/select-referral1",
    SelectReferral2: "/select-referral2",
    DigitalNotes: "/digital-notes",
    AiAsistance: "/ai-asistance",
    AddEditSessionDetails: "/add-edit-session-det",
    Playground: "/playground",
    JoinSession: "/join-session",
    // FollowUpList: "/follow-ups",
    Notifications: "/notifications",
    VideoCall: "/VideoCall",
  },
  PageNotFound: "*",
};

export default RouteName;
