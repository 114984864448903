import React, { useState, useEffect } from "react";
import MobMenuBar from "../Common/MobMenuBar";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import moment from "moment";
import Header from "../Common/Header";
import API from "../../Api/Api";
// import io from "socket.io-client";
import { socket } from "../../socket";


const Notifications = () => {
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [isChecked, setIschecked] = useState(false);
  const [room] = useState("notification");
  const [notification, setNotification] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (!socket || !room) return;
  //   socket.connect();

  //   const handleData = (data) => {
  //     setNotification((list) => [data, ...list]);
  //   };

  //   socket.on(room, handleData);

  //   return () => {
  //     socket.off(room, handleData); // Use the same handler to properly remove it
  //     socket.disconnect();
  //   };
  // }, [room]);

  useEffect(() => {
    setLoading(true);
    API.NotificationApis.getAllnotificationApi()
      .then((response) => {
        // console.log("Notification", response?.data?.data_all);
        if (response.data.status === 1) {
          setNotification(response?.data?.data_all);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header header={"Notifications"} isBack={true}></Header>
        <MobMenuBar></MobMenuBar>
        <div className="dashboard-container">
          <div className="client-name-list" style={{ maxHeight: "calc(100%)" }}>
            {/* <div className="d-flex justify-content-start">
              <label className="day-lbl2">Today</label>
            </div> */}

            {loading ? (
              <div className="loader_orange"></div>
            ) : (
              notification &&
              notification.map(
                ({ title, description, createdAt, type, receiver_id }, i) => {
                  return (
                    <>
                      {type === "all" ||
                      receiver_id === UserData.userdata._id ? (
                        <div
                          key={i}
                          onClick={() => setIschecked(!isChecked)}
                          className="client-name-card d-flex flex-row"
                          style={{ background: "#FFFFFF" }}
                        >
                          <div className="vert-div"></div>
                          <div className="content-par mx-3">
                            <p className="m-0 follow-up-header">{title}</p>
                            <p
                              className="follow-up-subhead"
                              style={{
                                textDecoration: isChecked
                                  ? "line-through"
                                  : "auto",
                              }}
                            >
                              {description}
                            </p>
                            <p
                              className="mt-2 mb-0"
                              style={{
                                position: "relative",
                              }}
                            >
                              {moment(createdAt).format("LT")}
                              {isChecked ? (
                                <>
                                  <span>
                                    <label className="check_container">
                                      <input type="checkbox" checked={true} />
                                      <span
                                        className="checkmark"
                                        style={{ top: "17px" }}
                                      ></span>
                                    </label>
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                }
              )
            )}

            {/* 
            <div
              onClick={() => setIschecked(!isChecked)}
              className="client-name-card d-flex flex-row"
              style={{ background: "#FFFFFF" }}
            >
              <div className="vert-div"></div>
              <div className="content-par mx-2">
                <p className="m-0 follow-up-header">Create Activity</p>
                <p
                  className="follow-up-subhead"
                  style={{
                    textDecoration: isChecked ? "line-through" : "auto",
                  }}
                >
                  Here is an overview of your letest activities
                </p>
                <p
                  className="my-2"
                  style={{
                    position: "relative",
                  }}
                >
                  Today 2:00PM
                  {isChecked ? (
                    <>
                      <span>
                        <label className="check_container">
                          <input type="checkbox" checked={true} />
                          <span
                            className="checkmark"
                            style={{ top: "17px" }}
                          ></span>
                        </label>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>

            <div
              onClick={() => setIschecked(!isChecked)}
              className="client-name-card d-flex flex-row"
              style={{ background: "#FFFFFF" }}
            >
              <div className="vert-div"></div>
              <div className="content-par mx-2">
                <p className="m-0 follow-up-header">
                  3 people joined your 'Fight Anxiety' group
                </p>
                <p
                  className="follow-up-subhead"
                  style={{
                    textDecoration: isChecked ? "line-through" : "auto",
                  }}
                >
                  Congratulations! Your support group is here
                </p>
                <p
                  className="my-2"
                  style={{
                    position: "relative",
                  }}
                >
                  Today 2:00PM
                  {isChecked ? (
                    <>
                      <span>
                        <label className="check_container">
                          <input type="checkbox" checked={true} />
                          <span
                            className="checkmark"
                            style={{ top: "17px" }}
                          ></span>
                        </label>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>

            <div
              onClick={() => setIschecked(!isChecked)}
              className="client-name-card d-flex flex-row"
              style={{ background: "#FFFFFF" }}
            >
              <div className="vert-div"></div>
              <div className="content-par mx-2">
                <p className="m-0 follow-up-header">Create Activity</p>
                <p
                  className="follow-up-subhead"
                  style={{
                    textDecoration: isChecked ? "line-through" : "auto",
                  }}
                >
                  Here is an overview of your letest activities
                </p>
                <p
                  className="my-2"
                  style={{
                    position: "relative",
                  }}
                >
                  Today 2:00PM
                  {isChecked ? (
                    <>
                      <span>
                        <label className="check_container">
                          <input type="checkbox" checked={true} />
                          <span
                            className="checkmark"
                            style={{ top: "17px" }}
                          ></span>
                        </label>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>

            <div
              onClick={() => setIschecked(!isChecked)}
              className="client-name-card d-flex flex-row"
              style={{ background: "#FFFFFF" }}
            >
              <div className="vert-div"></div>
              <div className="content-par mx-2">
                <p className="m-0 follow-up-header">Create Activity</p>
                <p
                  className="follow-up-subhead"
                  style={{
                    textDecoration: isChecked ? "line-through" : "auto",
                  }}
                >
                  Here is an overview of your letest activities
                </p>
                <p
                  className="my-2"
                  style={{
                    position: "relative",
                  }}
                >
                  Today 2:00PM
                  {isChecked ? (
                    <>
                      <span>
                        <label className="check_container">
                          <input type="checkbox" checked={true} />
                          <span
                            className="checkmark"
                            style={{ top: "17px" }}
                          ></span>
                        </label>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
