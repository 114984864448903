import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backarrow from "../../assets/images/icons/back-arrow.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const Registration2 = () => {
  const navigate = useNavigate();
  const regdata = JSON.parse(localStorage.getItem("RegUserData"));
  const ProfileImg = JSON.parse(localStorage.getItem("ProfileImage"));
  const [loading, setLoading] = useState(false);

  const [ApiError, setApiError] = useState("");

  const goBackFun = () => {
    navigate(-1);
    localStorage.removeItem("RegUserData");
    localStorage.removeItem("ProfileImage");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("Mobileno");
  };

  const initialValues = {
    YearOfExp: "",
    Expertise: "",
    Quote: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const RegisterValidate = (values) => {
    // console.log("===> ok", values);
    const errors = {};

    if (!values?.YearOfExp) {
      errors.YearOfExp = "Year of Experience required";
    }
    if (values?.YearOfExp?.length > 2) {
      errors.YearOfExp = "Invalid Years!";
    }
    if (!values?.Expertise) {
      errors.Expertise = "Your Expertise required";
    }
    if (values?.Expertise?.length >= 50) {
      errors.Expertise = "Max 50 characters allowed";
    }
    if (!values?.Quote) {
      errors.Quote = "Quote is required";
    }
    if (values?.Quote?.length >= 50) {
      errors.Quote = "Max 50 characters allowed";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setLoading(true);
      try {
        API.Auth.Register({
          data: {
            fullname: `${regdata?.Name}${" " + regdata?.Surname}`,
            email: regdata?.Email,
            phone: regdata?.PhoneNumber,
            // age: regdata?.Age,
            dob: regdata?.birthDate,
            gender: regdata?.Gender,
            role: "Therapist",
            experience: formData?.YearOfExp,
            experties: formData?.Expertise,
            qote: formData?.Quote,
            image: ProfileImg,
          },
        }).then((response) => {
          // console.log(response?.data);

          if (response?.data?.message === "User create successfully") {
            setApiError(response?.data?.message);
            localStorage.setItem("EmailId", response?.data?.data?.email);
            localStorage.setItem("Mobileno", response?.data?.data?.phone);
            navigate(`../${AdminRoute?.Auth?.VerifyOtp}/2`);
          }
          if (response?.data?.message === "User already registered.") {
            setApiError(response?.data?.message);
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setApiError(error.message);
        setLoading(false);
        // toast.error(error.message);
        // console.log("=====>", error);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  return (
    <>
      <div className="land-container">
        <div className="logo-container ">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div className="login-container regi-cont">
          <div className="register-therapist-div register-therapist">
            <form>
              <h2 className="heading fw-600 py-2">
                <span onClick={() => goBackFun()}>
                  <img className="back-arrow" src={backarrow} />
                </span>
                Registration
              </h2>
              <div className="mt-2">
                <label className="pb-2">* Years of Experience</label>
                <input
                  type="number"
                  id="myNumberInput"
                  name="YearOfExp"
                  placeholder="Enter Years of Experince"
                  required
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                />
                <p className="text-danger">{formErrors?.YearOfExp}</p>
              </div>
              <div className="mt-2">
                <label className="pb-2">* Your Expertise</label>
                <textarea
                  rows="4"
                  name="Expertise"
                  className="form-input-text-area"
                  placeholder="Enter your Expertise"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                ></textarea>
                <p className="text-danger">{formErrors?.Expertise}</p>
              </div>
              <div className="mt-2">
                <label className="pb-2">* Quote you resonate with</label>
                <input
                  type="text"
                  name="Quote"
                  placeholder="Enter the Quote you resonate with"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                />
                <p className="text-danger">{formErrors?.Quote}</p>
              </div>
              <p className="text-danger"> {ApiError}</p>

              <button
                type="submit"
                className="button_common w-100 mt-3 mb-3 text-center"
                role="button"
                disabled={
                  isSubmit === true && Object.keys(formErrors).length === 0
                    ? true
                    : false
                }
                onClick={(e) => handleSubmit(e)}
              >
                Next
              </button>
              {loading ? <div className="loader_orange"></div> : <></>}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration2;
