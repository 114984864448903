import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import plusIco from "../../assets/images/icons/+.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Modal from "react-modal";
import plusIcon from "../../assets/images/icons/Icon feather-plus.png";
import API from "../../Api/Api";
import SideBarMenu from "../Common/SideBarMenu";
import baseApi from "../../Api/config";
import AdminRoute from "../../Route/RouteDetails";
import moment from "moment";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgb(211,205,197, 50%)",
    borderRadius: "20px",
    paddingTop: "70px",
    width: "90%",
    maxWidth: "500px",
    border: "none",
    overflowX: "hidden",
    backdropFilter: "blur(10px)",
  },
};

const Groups = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [activeTab, setActiveTab] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [GimageName, setGimageName] = useState("");
  const [CheckGroupType, setCheckGroupType] = useState("");
  const [GroupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [is_loading, setis_loading] = useState(false);
  // const [loadingSubmitAns, setLoadingSubmitAns] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    setSpinner(false);
    setProfileImage("");
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  // ========= Group List ===============

  const getGroupList = (GroupType) => {
    if (GroupType === "Focused") {
      setActiveTab(0);
      setLoading(true);
      try {
        API.Group.GroupList({
          Grouptype: "focused",
        }).then((response) => {
          // console.log("console", response?.data?.data_all);
          setGroupList(response?.data?.data_all);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    if (GroupType === "Support") {
      setActiveTab(1);
      setLoading(true);
      try {
        API.Group.GroupList({
          Grouptype: "support",
        }).then((response) => {
          // console.log("console", response?.data?.data_all);
          setGroupList(response?.data?.data_all);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      API.Group.GroupList({
        Grouptype: "focused",
      }).then((response) => {
        // console.log("console", response?.data?.data_all);
        setGroupList(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const handleSearchText = (text) => {
    // console.log(text);
    if (activeTab === 0) {
      // console.log(text);
      setLoading(true);
      try {
        API.Group.GroupList({
          Grouptype: "focused",
          serchKey: text,
        }).then((response) => {
          // console.log("console", response?.data?.data_all);
          setGroupList(response?.data?.data_all);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    if (activeTab === 1) {
      setLoading(true);
      // console.log(text);
      try {
        API.Group.GroupList({
          Grouptype: "support",
          serchKey: text,
        }).then((response) => {
          // console.log("console", response?.data?.data_all);
          setGroupList(response?.data?.data_all);
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  // ==========Modal =========

  const initialValues = {
    GName: "",
    GDescription: "",
    GTags: "",
    GMembers: "",
    GStartDate: "",
    GEndDate: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleUpload = (e) => {
    // console.log(e.target.files[0]);
    setLoading(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setLoading(false)
        );
    } catch (err) {
      console.log(err.res);
      setLoading(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
    setGimageName(image);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const Validate = (values, img) => {
    // console.log("===> ok", values);
    const errors = {};
    if (!img) {
      errors.img = "Group image is required !";
    }
    if (!values?.GName) {
      errors.GName = "Name is required !";
    }
    if (!values?.GDescription) {
      errors.GDescription = "Discription is required !";
    }
    if (!values?.GTags) {
      errors.GTags = "Tag is required !";
    }
    if (!values?.GMembers && CheckGroupType === "focused") {
      errors.GMembers = "No Of Members required !";
    }
    if (!values?.GStartDate && CheckGroupType === "focused") {
      errors.GStartDate = "Start Date is required !";
    }
    if (!values?.GEndDate && CheckGroupType === "focused") {
      errors.GEndDate = "End Date is required !";
    }
    if (!values?.Group) {
      errors.Group = "Group Type is required !";
    }
    return errors;
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log("formData", formData);
      setis_loading(true);
      try {
        API.Group.CreateGroups({
          data: {
            name: formData?.GName,
            type: formData?.Group,
            logo: GimageName,
            description: formData?.GDescription,
            members: formData?.GMembers,
            startdate: formData?.GStartDate,
            enddate: formData?.GEndDate,
            created_by: UserData?.userdata?._id,
            tags: formData?.GTags
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            if (CheckGroupType === "support") {
              getGroupList("Support");
              setIsOpen(false);
            } else {
              navigate(
                `../${AdminRoute?.Auth?.MembersSelection}/${response?.data?.data?._id}`
              );
            }
          }
          setis_loading(false);
        });
      } catch (error) {
        console.log(error);
        // toast.error(error.message);
        setis_loading(false);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(Validate(formData, ProfileImage));
    setIsSubmit(true);
  };

  const gotoChat = (e, val) => {
    navigate(`../${AdminRoute.Auth.ChatBox}/${val?._id}`);
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");

    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <div className="app-container">
      <MobMenuBar></MobMenuBar>
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
        <img
          src={plusIcon}
          className="add-group-btn"
          onClick={() => openModal()}
        />
        <div className="group-container">
          <div className="groups-tabs">
            <div
              className="grp-tab-item cursor-pointer"
              onClick={() => {
                getGroupList("Focused");
              }}
              style={{
                background: activeTab === 0 ? "rgb(242,186,63)" : "white",
                color: activeTab === 0 ? "white" : "black",
              }}
            >
              Focused
            </div>
            <div
              className="grp-tab-item cursor-pointer"
              onClick={() => {
                getGroupList("Support");
              }}
              style={{
                background: activeTab === 1 ? "rgb(242,186,63)" : "white",
                color: activeTab === 1 ? "white" : "black",
              }}
            >
              Support
            </div>
          </div>

          <div className="grp-search-cont">
            <input
              placeholder="Search Group"
              className="grp_search"
              onChange={(e) => handleSearchText(e.target.value)}
            />
          </div>

          <div className="group-list-container pt-2">
            {loading ? (
              <div className="loader_orange"></div>
            ) : !GroupList?.length ? (
              <div className="alert alert-warning" role="alert">
                Data not available.
              </div>
            ) : (
              GroupList?.length &&
              GroupList?.map((val, i) => {
                return (
                  <div
                    className="group-list-item"
                    key={i}
                    onClick={(e) => gotoChat(e, val)}
                  >
                    <div className="group-icon-container">
                      <img src={`${baseApi.baseurl}images/${val?.logo}`} />
                      <div>
                        <p className="group-name mb-0">{val?.name}</p>
                        <p className="grp-msg-lbl mb-0">{val?.description}</p>
                      </div>
                    </div>
                    <div>
                      <p className="grp-msg-lbl">
                        {/* {moment(val?.createdAt).add(3, "days").calendar()} */}
                        {moment(val?.createdAt).format("LL")}
                      </p>
                      {/* {val?.msgunread !== 0 ? (
                        <span>
                          <label className="common-count mx-2">
                            {val?.msgunread}
                          </label>
                        </span>
                      ) : null} */}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className="select-group close-btn">
          <img src={plusIco} />{" "}
        </button>
        <div>
          <div className="d-flex justify-content-center ">
            {loading ? (
              <>
                <div className="loader_orange"></div>
                {/* <span className="custom-drag-box"> */}
                {/* <div className="m-3">
                    <div className="container_loader "></div>
                  </div> */}
                {/* <input
                    type="file"
                    id="uploadFile"
                    accept="image/png, image/gif, image/jpeg"
                    tabIndex="1"
                    title=" "
                    onChange={(e) => handleUpload(e)}
                  /> */}
                {/* </span> */}
              </>
            ) : ProfileImage ? (
              <>
                <span className="custom-drag-box">
                  <div className="d-flex flex-column align-items-center w-100 h-100">
                    <i
                      className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                      aria-hidden="true"
                    ></i>
                    <div
                      className="form-upload-photo-preview2"
                      style={{
                        backgroundImage: `url(${ProfileImage})`,
                      }}
                    ></div>
                    <span className="custom-drag-box-text d-none">
                      Image Uploaded
                    </span>
                    <input
                      type="file"
                      id="uploadFile"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      tabIndex="1"
                      title=" "
                      onChange={(e) => handleUpload(e)}
                    />
                  </div>
                </span>
              </>
            ) : (
              <>
                <span className="custom-drag-box">
                  <p style={{ textAlign: "Center" }}>Select Group Icon</p>
                  <img className="group-img" src={plusIcon} />
                  <input
                    type="file"
                    id="uploadFile"
                    accept="image/png, image/gif, image/jpeg"
                    tabIndex="1"
                    title=" "
                    onChange={(e) => handleUpload(e)}
                  />
                </span>
              </>
            )}
          </div>
          <p className="text-danger">{formErrors?.img}</p>

          <p>
            {" "}
            <span className="text-danger">* </span>Group Name
          </p>
          <input
            type="text"
            placeholder="Group Name"
            className="mb-2"
            name="GName"
            onChange={(e) => handleChange(e)}
            autoComplete="off"
          />
          <p className="text-danger">{formErrors?.GName}</p>
          <p>
            {" "}
            <span className="text-danger">* </span>Group Description
          </p>
          <input
            type="text"
            placeholder="Group Description"
            className="mb-2"
            name="GDescription"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
          />
          <p className="text-danger">{formErrors?.GDescription}</p>

          <p>
            <span className="text-danger">* </span>Tags
          </p>
          <input
            type="text"
            placeholder="Tags"
            className="mb-2"
            name="GTags"
            onChange={(e) => handleChange(e)}
            autoComplete="off"
          />
          <p className="text-danger">{formErrors?.GTags}</p>
          <p>
            {" "}
            <span className="text-danger">* </span>Select Group Type
          </p>
          <p className="mb-2 mt-3">
            <input
              type="radio"
              className="mb-2"
              name="Group"
              value="focused"
              onChange={(e) => handleChange(e)}
              onClick={(e) => setCheckGroupType("focused")}
            />{" "}
            <label className="form-in">Focused Group</label>
          </p>

          <p className="mb-2">
            <input
              type="radio"
              className="mb-2"
              name="Group"
              value="support"
              onChange={(e) => handleChange(e)}
              onClick={(e) => setCheckGroupType("support")}
            />{" "}
            <label className="form-in">Support Group</label>
          </p>

          <p className="text-danger">{formErrors?.Group}</p>
          <div
            style={{
              display: CheckGroupType === "support" ? "none" : "block",
              // color: CheckGroupType === 0 ? "white" : "black",
            }}
          >
            <p>
              {" "}
              <span className="text-danger">* </span>Select No. of members
            </p>
            <input
              type="number"
              id="myNumberInput"
              placeholder="Enter No."
              className="mb-2"
              name="GMembers"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
            <p className="text-danger">{formErrors?.GMembers}</p>

            <p>
              {" "}
              <span className="text-danger">* </span>Start Date
            </p>
            <input
              type="date"
              placeholder="Start date"
              className="mb-2"
              name="GStartDate"
              onChange={(e) => handleChange(e)}
              min={disablePastDate()}
            />
            <p className="text-danger">{formErrors?.GStartDate}</p>

            <p>
              {" "}
              <span className="text-danger">* </span>End Date
            </p>
            <input
              type="date"
              placeholder="End Date"
              className="mb-2"
              name="GEndDate"
              onChange={(e) => handleChange(e)}
              min={disablePastDate()}
            />
            <p className="text-danger">{formErrors?.GEndDate}</p>
          </div>
        </div>
        <button
          type="submit"
          className="button_common w-100 mb-2 text-center "
          role="button"
          onClick={(e) => handleSubmit(e)}
        >
          {CheckGroupType === "support" ? "Submit" : "Select Members"}
        </button>
        {is_loading ? <div className="loader_orange"></div> : <></>}
      </Modal>
    </div>
  );
};

export default Groups;
