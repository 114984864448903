import React, { useState, useEffect } from "react";
import MobMenuBar from "../Common/MobMenuBar";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import timeIcon from "../../assets/images/icons/Icon feather-clock.png";
import API from "../../Api/Api";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import AdminRoute from "../../Route/RouteDetails";

const Sessions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [activeIndex, setIndex] = useState(0);
  const [UpcommingList, setUpcommingList] = useState([]);
  const [Session_Summary, setSession_Summary] = useState([]);
  const [loadingUpcomingLst, setLoadingUpcomingList] = useState(false);
  const [loadingSessionSummary, setLoadingSessionSummary] = useState(false);

  const handleTabs = (e) => {
    // console.log("===>", e);
    setIndex(e);
    if (e === 0) {
      upcomingListfun();
    }
    if (e === 1) {
      getSessionSummary_List();
    }
  };

  //api call for upcommming session

  const upcomingListfun = () => {
    setLoadingUpcomingList(true);
    try {
      API.Client.UpcomingList({
        UserId: UserData?.userdata?._id,
        isApproved: "0",
      }).then((response) => {
        if (response.data.status === 1) {
          setUpcommingList(response?.data?.data_all);
        }
        setLoadingUpcomingList(false);
      });
    } catch (error) {
      setLoadingUpcomingList(false);
      console.log(error);
    }
  };

  //APi call for Session_History
  const getSessionSummary_List = () => {
    setLoadingSessionSummary(true);
    try {
      API.Client.SessionSummary({
        ClientId: params?.ClientId,
        UserId: UserData?.userdata?._id,
      }).then((response) => {
        if (response.data.status === 1) {
          setSession_Summary(response?.data?.data_all?.reverse());
        }
        setLoadingSessionSummary(false);
      });
    } catch (error) {
      setLoadingSessionSummary(false);
      console.log(error);
    }
  };

  useEffect(() => {
    upcomingListfun();
    getSessionSummary_List();
  }, []);

  const GoToSummary = (summaryId) => {
    navigate(`../${AdminRoute.Auth.SessionDetails}/${summaryId}`);
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
        <MobMenuBar></MobMenuBar>

        <div className="dashboard-container">
          <div className="sub-head px-3">
            <p className="sub-heading">Sessions</p>
            {/* <p className="sub-lbl">
              Total Number of Session Completed: 1000 Hours
            </p> */}
          </div>
        </div>

        <div className="common-tabs" style={{ width: "86%" }}>
          <div
            className="common-tab-item cursor-pointer"
            onClick={() => handleTabs(0)}
            style={
              activeIndex == 0
                ? { borderBottom: "2px solid rgb(245,189,64)" }
                : null
            }
          >
            Upcoming Session
          </div>
          <div
            className="common-tab-item cursor-pointer"
            onClick={() => handleTabs(1)}
            style={
              activeIndex == 1
                ? { borderBottom: "2px solid rgb(245,189,64)" }
                : null
            }
          >
            Summary
          </div>
        </div>
        {activeIndex === 0 ? (
          <>
            <div className="client-name-list">
              {loadingUpcomingLst ? (
                <div className="alert alert-warning" role="alert">
                  <div className="mx-3">
                    <div className="loader_orange "></div>
                  </div>
                </div>
              ) : UpcommingList?.length === 0 ? (
                <div className="alert alert-warning" role="alert">
                  Data for the upcoming session not found.
                </div>
              ) : (
                UpcommingList?.map((val, i) => {
                  return val?.clientapprove[0]?.is_approve === 1 ? (
                    <div
                      style={{ padding: "11px 15px" }}
                      className="client-name-card d-flex flex-row round-card align-items-center"
                      key={i}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          background: "#CBC5BD",
                          borderRadius: "27px",
                        }}
                        className="time-div"
                      >
                        <strong>{val?.timestart}</strong>
                        {/* | PM */}
                      </div>
                      <label
                        style={{ fontWeight: "500" }}
                        className="session-user-lbl  mx-2 my-1"
                      >
                        {`${val?.client[0]?.name} ${val?.client[0]?.last_name}`}
                      </label>
                      <strong>Date : {moment(val?.date).format("ll")}</strong>
                      <img src={timeIcon} className="time-icon" />
                    </div>
                  ) : (
                    <></>
                  );
                })
              )}
            </div>
          </>
        ) : (
          <>
            <div className="client-name-list">
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                {/* <label className="center-date-lbl">Today</label> */}
              </div>
              {loadingSessionSummary ? (
                <div className="alert alert-warning" role="alert">
                  <div className="m-3">
                    <div className="loader_orange "></div>
                  </div>
                </div>
              ) : Session_Summary?.length === 0 ? (
                <div className="alert alert-warning" role="alert">
                  Data for the session summary not found.
                 
                </div>
              ) : (
                Session_Summary?.map((val, i) => {
                  return (
                    <div
                      style={{ padding: "11px 15px", background: "#E5DFD7" }}
                      className="client-name-card d-flex flex-col cursor-pointer"
                      key={val?._id}
                      onClick={() => GoToSummary(val?._id)}
                    >
                      <div className="d-flex justify-content-between my-2">
                        <label style={{ fontSize: "12px" }}>
                          {moment(val?.date).format("ll")}
                        </label>
                        <label style={{ fontSize: "12px" }}>
                          Published <label className="dot green"></label>
                        </label>
                      </div>
                      <div className="d-flex justify-content-start align-items-center ">
                        <div
                          style={{ fontSize: "13px", background: "#CBC5BD" }}
                          className="time-div"
                        >
                          <strong>{val?.timestart?.split(" ")[0]}</strong> |{" "}
                          {val?.timestart.split(" ")[1]}
                        </div>
                        <label
                          className="session-user-lbl mx-2 fw-bold"
                          style={{ width: "180px", fontWeight: "500" }}
                        >
                          {val?.client[0]?.name} {val?.client[0]?.last_name}
                        </label>
                      </div>
                      <img
                        style={{ top: "63px", right: "12px" }}
                        src={rightArrow}
                        className="right-arr"
                      />
                    </div>
                  );
                })
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sessions;
