/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import { toast } from "react-toastify";
import backBtn from "../../assets/images/icons/Icon ionic-ios-arrow-back.png";

const MyCalender = () => {
  const param = useParams();
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [MyCalenderDetails, setMyCalenderDetails] = useState([]);
  const [DaysWeek, setDaysWeek] = useState(
    MyCalenderDetails?.noofworking_days
      ? MyCalenderDetails?.noofworking_days
      : "1"
  );
  const [StartT, setStartT] = useState("");
  const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const [pickedDays, setpickedDays] = useState([]);
  const [pickedSlots, setpickedSlots] = useState([]);
  const [Time, setTime] = useState([]);
  const [isSetStartTime, setisSetStartTime] = useState(false);
  const [isSetEndTime, setisSetEndTime] = useState(false);
  const [disableindex, setdisableindex] = useState(0);
  const [CalenderError, setCalenderError] = useState();
  const [loading, setLoading] = useState(false);

  // const [EndT, setEndT] = useState("");
  // var newa = [];
  // const [isStartTimeSelect, setisStartTimeSelect] = useState(false);

  const timeSlots = [
    { "Select Time": false },
    { "12:00 AM": false },
    { "01:00 AM": false },
    { "02:00 AM": false },
    { "03:00 AM": false },
    { "04:00 AM": false },
    { "05:00 AM": false },
    { "06:00 AM": false },
    { "07:00 AM": false },
    { "08:00 AM": false },
    { "09:00 AM": false },
    { "10:00 AM": false },
    { "11:00 AM": false },
    { "12:00 PM": false },
    { "01:00 PM": false },
    { "02:00 PM": false },
    { "03:00 PM": false },
    { "04:00 PM": false },
    { "05:00 PM": false },
    { "06:00 PM": false },
    { "07:00 PM": false },
    { "08:00 PM": false },
    { "09:00 PM": false },
    { "10:00 PM": false },
    { "11:00 PM": false },
  ];

  const slots = [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  // =======Profile info api to get calender data ================================================
  useEffect(() => {
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          // console.log(
          //   "Profile data for calender",
          //   response?.data?.data?.theropist_calender
          // );
          setMyCalenderDetails(response?.data?.data?.theropist_calender);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // =============================================================================================

  const handleClickDays = (ele) => {
    // if (pickedDays.indexOf(ele) > -1) {
    //   pickedDays.splice(pickedDays.indexOf(ele), 1);
    // } else if (pickedDays?.length < parseInt(DaysWeek)) {
    //   setpickedDays([...pickedDays, ele]);
    // }

    // if (pickedDays.indexOf(ele) > -1) {
    //   pickedDays.splice(pickedDays.indexOf(ele), 1);
    //   setpickedDays(pickedDays.splice(pickedDays.indexOf(ele), 1));
    //   } else if (pickedDays?.length < parseInt(DaysWeek)) {
    // // } else {
    //   setpickedDays([...pickedDays, ele]);
    // }

    if (
      parseInt(DaysWeek) > pickedDays.length &&
      pickedDays.includes(ele) === false
    ) {
      setpickedDays([...pickedDays, ele]);
    } else if (pickedDays.length) {
      setpickedDays(pickedDays.filter((word) => word !== ele));
    }
  };

  const handleClickSlots = (ele) => {
    // console.log(ele)
    const filteredTimeSlots = Time.filter((timeSlot) => {
      const time = Object.keys(timeSlot)[0];
      return time === ele;
    });
    // console.log("valval", Object.values(filteredTimeSlots[0])[0])
    if (
      Object.values(filteredTimeSlots[0])[0] &&
      pickedSlots.includes(ele) === false
    ) {
      setpickedSlots([...pickedSlots, ele]);
    } else if (pickedSlots.length) {
      setpickedSlots(pickedSlots.filter((word) => word !== ele));
    }
  };

  const handleTimeSlots = (e) => {
    // console.log(e.target.selectedIndex);
    setdisableindex(e.target.selectedIndex);
    setStartT(e.target.selectedIndex);
    setisSetStartTime(true);

    // timeSlots.forEach((ele, i) => {
    //   if (Object.keys(ele).toString() === e) {
    //     timeSlots[i][e] = true;
    //   } else {
    //     timeSlots[i][Object.keys(ele)] = false;
    //   }
    // });
  };

  const handleSecondTimeSlot = (e) => {
    let lowerIndex;
    let higherIndex;
    timeSlots.filter((ele, i) => {
      if (Object.values(ele)[0] === true) {
        lowerIndex = i;
      }
      if (Object.keys(ele).toString() === e) {
        higherIndex = i;
        // setdisableindex(i);
      }
    });

    timeSlots.forEach((ele, i) => {
      if (lowerIndex <= i && higherIndex >= i) {
        timeSlots[i][Object.keys(ele)] = true;
      } else {
        timeSlots[i][Object.keys(ele)] = false;
      }
    });

    //Set Var Data Here
    setTime(timeSlots);
    // setEndT(1 + e.target.value);
    if (StartT < 1 + e.target.selectedIndex) {
      const selectedTimeSlots = timeSlots.slice(
        StartT,
        1 + e.target.selectedIndex
      );
      const modifiedTimeSlots = timeSlots.map((timeSlot) => {
        const isIncluded = selectedTimeSlots.find((selectedSlot) => {
          return Object.keys(timeSlot)[0] === Object.keys(selectedSlot)[0];
        });

        return {
          [Object.keys(timeSlot)[0]]: isIncluded ? true : false,
        };
      });
      // setpickedSlots(modifiedTimeSlots)
      setTime(modifiedTimeSlots);
      // newa = modifiedTimeSlots;
    }
    setisSetEndTime(true);
  };

  const handleChange = (e) => {
    setDaysWeek(e.target.value);
  };

  const submitCalender = (e) => {
    e.preventDefault();
    if (
      DaysWeek?.length != 0 &&
      pickedDays?.length != 0 &&
      Time?.length != 0
      // && pickedSlots?.length != 0
    ) {
      try {
        setLoading(true);
        API.Auth.TherapistCalenderUpdate({
          data: {
            theropist_calender: {
              noofworking_days: parseInt(DaysWeek),
              days: pickedDays,
              time: Time,
              exclude: pickedSlots,
            },
          },
          UserId: UserData?.userdata?._id,
        }).then((response) => {
          // console.log(response?.data);

          if (response?.data?.status === 1) {
            setCalenderError("");
            console.log("param?.id", param?.id);
            // toast.success(response?.data?.message);
            if (
              param?.id === "1" &&
              response?.data?.userdata?.is_authorized === 0
            ) {
              navigate(`../${AdminRoute?.Auth?.Login}`);
              toast.success(
                "We are reviewing your profile. Please check back later."
              );
            } else if (
              param?.id === "2" &&
              response?.data?.userdata?.is_authorized === 1
            ) {
              navigate(`../${AdminRoute?.Auth?.Dashboard}`);
            }
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      // alert("API call");
      setCalenderError("");
    } else {
      // alert("Error display");
      setCalenderError("Please Fill all the details!");
    }
  };

  // side Effect

  useEffect(() => {
    // console.log(Time);
    // console.log("pickedSlots", pickedSlots);
  }, [Time, pickedSlots]);

  return (
    <div className="calender-container">
      <img src={rtylifeLogo} className="rylife-log" />
      <div className="cal-header">
        {/* <span>
          <img
            src={backArrow}
            to={navigate(`../${AdminRoute?.Auth?.Registration1}`)}
            className="backarrow"
          />
        </span> */}

        {console.log("param?.id", typeof param?.id)}

        {param?.id === "2" ? (
          <span onClick={() => navigate(-1)}>
            <img
              src={backBtn}
              style={{
                height: "30px",
                height: "23px",
                marginLeft: "16px",
                opacity: "0.7",
              }}
            />
          </span>
        ) : (
          <></>
        )}

        <p className="header-lbl">My Calender</p>
      </div>
      <div className="common-container">
        <div className="inner-container p-3">
          <p className="commmon-heading">
            * Please enter the number of working days per week
          </p>
          <div className="day-input-container">
            <select
              className="time-select bg-white"
              onChange={(e) => handleChange(e)}
            >
              return (<option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>)
            </select>
            {/* <span className="days-placeholder">days a week</span> */}
          </div>
        </div>
      </div>
      <div className="common-container">
        <p className="commmon-heading">
          * Please Select working days of the week
        </p>
        <div className="inner-container days-container">
          {days.map((ele, i) => {
            return (
              <label
                key={i}
                className={
                  pickedDays.includes(ele) ? "days-lbl days-active" : "days-lbl"
                }
                onClick={() => handleClickDays(ele)}
              >
                {ele}
              </label>
            );
          })}
        </div>
      </div>
      <div className="common-container">
        <div className="inner-container time-pick px-0 mt-0">
          <div>
            <p className="commmon-heading mb-1">* Start Time</p>
            <select
              className="time-select"
              onChange={(e) => handleTimeSlots(e)}
            >
              {timeSlots.map((ele, i) => {
                return (
                  <>
                    {i !== timeSlots.length - 1 ? (
                      <option id={i} key={i} value={Object.keys(ele)}>
                        {Object.keys(ele)}
                      </option>
                    ) : null}
                  </>
                );
              })}
            </select>
          </div>

          <div>
            <p className="commmon-heading mb-1">* End Time</p>

            <select
              disabled={isSetStartTime == 0 ? true : false}
              className="time-select"
              onChange={(e) => handleSecondTimeSlot(e)}
              //  style={{pointerEvents: temp.length === 0 ? 'none' : 'all',
              // opacity: temp.length === 0 ? '50%' : '100%'}}
            >
              {timeSlots.map((ele, i) => {
                return (
                  <>
                    {i !== 0 ? (
                      <option
                        key={i}
                        disabled={disableindex >= i}
                        id={i}
                        value={Object.keys(ele)}
                      >
                        {Object.keys(ele)}
                      </option>
                    ) : null}
                  </>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="common-container ">
        <p className="commmon-heading">
          * Please select the time slot you wish to exclude
        </p>
        <div className="inner-container slots calender-slots">
          {slots.map((ele, index) => {
            return (
              <button
                key={index}
                disabled={isSetEndTime === false ? true : false}
                // disabled={disableindex >= index + 1}
                className={
                  pickedSlots.includes(ele)
                    ? `time-picker-button time-picker-disabled`
                    : "time-picker-button "
                }
                onClick={() => handleClickSlots(ele)}
              >
                {ele}
              </button>
            );
          })}
        </div>
      </div>
      <h3 className="text-danger">{CalenderError}</h3>

      <div className="common-container ">
        <button
          type="submit"
          className="button_common w-100 my-3 mb-3 text-center nav_btn"
          role="button"
          // to={navigate(`../${AdminRoute?.Auth?.Dashboard}`)}
          onClick={(e) => submitCalender(e)}
        >
          Set
        </button>
        {loading ? <div className="loader_orange"></div> : <></>}
      </div>
    </div>
  );
};

export default MyCalender;
