import React, { useState, useEffect } from "react";
import userImage from "../../assets/images/icons/mentalhealthart-01.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import MobMenuBar from "../Common/MobMenuBar";
import { useParams } from "react-router-dom";
import API from "../../Api/Api";
import moment from "moment";

const SessionDetails = () => {
  const param = useParams();
  const [SessionDetails, setSessionDetails] = useState([]);

  const GetSessionDetails = () => {
    try {
      API.Client.SessionDetails({
        SessionId: param?.SessionId,
      }).then((response) => {
        console.log("SessionDetails", response?.data);
        if (response?.data?.status === 1) {
          setSessionDetails(response?.data?.data_all);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetSessionDetails();
  }, []);

  //   side Effect==

  useEffect(() => {
    // console.log("SessionDetails", SessionDetails);
  }, [SessionDetails]);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>

        <MobMenuBar></MobMenuBar>
        <div className="dashboard-container">
          <div className="sub-head px-3 d-flex">
            <img
              style={{ height: "40px", marginRight: "10px" }}
              src={userImage}
            />
            <div>
              <p className="sub-heading">
                {/* <strong>31 July 2023 11:00 AM</strong> */}
                <strong>
                  {moment(SessionDetails[0]?.createdAt).format("LLL")}
                </strong>
              </p>
              <p className="sub-lbl">Here are all your session details</p>
            </div>
          </div>
          <div className="mb-2 client-view-cont">
            <p className="my-2" style={{ color: "#32665F" }}>
              <strong>Session Summary:</strong>
            </p>
            <p>{SessionDetails[0]?.summary}</p>
            <p className="my-2" style={{ color: "#32665F" }}>
              <strong>Observations:</strong>
            </p>
            <p>{SessionDetails[0]?.observation}</p>

            <p className="my-2" style={{ color: "#32665F" }}>
              <strong>Interventions:</strong>
            </p>
            <p>{SessionDetails[0]?.intervention}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionDetails;
