import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import userImg from "../../assets/images/icons/userIMG.jpg";
import userIcon from "../../assets/images/icons/Icon awesome-user-circle.png";
import Modal from "react-modal";
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import SideBarMenu from "../Common/SideBarMenu";
import searchIcon from "../../assets/images/icons/Icon ionic-md-search.png";
import API from "../../Api/Api";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";

const MembersSelection = () => {
  const params = useParams();
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [ClientList, setClientList] = useState([]);
  const [selectedMembers, setselectedMembers] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // try {
    //   API.Group.ClientListing({}).then((response) => {
    //     console.log(response?.data?.data?.dataorg);
    //     setClientList(response?.data?.data?.dataorg);
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
    setLoading(true);
    try {
      API.Client.ClientActiveList({
        UserId: UserData?.userdata?._id,
        isApproved: "1",
      }).then((response) => {
        // console.log("ClientActiveList", response?.data?.data_all);
        setClientList(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  //   ====Select Menbers====

  // const selectMember = (e) => {
  //   // setselectedMembers(e.target.value);
  //   // console.log(e.target.value)
  //   setselectedMembers((prevSelectedCheckboxes) => {
  //     if (prevSelectedCheckboxes.includes(e.target.value)) {
  //       // Checkbox was previously selected, remove it from the array
  //       return prevSelectedCheckboxes.filter(
  //         (value) => value !== e.target.value
  //       );
  //     } else {
  //       // Checkbox was not previously selected, add it to the array
  //       return [...prevSelectedCheckboxes, e.target.value];
  //     }
  //   });
  // };

  // const upadateGroupmembers = (e) => {
  //   try {
  //     API.Group.UpdateGroups({
  //       data: {
  //         id: params.GroupId,
  //         group_member: selectedMembers,
  //       },
  //     }).then((response) => {
  //       console.log(response?.data);
  //       if (response?.data?.message === "Record Updated Success") {
  //         navigate(`${AdminRoute?.Auth?.Groups}`);
  //         toast.success(response?.data?.message);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(error.message);
  //   }
  // };

  // ======= select members =======

  const selectMembersGroup = (e, value) => {
    // console.log("=======>", e.target.checked);

    if (e.target.checked === true) {
      try {
        API.Group.SelectGroupMembers({
          data: {
            userId: value?.client[0]?._id,
            groupId: params?.GroupId,
          },
        }).then((response) => {
          console.log(response?.data);
        });
      } catch (error) {
        console.log(error);
      }
    } else if (e.target.checked === false) {
      try {
        API.Group.DeSelectGroupMembers({
          data: {
            userId: value?.client[0]?._id,
            groupId: params?.GroupId,
          },
        }).then((response) => {
          console.log(response?.data);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Search function

  const handleSearchText = (text) => {
    console.log(text);

    try {
      API.Client.ClientActiveList({
        UserId: UserData?.userdata?._id,
        isApproved: "1",
        serchKey: text,
      }).then((response) => {
        // console.log("ClientActiveList", response?.data?.data_all);
        setClientList(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //   test effect

  useEffect(() => {
    // console.log("selectedMembers", selectedMembers);
  }, [selectedMembers]);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <div className="app-header">
          <label className="header-lbl">Memebers Selection</label>
          <div>
            <button
              // onClick={() => openModal()}
              // onClick={(e) => upadateGroupmembers(e)}
              onClick={() => navigate(`../${AdminRoute?.Auth?.Groups}`)}
              className="next-btn-member"
            >
              Skip
            </button>
          </div>
          <div>
            <button
              // onClick={() => openModal()}
              // onClick={(e) => upadateGroupmembers(e)}
              onClick={() => navigate(`../${AdminRoute?.Auth?.Groups}`)}
              className="next-btn-member"
            >
              Next
            </button>
          </div>
        </div>
        <div className="group-container mx-3">
          {/* <div className="my-2">
            <input
              type="text"
              placeholder="Search Members"
              name="Title"
              className="search_inp "
              onChange={(e) => handleSearchText(e.target.value)}
            />
          </div> */}

          <div className="col-12 mt-2" style={{ position: "relative" }}>
            <span>
              <img src={searchIcon} className="search_ref_icon ms-2" />
            </span>
            <input
              type="text"
              placeholder="Search Members"
              className="search_ref ps-5 bg-white"
              onChange={(e) => handleSearchText(e.target.value)}
            />
          </div>

          <div className="group-list-container py-0">
            {loading ? (
              <div className="loader_orange"></div>
            ) : !ClientList?.length ? (
              <>
                <div className="alert alert-warning my-3" role="alert">
                  Clients Not Available
                </div>
              </>
            ) : (
              ClientList?.length &&
              ClientList?.map((val, i) => {
                return (
                  <div className="group-list-item w-100" key={i}>
                    <div className="group-icon-container">
                      <img
                        className="memeber-img bg-transparent me-2"
                        // src={userIcon}
                        src={userImg}
                      />
                      <p className="m-0 mx-2">{`${val?.client[0]?.name} ${val?.client[0]?.last_name}`}</p>
                    </div>
                    <div>
                      {/* <input
                        type="checkbox"
                        className="user-check"
                        name={`${val?.client[0]?.name} ${val?.client[0]?.last_name}`}
                        value={val?.client[0]?._id}
                        onChange={(e) => selectMembersGroup(e, val)}
                        // onChange={(e) => selectMember(e)}
                        // checked={selectedMembers == val?._id ? true : false}
                      /> */}
                      <span className="position-relative member-selection">
                        <label className="check_container">
                          <input
                            name={`${val?.client[0]?.name} ${val?.client[0]?.last_name}`}
                            type="checkbox"
                            value={val?.client[0]?._id}
                            onChange={(e) => selectMembersGroup(e, val)}
                            // checked="checked"
                          />
                          <span
                            className="checkmark"
                            style={{ top: "0px" }}
                          ></span>
                        </label>
                      </span>
                    </div>
                  </div>
                );
              })
            )}
            {/* <div className="group-list-item px-3">
              <div className="group-icon-container">
                <img className="memeber-img" src={userImg} />
                <p className="m-0 mx-2">Akshay Mahajan</p>
              </div>
              <div>
                <input type="checkbox" className="user-check" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersSelection;
