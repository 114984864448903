import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import { toast } from "react-toastify";
import backBtn from "../../assets/images/icons/Icon ionic-ios-arrow-back.png";

const DisplayMyCalender = () => {
  const param = useParams();
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [MyCalenderDetails, setMyCalenderDetails] = useState([]);
  const [displayValue, setdisplayValue] = useState();
  const [displayLastValue, setdisplayLastValue] = useState();

  const [StartT, setStartT] = useState("");
  const days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const [pickedDays, setpickedDays] = useState([]);
  const [pickedSlots, setpickedSlots] = useState([]);
  const [Time, setTime] = useState([]);
  const [isSetStartTime, setisSetStartTime] = useState(false);
  const [disableindex, setdisableindex] = useState(0);
  const [CalenderError, setCalenderError] = useState();

  const timeSlots = [
    { "Select Time": false },
    { "12:00 AM": false },
    { "01:00 AM": false },
    { "02:00 AM": false },
    { "03:00 AM": false },
    { "04:00 AM": false },
    { "05:00 AM": false },
    { "06:00 AM": false },
    { "07:00 AM": false },
    { "08:00 AM": false },
    { "09:00 AM": false },
    { "10:00 AM": false },
    { "11:00 AM": false },
    { "12:00 PM": false },
    { "01:00 PM": false },
    { "02:00 PM": false },
    { "03:00 PM": false },
    { "04:00 PM": false },
    { "05:00 PM": false },
    { "06:00 PM": false },
    { "07:00 PM": false },
    { "08:00 PM": false },
    { "09:00 PM": false },
    { "10:00 PM": false },
    { "11:00 PM": false },
  ];

  const slots = [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  // =======Profile info api to get calender data ================================================
  useEffect(() => {
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          // console.log(
          //   "Profile data for calender",
          //   response?.data?.data?.theropist_calender
          // );
          setMyCalenderDetails(response?.data?.data?.theropist_calender);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // =============================================================================================

  const handleSecondTimeSlot = (e) => {
    let lowerIndex;
    let higherIndex;
    timeSlots.filter((ele, i) => {
      if (Object.values(ele)[0] === true) {
        lowerIndex = i;
      }
      if (Object.keys(ele).toString() === e) {
        higherIndex = i;
      }
    });

    timeSlots.forEach((ele, i) => {
      if (lowerIndex <= i && higherIndex >= i) {
        timeSlots[i][Object.keys(ele)] = true;
      } else {
        timeSlots[i][Object.keys(ele)] = false;
      }
    });

    //Set Var Data Here
    setTime(timeSlots);
    // setEndT(1 + e.target.value);
    if (StartT < 1 + e.target.selectedIndex) {
      const selectedTimeSlots = timeSlots.slice(
        StartT,
        1 + e.target.selectedIndex
      );
      const modifiedTimeSlots = timeSlots.map((timeSlot) => {
        const isIncluded = selectedTimeSlots.find((selectedSlot) => {
          return Object.keys(timeSlot)[0] === Object.keys(selectedSlot)[0];
        });

        return {
          [Object.keys(timeSlot)[0]]: isIncluded ? true : false,
        };
      });
      // setpickedSlots(modifiedTimeSlots)
      setTime(modifiedTimeSlots);
      // newa = modifiedTimeSlots;
    }
  };

  useEffect(() => {
    // console.log(Time);
    // console.log("pickedSlots", pickedSlots);
  }, [Time, pickedSlots]);

  const getStartTime = () => {
    const firstTrueObject = MyCalenderDetails?.time?.find(
      (slot) => Object.values(slot)[0] === true
    );
    const displayValuetemp = firstTrueObject
      ? Object.keys(firstTrueObject)[0]
      : "No available time slot";
    // console.log(displayValuetemp);
    setdisplayValue(displayValuetemp);
  };

  const getEndTime = () => {
    const lastTrueObject = MyCalenderDetails?.time
      ?.slice()
      .reverse()
      .find((slot) => Object.values(slot)[0] === true);

    // Display the last true value or a message if none is found
    const displayLastValuetemp = lastTrueObject
      ? Object.keys(lastTrueObject)[0]
      : "No available time slot";
    // console.log(displayLastValuetemp);
    setdisplayLastValue(displayLastValuetemp);
  };

  useEffect(() => {
    getStartTime();
    getEndTime();
  }, [MyCalenderDetails]);

  return (
    <div className="calender-container">
      <img src={rtylifeLogo} className="rylife-log" />
      <div className="cal-header">
        {/* <span>
          <img
            src={backArrow}
            to={navigate(`../${AdminRoute?.Auth?.Registration1}`)}
            className="backarrow"
          />
        </span> */}

        {param?.id === "2" ? (
          <span onClick={() => navigate(-1)}>
            <img
              src={backBtn}
              style={{
                height: "30px",
                height: "23px",
                marginLeft: "16px",
                opacity: "0.7",
              }}
            />
          </span>
        ) : (
          <></>
        )}

        <p className="header-lbl">My Calender</p>
      </div>
      <div className="common-container">
        <div className="inner-container p-3">
          <p className="commmon-heading">
            * Please enter the no. of working days of the week
          </p>
          <div className="day-input-container my-calender">
            <select className="time-select" disabled>
              return (
              <option value={MyCalenderDetails?.noofworking_days}>
                {MyCalenderDetails?.noofworking_days}
              </option>
              )
            </select>
          </div>
        </div>
      </div>
      <div className="common-container">
        <p className="commmon-heading">
          * Please Select working days of the week
        </p>
        <div className="inner-container days-container">
          {days.map((ele, i) => {
            return (
              <label
                key={i}
                className={
                  MyCalenderDetails?.days?.includes(ele)
                    ? "days-lbl days-active"
                    : "days-lbl"
                }
              >
                {ele}
              </label>
            );
          })}
        </div>
      </div>
      <div className="common-container">
        <div className="inner-container time-pick px-0 mt-0">
          <div>
            <p className="commmon-heading mb-1">* Start Time</p>
            <select className="time-select">
              <>
                <option value={displayValue}>{displayValue}</option>
              </>
            </select>
          </div>

          <div>
            <p className="commmon-heading mb-1">* End Time</p>

            <select
              disabled={isSetStartTime == 0 ? true : false}
              className="time-select"
            >
              <option value={displayLastValue}>{displayLastValue}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="common-container ">
        <p className="commmon-heading">
          * Please select time slot you wish to exclude
        </p>
        <div className="inner-container slots calender-slots">
          {slots.map((ele, index) => {
            return (
              <button
                key={index}
                className={
                  MyCalenderDetails?.exclude?.includes(ele)
                    ? `time-picker-button selected`
                    : "time-picker-button"
                }
                // onClick={() => handleClickSlots(ele)}
              >
                {ele}
              </button>
            );
          })}
        </div>
      </div>
      <h3 className="text-danger">{CalenderError}</h3>

      <div className="common-container ">
        <button
          type="submit"
          className="button_common w-100 my-3 mb-3 text-center nav_btn"
          role="button"
          onClick={() => navigate(`../${AdminRoute?.Auth?.MyCalender}/2`)}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default DisplayMyCalender;
