import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userImg from "../../assets/images/icons/userIMG.jpg";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import API from "../../Api/Api";
import Modal from "react-modal";
import plusIcon from "../../assets/images/icons/Icon feather-plus.png";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import RouteName from "../../Route/RouteDetails";
import SideBarMenu from "../Common/SideBarMenu";
import moment from "moment/moment";
import baseApi from "../../Api/config";
import AdminRoute from "../../Route/RouteDetails";
import plusIco from "../../assets/images/icons/+.png";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgb(211,205,197, 50%)",
    borderRadius: "20px",
    paddingTop: "70px",
    width: "90%",
    maxWidth: "500px",
    border: "none",
    overflowX: "hidden",
    backdropFilter: "blur(10px)",
  },
};

const GroupDetails = () => {
  const { GroupId } = useParams();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const navigate = useNavigate();
  const [UserInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [GroupDetail, setGroupDetail] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [CheckGroupType, setCheckGroupType] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [GimageName, setGimageName] = useState("");
  const [is_loading, setis_loading] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    setSpinner(false);
    setProfileImage("");
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const initialValues = {
    GName: "",
    GDescription: "",
    GTags: "",
    GMembers: "",
    GStartDate: "",
    GEndDate: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleUpload = (e) => {
    // console.log(e.target.files[0]);
    setLoading(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setLoading(false)
        );
    } catch (err) {
      console.log(err.res);
      setLoading(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
    setGimageName(image);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const Validate = (values, img) => {
    // console.log("===> ok", values);
    const errors = {};
    if (!img) {
      errors.img = "Group image is required !";
    }
    if (!values?.GName) {
      errors.GName = "Name is required !";
    }
    if (!values?.GDescription) {
      errors.GDescription = "Discription is required !";
    }
    if (!values?.GTags) {
      errors.GTags = "Tag is required !";
    }
    if (!values?.GMembers && CheckGroupType === "focused") {
      errors.GMembers = "No Of Members required !";
    }
    if (!values?.GStartDate && CheckGroupType === "focused") {
      errors.GStartDate = "Start Date is required !";
    }
    if (!values?.GEndDate && CheckGroupType === "focused") {
      errors.GEndDate = "End Date is required !";
    }
    if (!values?.Group) {
      errors.Group = "Group Type is required !";
    }
    return errors;
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log("formData", formData);
      setis_loading(true);
      try {
        API.Group.CreateGroups({
          data: {
            name: formData?.GName,
            type: formData?.Group,
            logo: GimageName,
            description: formData?.GDescription,
            members: formData?.GMembers,
            startdate: formData?.GStartDate,
            enddate: formData?.GEndDate,
            created_by: UserData?.userdata?._id,
            tags: formData?.GTags,
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            if (CheckGroupType === "support") {
              setIsOpen(false);
              navigate(`../${AdminRoute?.Auth?.Groups}`);
            } else {
              navigate(
                `../${AdminRoute?.Auth?.MembersSelection}/${response?.data?.data?._id}`
              );
            }
          }
          setis_loading(false);
        });
      } catch (error) {
        console.log(error);
        // toast.error(error.message);
        setis_loading(false);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(Validate(formData, ProfileImage));
    setIsSubmit(true);
  };

  useEffect(() => {
    setLoading(true);
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          // console.log(response?.data?.data);
          setUserInfo(response?.data?.data);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const GroupDetails = () => {
    API.Group.GroupDetails({
      GroupId: GroupId,
    })
      .then((response) => {
        console.log("Group Details", response?.data?.data[0]);
        setGroupDetail(response?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
        // setLoadingChat(false);
      });
  };

  useEffect(() => {
    GroupDetails();
  }, []);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");

    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const EndGroup = (e) => {
    try {
      API.Group.UpdateGroups({
        data: {
          id: GroupId,
          is_active: 0,
        },
      }).then((response) => {
        console.log(response?.data);
        if (response?.data?.message === "Group updated successfully.") {
          navigate(`${AdminRoute?.Auth?.Groups}`);
          toast.success("Group is terminated successfully.");
        }
      });
    } catch (error) {
      console.log(error);
      // toast.error(error.message);
    }
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>

      <div className="content">
        <Header
          isBack={true}
          header={GroupDetail?.name}
          isGroupImg={GroupDetail?.logo}
          GroupId={GroupDetail?._id}
        ></Header>

        <div className="p-3 mt-2 group-details">
          {loading ? (
            <div className="loader_orange"></div>
          ) : (
            <>
              {" "}
              <div className="img-container pb-0 px-2">
                <span className="custom-drag-box">
                  <img
                    className="reg-user-img form-upload-photo-preview2 "
                    src={`${baseApi.baseurl}images/${GroupDetail?.logo}`? `${baseApi.baseurl}images/${GroupDetail?.logo}`: userImg}
                  />
                </span>
              </div>
              <p className="text-center sub-heading mb-3">
                {GroupDetail?.name}
              </p>
              <div className="field-container-main">
                <div className="field-conteiner w-100">
                  <p className="fw-bold mb-2">Group Description</p>

                  <p>{GroupDetail?.description}</p>

                  <p className="fw-bold mb-2 mt-3">Tags</p>

                  {GroupDetail?.tags?.length &&
                    GroupDetail?.tags?.map((val, i) => {
                      return (
                        <div className="d-flex gap-2 mb-3">
                          <h3 key={i}>
                            <span class="badge bg-white text-info-emphasis py-2 px-3 rounded-pill">
                              {val}
                            </span>
                          </h3>
                        </div>
                      );
                    })}
                </div>

                <div className="row w-100 my-4">
                  <div className="col-6 col-md-3">
                    <p className="fw-bold mb-1">Group Type:</p>
                    <p>{GroupDetail?.type}</p>
                  </div>

                  {GroupDetail?.type === "focused" ? (
                    <>
                      <div className="col-6 col-md-3">
                        <p className="fw-bold mb-1">Members:</p>
                        <p>{GroupDetail?.members}</p>
                      </div>

                      <div className="col-6 col-md-3">
                        <p className="fw-bold mb-1">Start Date:</p>
                        <p>{moment(GroupDetail?.startdate).format("LL")}</p>
                      </div>

                      <div className="col-6 col-md-3">
                        <p className="fw-bold mb-1">End Date:</p>
                        <p>{moment(GroupDetail?.enddate).format("LL")}</p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {GroupDetail?.type === "support" ? (
                    <div className="col-6 col-md-3">
                      <p className="fw-bold mb-1">Start Date:</p>
                      <p>{moment(GroupDetail?.createdAt).format("LL")}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="bottom-btn-container justify-content-start col-6">
                  <p className="text-danger fw-bold d-flex align-items-center cursor-pointer mb-0">
                    <i
                      class="fa fa-stop-circle fs-3 me-2"
                      onClick={(e) => EndGroup(e)}
                    ></i>{" "}
                    End Group
                  </p>
                </div>
                <div className="bottom-btn-container col-6">
                  <button
                    className="sec-btn w-auto px-4"
                    onClick={() => openModal()}
                  >
                    Create Group
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className="select-group close-btn">
          <img src={plusIco} />{" "}
        </button>
        <div>
          <div className="d-flex justify-content-center ">
            {loading ? (
              <>
                <div className="loader_orange"></div>
              </>
            ) : ProfileImage ? (
              <>
                <span className="custom-drag-box">
                  <div className="d-flex flex-column align-items-center w-100 h-100">
                    <i
                      className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                      aria-hidden="true"
                    ></i>
                    <div
                      className="form-upload-photo-preview2"
                      style={{
                        backgroundImage: `url(${ProfileImage})`,
                      }}
                    ></div>
                    <span className="custom-drag-box-text d-none">
                      Image Uploaded
                    </span>
                    <input
                      type="file"
                      id="uploadFile"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      tabIndex="1"
                      title=" "
                      onChange={(e) => handleUpload(e)}
                    />
                  </div>
                </span>
              </>
            ) : (
              <>
                <span className="custom-drag-box">
                  <p style={{ textAlign: "Center" }}>Select Group Icon</p>
                  <img className="group-img" src={plusIcon} />
                  <input
                    type="file"
                    id="uploadFile"
                    accept="image/png, image/gif, image/jpeg"
                    tabIndex="1"
                    title=" "
                    onChange={(e) => handleUpload(e)}
                  />
                </span>
              </>
            )}
          </div>
          <p className="text-danger">{formErrors?.img}</p>

          <p>
            {" "}
            <span className="text-danger">* </span>Group Name
          </p>
          <input
            type="text"
            placeholder="Group Name"
            className="mb-2"
            name="GName"
            onChange={(e) => handleChange(e)}
            autoComplete="off"
          />
          <p className="text-danger">{formErrors?.GName}</p>
          <p>
            {" "}
            <span className="text-danger">* </span>Group Description
          </p>
          <input
            type="text"
            placeholder="Group Description"
            className="mb-2"
            name="GDescription"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
          />
          <p className="text-danger">{formErrors?.GDescription}</p>

          <p>
            <span className="text-danger">* </span>Tags
          </p>
          <input
            type="text"
            placeholder="Tags"
            className="mb-2"
            name="GTags"
            onChange={(e) => handleChange(e)}
            autoComplete="off"
          />
          <p className="text-danger">{formErrors?.GTags}</p>
          <p>
            {" "}
            <span className="text-danger">* </span>Select Group Type
          </p>
          <p className="mb-2 mt-3">
            <input
              type="radio"
              className="mb-2"
              name="Group"
              value="focused"
              onChange={(e) => handleChange(e)}
              onClick={(e) => setCheckGroupType("focused")}
            />{" "}
            <label className="form-in">Focused Group</label>
          </p>

          <p className="mb-2">
            <input
              type="radio"
              className="mb-2"
              name="Group"
              value="support"
              onChange={(e) => handleChange(e)}
              onClick={(e) => setCheckGroupType("support")}
            />{" "}
            <label className="form-in">Support Group</label>
          </p>

          <p className="text-danger">{formErrors?.Group}</p>
          <div
            style={{
              display: CheckGroupType === "support" ? "none" : "block",
              color: CheckGroupType === 0 ? "white" : "black",
            }}
          >
            <p>
              {" "}
              <span className="text-danger">* </span>Select No. of members
            </p>
            <input
              type="number"
              id="myNumberInput"
              placeholder="Enter No."
              className="mb-2"
              name="GMembers"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
            <p className="text-danger">{formErrors?.GMembers}</p>

            <p>
              {" "}
              <span className="text-danger">* </span>Start Date
            </p>
            <input
              type="date"
              placeholder="Start date"
              className="mb-2"
              name="GStartDate"
              onChange={(e) => handleChange(e)}
              min={disablePastDate()}
            />
            <p className="text-danger">{formErrors?.GStartDate}</p>

            <p>
              {" "}
              <span className="text-danger">* </span>End Date
            </p>
            <input
              type="date"
              placeholder="End Date"
              className="mb-2"
              name="GEndDate"
              onChange={(e) => handleChange(e)}
              min={disablePastDate()}
            />
            <p className="text-danger">{formErrors?.GEndDate}</p>
          </div>
        </div>
        <button
          type="submit"
          className="button_common w-100 mb-2 text-center "
          role="button"
          onClick={(e) => handleSubmit(e)}
        >
          {CheckGroupType === "support" ? "Submit" : "Select Members"}
        </button>
        {is_loading ? <div className="loader_orange"></div> : <></>}
      </Modal>

      <MobMenuBar></MobMenuBar>
    </div>
  );
};

export default GroupDetails;
