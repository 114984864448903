/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import plusIcon from "../../assets/images/icons/Icon feather-plus.png";
import Modal from "react-modal";
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import API from "../../Api/Api";
import moment from "moment";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import MobMenuBar from "../Common/MobMenuBar";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#FFF",
    borderRadius: "20px",
    paddingTop: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0px 0px 24px -10px #bdbdbd",
  },
};

const MyActivityList = () => {
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [viewmodalIsOpen, setviewmodalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function OpenViewModal() {
    setviewmodalIsOpen(true);
  }
  function afterViewOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeViewModal() {
    setviewmodalIsOpen(false);
  }

  const [NotesList, setNotesList] = useState([]);
  const [initialid, setinitialid] = useState("");
  const initialValues = {
    Title: "",
    Description: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [NotesDetails, setNotesDetails] = useState([]);
  const [TaskData, setTaskData] = useState([]);

  const Noteslist = () => {
    setLoading(true);
    try {
      API.Notes.NotesList({}).then((response) => {
        console.log(response?.data?.data_all);
        if (response?.data?.status === 1) {
          setNotesList(response?.data?.data_all);
          setinitialid(
            response?.data?.data_all[response?.data?.data_all?.length - 1]?._id
          );
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Noteslist();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const Validate = (values) => {
    // console.log("===> ok", values);
    const errors = {};

    if (!values?.Title) {
      errors.Title = "Title is required";
    }

    if (!values?.Description) {
      errors.Description = "Description is required";
    }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log(
      //   "Data",
      //   formData?.Title,
      //   formData?.Description,
      //   UserData?.userdata?._id
      // );

      try {
        API.Notes.CreateNotes({
          data: {
            title: formData?.Title,
            description: formData?.Description,
            created_by: UserData?.userdata?._id,
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            // console.log("ok");
            Noteslist();
            closeModal();
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  const CreateNotes = (e) => {
    e.preventDefault();
    setFormErrors(Validate(formData));
    setIsSubmit(true);
  };

  const getNoteDetails = (e, id) => {
    // console.log(id);
    try {
      API.Notes.NotesDetailbyId({
        NoteId: id ? id : initialid,
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          setNotesDetails(response?.data?.data_all[0]);
          OpenViewModal();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const TaskSeenToggle = (e, taskdata) => {
    setTaskData(taskdata);

    try {
      API.Notes.UpdateNote({
        data: {
          is_seen: taskdata?.is_seen === 1 ? 0 : 1,
        },
        NoteId: taskdata?._id,
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          //after update code
          Noteslist();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkedfun = (seen) => {
    if (seen === 1) {
      true;
    }
    if (seen === 0) {
      false;
    }
  };

  // side effect

  useEffect(() => {
    // console.log(TaskData);
  }, [TaskData]);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>

      <MobMenuBar></MobMenuBar>
      <div className="content">
        <Header></Header>
        <div className="d-flex px-3 justify-content-between align-items-center mt-3 mb-3">
          <p className="sub-heading">My Activity</p>
          <span>
            <button onClick={openModal} className="add-act-btn">
              <img src={plusIcon} />
            </button>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <button onClick={closeModal} className="close-btn ">
                <img src={plusIcon} />{" "}
              </button>
              <div>
                <input
                  type="text"
                  placeholder="My Task "
                  className="mb-3 my-task-add"
                  disabled
                />
                <input
                  type="text"
                  placeholder="Enter Title "
                  className="mb-3 "
                  name="Title"
                  onChange={(e) => handleChange(e)}
                />
                <p className="text-danger">{formErrors?.Title}</p>
                <p>Please list down your today task</p>
                <textarea
                  onChange={(e) => handleChange(e)}
                  rows="8"
                  name="Description"
                  className="form-input-text-area"
                  placeholder="My Activity"
                ></textarea>
                <p className="text-danger">{formErrors?.Description}</p>
              </div>
              <button
                type="submit"
                className="button_common w-100 mt-2 mb-0 text-center"
                role="button"
                //  onClick={closeModal}
                onClick={(e) => CreateNotes(e)}
              >
                Save
              </button>
            </Modal>
            {/* **************** View Modal Starts ************************** */}
            <Modal
              isOpen={viewmodalIsOpen}
              onAfterOpen={afterViewOpenModal}
              onRequestClose={closeViewModal}
              style={customStyles}
            >
              <button onClick={closeViewModal} className="close-btn ">
                <img src={plusIcon} />{" "}
              </button>
              <div>
                <input
                  type="text"
                  placeholder="My View Task "
                  className="mb-3 my-task-add"
                  disabled
                />
                <input
                  type="text"
                  placeholder="Enter Title "
                  className="mb-3 "
                  name="Title"
                  readOnly
                  defaultValue={NotesDetails?.title ? NotesDetails?.title : ""}
                />
                <p>Please list down your today task</p>
                <textarea
                  rows="8"
                  name="Description"
                  className="form-input-text-area"
                  placeholder="My Activity"
                  readOnly
                  defaultValue={NotesDetails?.description}
                ></textarea>
              </div>
              <button
                disabled
                type="submit"
                className="button_common w-100 mt-2 mb-0 text-center"
                role="button"
              >
                Done
              </button>
            </Modal>
            {/* ************View Modal Starts ******************* */}
          </span>
        </div>
        <div className="activity-container">
          <div className="activity-list">
            {loading ? (
              <div className="loader_orange"></div>
            ) : !NotesList?.length ? (
              <div className="alert alert-warning" role="alert">
                Digital Notes Not Available
              </div>
            ) : (
              NotesList?.length &&
              NotesList?.map((val, i) => {
                return (
                  <>
                    <div className="activity-card cursor-pointer" key={i}>
                      <div onClick={(e) => getNoteDetails(e, val?._id)}>
                        <div className="v-div new"></div>
                        <p className="card-header">{val?.title}</p>
                        <p
                          className={
                            val?.is_seen === 1
                              ? "activity-lbl text-decoration-line-through"
                              : "activity-lbl"
                          }
                        >
                          {val?.description}
                        </p>
                        <p className="activity-time-lbl">
                          {moment(val?.updatedAt).calendar()}
                        </p>
                      </div>
                      <span>
                        <label className="check_container">
                          <input
                            type="checkbox"
                            name={val?.name}
                            value={val?._id}
                            onClick={(e) => TaskSeenToggle(e, val)}
                            defaultChecked={val?.is_seen === 1 ? true : false}
                            checked={checkedfun(val?.is_seen)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </span>
                    </div>
                  </>
                );
              })
            )}

            {/* Red */}

            {/* <div className="activity-card">
              <div className="v-div pending"></div>
              <p className="card-header">My Activity 1</p>
              <p className="activity-lbl">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Nostrum voluptatum rerum ducimus numquam! Nisi, provident saepe
                adipisci corporis pariatur ex cupiditate harum, et, fugiat natus
                impedit qui. Facilis, porro dolore.
              </p>
              <p className="activity-time-lbl">
                Today 02:00 PM{" "}
                <span>
                  <label className="check_container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </span>
              </p>
            </div> */}

            {/* Green */}

            {/* <div className="activity-card">
              <div className="v-div completed"></div>
              <p className="card-header">My Activity 1</p>
              <p className="activity-lbl completed-lbl">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Nostrum voluptatum rerum ducimus numquam! Nisi, provident saepe
                adipisci corporis pariatur ex cupiditate harum, et, fugiat natus
                impedit qui. Facilis, porro dolore.
              </p>
              <p className="activity-time-lbl ">
                Today 02:00 PM
                <span>
                  <label className="check_container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </span>
              </p>
            </div> */}
          </div>
          {/* <div className="activity-content">
            <div className="activity mt-4">
              <div className="activity-header">
                <p className="card-header"> {NotesDetails?.title}</p>
              </div>

              <p className="activity-content  ">{NotesDetails?.description}</p>
              {NotesDetails ? (
                <p className="activity-time-lbl">
                  {moment(NotesDetails?.createdAt).format("lll")}
                </p>
              ) : (
                <h3>Data not available</h3>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MyActivityList;
