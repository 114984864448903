import React from "react";
import RouteName from "./RouteDetails";
import Login from "./../Components/Auth/Login";
import Home from "../Components/Auth/Home";
import Registration1 from "../Components/Auth/Registration1";
import Registration2 from "../Components/Auth/Registration2";
import Dashboard from "../Components/Pages/Dashboard";
import Profile from "../Components/Pages/Profile";
import ProfileEdit from "../Components/Pages/EditProfile";
import VerifyOtp from "../Components/Auth/VerifyOTP";
import VerifyOtpMail from "../Components/Auth/VerifyOtpMail";
import MyCalender from "../Components/Pages/MyCalender";
import MyActivityList from "../Components/Pages/MyActivityList";
import ChatBox from "../Components/Pages/Chatbox";
import Groups from "../Components/Pages/Groups";
import GroupDetails from "../Components/Pages/GroupDetails";
import MembersSelection from "../Components/Pages/MembersSelection";
import ClientList from "../Components/Pages/ClientsList";
import ClientDetails from "../Components/Pages/ClientDetials";
import ClientViewDetDemo from "../Components/Pages/ClientViewDetDemo";
import Sessions from "../Components/Pages/Sessions";
import SessionDetails from "../Components/Pages/SessionDetails";
import Settings from "../Components/Pages/Settings";
import MyReferrel from "../Components/Pages/MyReferrel";
import SelectReferral1 from "../Components/Pages/SelectReferral1";
import SelectReferral2 from "../Components/Pages/SelectReferral2";
import DigitalNotes from "../Components/Pages/DigitalNotes";
import AiAsistance from "../Components/Pages/AiAsistance";
import AddEditSessionDetails from "../Components/Pages/AddEditSessionDetails";
import Playground from "../Components/Pages/Playground";
import JoinSession from "../Components/Pages/JoinSession";
// import FollowUpList from "../Components/Pages/FollowUpList";
import Notifications from "../Components/Pages/Notifications";
import DisplayMyCalender from "../Components/Pages/DisplayMyCalender";
import VideoCall from "../Components/VideoCall/VideoCall";
import ProtectedRoute from "./ProtectedRoute";

const AdminRoute = [
  {
    path: RouteName.Auth.Home,
    element: <Home />,
    exact: true,
  },
  {
    path: RouteName.Auth.Login,
    element: <Login />,
    exact: true,
  },
  {
    path: RouteName.Auth.Registration1,
    element: <Registration1 />,
    exact: true,
  },
  {
    path: RouteName.Auth.Registration2,
    element: <Registration2 />,
    exact: true,
  },

  {
    path: `${RouteName.Auth.VerifyOtp}/:path`,
    // 1: login
    // 2: registration
    element: <VerifyOtp />,
    exact: true,
  },

  {
    path: `${RouteName.Auth.verifyOtpMail}/:path`,
    element: <VerifyOtpMail />,
    exact: true,
  },
  {
    path: RouteName.Auth.Dashboard,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Dashboard />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.Profile,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Profile />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.ProfileEdit,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <ProfileEdit />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.MyCalender}/:id`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <MyCalender />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.DisplayMyCalender}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <DisplayMyCalender />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.MyActivityList,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <MyActivityList />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.ChatBox}/:GroupId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <ChatBox />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.Groups,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Groups />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.GroupDetails}/:GroupId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <GroupDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.MembersSelection}/:GroupId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <MembersSelection />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.ClientList}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <ClientList />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.ClientDetails}/:ClientId/isActive/:isActiveId/isDelete/:isDelete`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <ClientDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },

  // Demo client details for graph

  {
    path: `${RouteName.Auth.ClientViewDetDemo}/:ClientId/isActive/:isActiveId/isDelete/:isDelete`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <ClientViewDetDemo />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.Sessions}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <Sessions />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.SessionDetails}/:SessionId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <SessionDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.Settings}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <Settings />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.MyReferrel}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <MyReferrel />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.SelectReferral1}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <SelectReferral1 />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.SelectReferral2}/:Clientid`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <SelectReferral2 />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.DigitalNotes}/:NotesId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <DigitalNotes />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.AiAsistance}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <AiAsistance />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.AddEditSessionDetails}/:SessionId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <AddEditSessionDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.Playground}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <Playground />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.JoinSession}/:SessionId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <JoinSession />
      </ProtectedRoute>
    ),
    exact: true,
  },
  // {
  //   path: `${RouteName.Auth.FollowUpList}`,
  //   element: (
  //     <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
  //       {" "}
  //       <FollowUpList />
  //     </ProtectedRoute>
  //   ),
  //   exact: true,
  // },
  {
    path: `${RouteName.Auth.Notifications}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <Notifications />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.VideoCall}/:SessionId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <VideoCall />
      </ProtectedRoute>
    ),
    exact: true,
  },

  // {
  //     path: RouteName.Auth.NewPassword,
  //     element: < NewPassword />,
  //     exact: true,
  // },
];

export default AdminRoute;
