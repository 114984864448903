import BaseApi from "./BaseApi";

const API = {
  Auth: {
    LoginAPIEmail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/resendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    LoginAPIMobile: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/mresendotp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    Register: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/create`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyViaMobile: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify/otpphon`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyViaMail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/verify/otpmail`,
        data: props.data,
        tokenRequired: true,
      });
    },
    TherapistCalenderUpdate: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/theropistcalender/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Profile: {
    GetProfileData: () => {
      return BaseApi({
        method: "GET",
        url: `users/userinfo`,
        tokenRequired: true,
      });
    },
    UpdateProfileAPI: (props) => {
      return BaseApi({
        method: "PUT",
        url: `users/${props.UserId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  JoinSession: {
    Joinmeeting: (props) => {
      return BaseApi({
        method: "POST",
        url: `session/joinmeeting`,
        data: props.data,
        tokenRequired: true,
      });
    },
  },
  Notes: {
    CreateNotes: (props) => {
      return BaseApi({
        method: "POST",
        url: `notes/createnotes`,
        data: props.data,
        tokenRequired: true,
      });
    },

    NotesList: (props) => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist`,
        data: props.data,
        tokenRequired: true,
      });
    },
    NotesDetailbyId: (props) => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist/${props.NoteId}`,
        data: props.data,
        tokenRequired: true,
      });
    },

    UpdateNote: (props) => {
      return BaseApi({
        method: "PUT",
        url: `notes/updatenote/${props.NoteId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
    NoteResponseList: (props) => {
      return BaseApi({
        method: "GET",
        url: `notes/notelist/${props.NoteId}`,
        tokenRequired: true,
      });
    },
  },
  MyThreapist: {
    ChatList: () => {
      return BaseApi({
        method: "GET",
        url: `/users/messages`,
        tokenRequired: true,
      });
    },
  },
  Group: {
    CreateGroups: (props) => {
      return BaseApi({
        method: "POST",
        url: `groups/creategroup`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GroupList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groups/grouplist?${
          props.Grouptype ? "key=" + props.Grouptype : ""
        }${props.serchKey ? "&name=" + props.serchKey : ""}&istheropist=yes`,
        tokenRequired: true,
      });
    },
    ClientListing: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/list?page=1&role=Client${
          props.serchKey ? "&name=" + props.serchKey : ""
        }`,
        tokenRequired: false,
      });
    },
    TherapistListing: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/list?page=1&role=Therapist${
          props.serchKey ? "&name=" + props.serchKey : ""
        }`,
        tokenRequired: false,
      });
    },
    UpdateGroups: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/updategroupinfo`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GroupsChatList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/chat/chatlist/${props.group_id}`,
        tokenRequired: true,
      });
    },
    SelectGroupMembers: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/joingroup`,
        data: props.data,
        tokenRequired: true,
      });
    },
    DeSelectGroupMembers: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/deleteusergroup`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Groupcount: (props) => {
      return BaseApi({
        method: "POST",
        url: `/groups/groupcount`,
        data: props.data,
        tokenRequired: true,
      });
    },
    GroupDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/groups/groupview?id=${props.GroupId}`,
        tokenRequired: true,
      });
    },
  },
  Event: {
    CreateEvent: (props) => {
      return BaseApi({
        method: "POST",
        url: `event/createevent`,
        data: props.data,
        tokenRequired: true,
      });
    },

    EventList: (props) => {
      return BaseApi({
        method: "GET",
        url: `event/eventlist?key=${props.GroupId}`,
        tokenRequired: true,
      });
    },

    MediaList: (props) => {
      return BaseApi({
        method: "GET",
        url: `groupmedia/medialist?key=${props.GroupId}`,
        tokenRequired: true,
      });
    },
    MediaListIndividual: (props) => {
      return BaseApi({
        method: "GET",
        // url: `groupmedia/medialist?key=${props.GroupId}`,
        url: `groupmedia/medialistindividual?id=${props.UserId}`,
        tokenRequired: true,
      });
    },
    CreateMedia: (props) => {
      return BaseApi({
        method: "POST",
        url: `groupmedia/createmedia`,
        data: props.data,
        tokenRequired: true,
      });
    },

    CreateMediaIndividual: (props) => {
      return BaseApi({
        method: "POST",
        url: `groupmedia/createmedia`,
        data: props.data,
        tokenRequired: true,
      });
    },
    SingleUserEventList: (props) => {
      return BaseApi({
        method: "GET",
        url: `event/userevent?userid=${props.UserId}`,
        tokenRequired: true,
      });
    },

    // SingleUserEventListNew: (props) => {
    //   return BaseApi({
    //     method: "GET",
    //     url: `users/usereventanswer?id=${props.UserId}`,
    //     tokenRequired: true,
    //   });
    // },

    SingleUserEventListNew: (props) => {
      return BaseApi({
        method: "GET",
        url: `event/eventlistindividualtherapist?id=${props.UserId}`,
        tokenRequired: true,
      });
    },
  },
  Client: {
    // ClientActiveList: () => {
    //   return BaseApi({
    //     method: "GET",
    //     url: `/notes/theropistclientlistactive`,
    //     tokenRequired: true,
    //   });
    // },
    ClientActiveList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/upcomingsessiontheropistlistclient?theropist=${
          props.UserId
        }&isapprove=${props.isApproved}&client=${
          props.ClientId ? props.ClientId : ""
        }&isdelete=0${props.serchKey ? "&name=" + props.serchKey : ""}`,
        tokenRequired: true,
      });
    },
    ClientInActiveList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/upcomingsessiontheropistlistclient?theropist=${
          props.UserId
        }&client=${props.ClientId ? props.ClientId : ""}&isdelete=1`,
        tokenRequired: true,
      });
    },
    UpcomingList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/upcomingsessiontheropistlist?theropist=${
          props.UserId
        }&isapprove=${props.isApproved}&client=${
          props.ClientId ? props.ClientId : ""
        }`,
        tokenRequired: true,
      });
    },
    UpcomingClientList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/upcomingsessiontheropistlistclient?theropist=${
          props.UserId
        }&isapprove=${props.isApproved}&client=${
          props.ClientId ? props.ClientId : ""
        }&isdelete=${props.isDelete}`,
        tokenRequired: true,
      });
    },

    // `/groups/grouplist?${
    //   props.Grouptype ? "key=" + props.Grouptype : ""
    // }${props.serchKey ? "&name=" + props.serchKey : ""}&istheropist=yes`

    UpcomingClientList: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/upcomingsessiontheropistlistclient?theropist=${
          props.UserId
        }${props.isApproved ? "&isapprove=" + props.isApproved : ""}&client=${
          props.ClientId ? props.ClientId : ""
        }${props.isDelete ? "&isdelete=" + props.isDelete : ""}`,
        tokenRequired: true,
      });
    },
    UpcomingListdashboard: (props) => {
      return BaseApi({
        method: "GET",
        // url: `/session/upcomingsessiontheropistlist?theropist=${props.UserId}&isapprove=${props.isApproved}`,
        url: `/session/currentsessionlisttheropist`,

        tokenRequired: true,
      });
    },
    UpdateSession: (props) => {
      return BaseApi({
        method: "PUT",
        url: `/session/updatesession/${props.clientId}`,
        data: props.data,
        tokenRequired: true,
      });
    },

    UpdateClientApprove: (props) => {
      return BaseApi({
        method: "PUT",
        url: `/session/updateclientapprove/${props.clientId}`,
        data: props.data,
        tokenRequired: true,
      });
    },
    SessionHistory: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/sumarysessionlisttheropist?client=${props.ClientId}&theropist=${props.TherapistID}`,
        tokenRequired: true,
      });
    },

    SessionSummary: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/sumarysessionlisttheropist?theropist=${props.UserId}`,
        tokenRequired: true,
      });
    },
    Digital_Notes: (props) => {
      return BaseApi({
        method: "GET",
        url: `/notes/usernotelist?id=${props.UserId}`,
        tokenRequired: true,
      });
    },

    //geeta
    SessionDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/session/sessiondetails/${props.SessionId}`,
        tokenRequired: true,
      });
    },
  },
  Refer: {
    CreateRefer: (props) => {
      return BaseApi({
        method: "POST",
        url: `/referrel/createrefer`,
        data: props.data,
        tokenRequired: true,
      });
    },
    MyreferralList: () => {
      return BaseApi({
        method: "GET",
        url: `/referrel/referlist`,
        tokenRequired: true,
      });
    },
  },
  Therapist: {
    TherpistAdd: (props) => {
      return BaseApi({
        method: "POST",
        url: `/users/theropistAdd`,
        data: props.data,
        tokenRequired: true,
      });
    },
    getTherpistDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/theropistdtls?date=${props.date}`,
        tokenRequired: true,
      });
    },
    CreatMessageid: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/creatmessageid`,
        data: props.data,
        tokenRequired: true,
      });
    },
    Chatapi: (props) => {
      return BaseApi({
        method: "POST",
        url: `/aiconnect/chatapi`,
        data: props.data,
        tokenRequired: true,
      });
    },
    ChatHistory: (props) => {
      return BaseApi({
        method: "GET",
        url: props.msgId
          ? `/aiconnect/aichatlist?messageid=${props.msgId}`
          : `/aiconnect/aichatlist`,
        tokenRequired: true,
      });
    },

    Aichatlisthistory: () => {
      return BaseApi({
        method: "GET",
        url: `/aiconnect/aichatlisthistory`,
        tokenRequired: true,
      });
    },
  },
  // AIassistant: {
  //   CreatmessageID: (props) => {
  //     return BaseApi({
  //       method: "POST",
  //       url: `/aiconnect/creatmessageid`,
  //       data: props.data,
  //       tokenRequired: true,
  //     });
  //   },
  //   chatAPI: (props) => {
  //     return BaseApi({
  //       method: "POST",
  //       url: `/aiconnect/chatapi`,
  //       data: props.data,
  //       tokenRequired: true,
  //     });
  //   },
  // },
  PlayGround: {
    //PlayGround API call
    // Mental Health Resources
    Mental_HealthAPI: (props) => {
      return BaseApi({
        method: "GET",
        url: `info/infolist?key=${props.health}`,
        tokenRequired: false,
      });
    },

    // Videos
    Videos_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/info/infolist?key=${props.kind}`,
        tokenRequired: false,
      });
    },

    // Self Assessment
    SelfAsses_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/info/infolist?key=${props.selfAsses}`,
        tokenRequired: false,
      });
    },
  },

  NotificationApis: {
    getAllnotificationApi: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/notification`,
        tokenRequired: true,
      });
    },
  },
  Survey: {
    AnswerSurvey: (props) => {
      return BaseApi({
        method: "GET",
        url: `/survaytype/answersurvey?id=${props.ClientId}`,
        tokenRequired: true,
      });
    },
  },
};

export default API;
