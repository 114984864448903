import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import backarrow from "../../assets/images/icons/back-arrow.png";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const path = useParams();
  const mobile = JSON.parse(localStorage.getItem("Mobileno"));
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [counter, setCounter] = useState(30);
  const [masbableMobile, setmasbableMobile] = useState();
  const [ApiError, setApiError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const goBackFun = () => {
    navigate(-1);
    localStorage.removeItem("Mobileno");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Code to execute after 30 seconds
    }, 30000);

    const interval = setInterval(() => {
      if (counter) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [counter]);

  function handleChange(event, index) {
    const { value } = event.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });

      if (value.length === 1 && index < 3) {
        inputRefs[index + 1].current.focus();
      } else if (value.length === 0 && index > 0) {
        if (event.key === "Backspace") {
          inputRefs[index - 1].current.focus();
        }
      }
    }
  }
  const handleKeyDown = (event, index) => {
    const { value } = event.target;
    if (event.key === "Backspace") {
      if (value.length === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };
  const newotp = otp.join("");

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    setApiError("");
    setIsSubmit(true);

    // console.log("locat", path.path)
    if (path.path == 2) {
      setLoading(true);
      try {
        API.Auth.VerifyViaMobile({
          data: {
            phone: mobile,
            otp: newotp,
            role: "Therapist",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            setApiError(response?.data?.message);
            localStorage.setItem("AuthTokenTherapist", response?.data?.token);
            localStorage.setItem(
              "TherapistUserData",
              JSON.stringify(response?.data)
            );
            navigate(`../${AdminRoute?.Auth?.verifyOtpMail}/2`);
          } else {
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        setApiError(error?.message);
        console.log(error);
        setLoading(false);
      }
    }
    if (path.path == 1) {
      setLoading(true);
      try {
        API.Auth.VerifyViaMobile({
          data: {
            phone: mobile,
            otp: newotp,
            role: "Therapist",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            setApiError(response?.data?.message);
            localStorage.setItem("AuthTokenTherapist", response?.data?.token);
            localStorage.setItem(
              "TherapistUserData",
              JSON.stringify(response?.data)
            );
            navigate(`../${AdminRoute?.Auth?.Dashboard}`);
          } else {
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        setApiError(error?.message);
        console.log(error);
        setLoading(false);
      }
    }
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    setApiError("");
    setLoading(true);
    try {
      API.Auth.LoginAPIMobile({
        data: {
          phone: mobile,
          role: "Therapist",
          resend: "true",
        },
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          setApiError(response?.data?.message);
          // toast.success("Resent OTP");
        } else {
          setApiError(response?.data?.message);
        }
        setLoading(false);
      });
    } catch (error) {
      setApiError(error?.message);
      console.log(error);
      setLoading(false);
    }
  };

  // =====Mobile number hide======

  const mobilefun = () => {
    if (/^\d{10}$/.test(mobile)) {
      var numberString = mobile.toString();
      var lastFourDigits = numberString.slice(-4);
      var maskedPart = "X".repeat(numberString.length - 4);
      var maskedNumber = maskedPart + lastFourDigits;
      setmasbableMobile(maskedNumber);
    }
  };

  useEffect(() => {
    mobilefun();
  }, []);

  return (
    <>
      <div className="land-container">
        <div className="logo-container">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div className="login-container">
          <div className="register-therapist-div register-therapist">
            <form>
              <h2
                className="heading fw-600 py-2"
                // onClick={() => navigate(-1)}
                onClick={() => goBackFun()}
              >
                <img className="back-arrow" src={backarrow} />
                Verify via Mobile OTP
              </h2>
              <div>
                <label className="pb-2">
                  * Enter OTP sent on the registered Mobile No.{" "}
                  <b>&quot;{masbableMobile}&quot;</b>
                </label>
                <div
                  style={{ justifyContent: "space-evenly", display: "flex" }}
                >
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      id={`otp-${index}`}
                      name={`otp-${index}`}
                      value={value}
                      onChange={(event) => handleChange(event, index)}
                      onKeyDown={(event) => handleKeyDown(event, index)}
                      maxLength={1}
                      ref={inputRefs[index]}
                      autoComplete="off"
                      className="form-control custom-input-text-primary otp-input mb-0"
                    />
                  ))}
                </div>
              </div>
              <p className="text-danger mb-0 mt-2">{ApiError}</p>

              <button
                type="submit"
                className="button_common w-100 mt-3 mb-3 text-center"
                role="button"
                disabled={
                  otp[3] === "" || (isSubmit === true && ApiError === "")
                    ? true
                    : false
                }
                onClick={(e) => handleVerifyOtp(e)}
              >
                Verify via OTP
              </button>
              {loading ? <div className="loader_orange"></div> : <></>}
              <div className="resend-link" onClick={(e) => handleResendOtp(e)}>
                <span>Didn't receive the OTP? </span>
                <strong className="cursor-pointer">Resend OTP</strong>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
