import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import notesLogo from "../../assets/images/icons/Group 22440.png";
import respIcon from "../../assets/images/icons/Icon feather-user.png";
import pencilIcon from "../../assets/images/icons/Icon feather-edit-2.png";
import { useParams } from "react-router-dom";
import API from "../../Api/Api";
import moment from "moment";
import { toast } from "react-toastify";

const DigitalNotes = () => {
  const param = useParams();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [resp, setResp] = useState(false);
  const [DNotesResponse, setDNotesResponse] = useState("");
  const [NotesDetails, setNotesDetails] = useState([]);
  const [responselist, setresponselist] = useState([]);
  const [Responsedatetime, setResponsedatetime] = useState("");

  const handleRespoClick = () => {
    setResp(true);
  };

  const handleEditClick = () => {
    setResp(false);
  };

  const handleSaveResponse = (e) => {
    e.preventDefault();
    try {
      API.Notes.UpdateNote({
        data: {
          response: {
            theropistid: UserData?.userdata?._id,
            theropistname: UserData?.userdata?.fullname,
            responce: DNotesResponse,
          },
          is_seen: 0,
        },
        NoteId: param?.NotesId,
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          //   toast.success("update successfull");
          getResponsesList();
          setDNotesResponse("");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getNoteDetails = () => {
    try {
      API.Notes.NotesDetailbyId({
        NoteId: param?.NotesId,
      }).then((response) => {
        // console.log("Note Deatils", response?.data);
        if (response?.data?.status === 1) {
          setNotesDetails(response?.data?.data_all[0]);
          // navigate(`../${AdminRoute?.Auth?.MyCalender}`);
          // set localstorage data
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getResponsesList = () => {
    try {
      API.Notes.NoteResponseList({
        NoteId: param?.NotesId,
      }).then((response) => {
        console.log(response?.data?.data_all[0]?.updatedAt);
        if (response?.data?.status === 1) {
          setresponselist(response?.data?.data_all[0]?.response);
          setResponsedatetime(response?.data?.data_all[0]?.updatedAt);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // side effect

  useEffect(() => {
    getNoteDetails();
    getResponsesList();
  }, []);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
      </div>
      <div className="content">
        <Header
          isBack={true}
          header={"Digital Notes"}
          isShowIcons={false}
        ></Header>
        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 13%)", padding: "14px" }}
        >
          <div className="digital-note-cont pb-4">
            <div className="d-flex flex-row align-items-center pt-2">
              <img src={notesLogo} style={{ height: "40px" }} />
              <div className="d-flex flex-column mx-2">
                <p
                  className="ps-2 m-0 fw-bold sub-heading"
                  style={{ fontSize: "16px" }}
                >
                  {/* Mindfulness: A state of Art */}
                  {NotesDetails?.title}
                </p>
                <p className="ps-2 m-0" style={{ fontSize: "12px" }}>
                  {/* Today, 11:00PM */}
                  {moment(NotesDetails?.createdAt).format("lll")}
                </p>
              </div>
            </div>
            <p className="py-3" style={{ fontSize: "14px" }}>
              {NotesDetails?.description}
            </p>

            {/* <p style={{ fontSize: "14px" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo
              labore dolorum quam aliquam ipsam, at illum quia nobis neque natus
              pariatur minus obcaecati aperiam iure blanditiis inventore
              accusamus dicta earum. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Animi accusantium vero alias architecto dicta
              tenetur obcaecati doloribus qui iusto incidunt amet ea ullam dolor
              voluptatum, laudantium similique natus sunt laboriosam.
            </p> */}
            {!responselist ? (
              <>
                <textarea
                  name="DNotesResponse"
                  className="respo_inp"
                  value={DNotesResponse}
                  placeholder="Add Response"
                  onChange={(e) => setDNotesResponse(e.target.value)}
                ></textarea>
              </>
            ) : (
              <></>
            )}
          </div>

          {!responselist ? (
            <>
              <button
                className="btn_pr my-3"
                // onClick={() => handleRespoClick()} /// this for read only response view
                onClick={(e) => handleSaveResponse(e)}
              >
                Submit
              </button>
            </>
          ) : (
            <></>
          )}

          {/* {!responselist?.length ? (
            <div className="alert alert-warning" role="alert">
              Response not Available
            </div>
          ) : (
            responselist?.length &&
            responselist.map((val, i) => {
              return (
                <div className="col-lg-12 col-md-12 mb-3" key={i}>
                  <div className="response-box p-3">
                    <div className="d-flex justify-content-between">
                      <p className="fw-bold">Response </p>
                      <p className="text-muted">{moment().format("ll")}</p>
                    </div>

                    <div className="responce-by">
                        <i class="fa fa-user me-1"></i> Meenakshi Narayan
                      </div>

                    <p className="responce-details">{val?.responce}</p>
                  </div>
                </div>
              );
            })
          )} */}

          {!responselist ? (
            <div className="alert alert-warning" role="alert">
              Response not Available
            </div>
          ) : (
            <div className="col-lg-12 col-md-12 mb-3">
              <div className="response-box p-3">
                <div className="d-flex justify-content-between">
                  <p className="fw-bold">Response </p>
                  <p className="text-muted">
                    {moment(Responsedatetime).format("lll")}
                  </p>
                </div>

                {/* <div className="responce-by">
                        <i class="fa fa-user me-1"></i> Meenakshi Narayan
                      </div> */}

                <p className="responce-details">{responselist?.responce}</p>
              </div>
            </div>
          )}
          {/* 
          {resp ? (
            <>
              <div className="response-cont my-2">
                <div className="repsonse-head d-flex justify-content-between my-2 align-items-center">
                  <div>
                    <img src={respIcon} style={{ height: "18px" }} />
                    <label className="respo-head-lbl">Response</label>
                    <img
                      onClick={() => handleEditClick()}
                      src={pencilIcon}
                      style={{ height: "18px" }}
                    />
                  </div>
                  <div>
                    <label>Today, 11:00 PM</label>
                  </div>
                </div>
                <p style={{ fontSize: "14px", margin: 0 }}>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo
                  labore dolorum quam aliquam ipsam, at illum quia nobis neque
                  natus pariatur minus obcaecati aperiam iure blanditiis
                  inventore accusamus dicta earum. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Animi accusantium vero alias
                  architecto dicta tenetur obcaecati doloribus qui iusto
                  incidunt amet ea ullam dolor voluptatum, laudantium similique
                  natus sunt laboriosam.
                </p>
              </div>
            </>
          ) : (
            <></>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default DigitalNotes;
