import React from "react";
import Home from '../../assets/images/icons/Icon feather-home.png';
import CLients from '../../assets/images/icons/mentalhealthart-01.png';
import Sessions from '../../assets/images/icons/Path 10368.png';
import Groups from '../../assets/images/icons/mentalhealthart-31.png';
import AiAsistance from '../../assets/images/icons/Path 10368.png';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";

const MobMenuBar = () => {

  const navigate = useNavigate();
    return (
        <div className="mob-menu-container">
            <NavLink to={`/`}>
                <div className="mobile-menu-item">
                    <img src={Home} />
                    <div>
                        Home
                    </div>
                </div>
            </NavLink>


            <div className="mobile-menu-item" onClick={() => navigate(`../${AdminRoute.Auth.ClientList}`)}>
                <img src={CLients} />
                <div>
                    Clients
                </div>
            </div>

            <div className="mobile-menu-item" onClick={() => navigate(`../${AdminRoute.Auth.Sessions}`)}>
                <img src={Sessions} />
                <div>
                    Sessions
                </div>
            </div>
            <NavLink to={`/groups`}>
                <div className="mobile-menu-item">
                    <img src={Groups} />
                    <div>
                        Groups
                    </div>
                </div>
            </NavLink>


            <div className="mobile-menu-item"  onClick={() => navigate(`../${AdminRoute.Auth.AiAsistance}`)}>
                <img src={AiAsistance} />
                <div>
                    AI Asistance
                </div>
            </div>

        </div>
    );
}

export default MobMenuBar;