import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
// import commoan from "../../environment/Config";
import commoan from "../../Api/config";

// import profileImg from '../../assets/images/icons/Icon awesome-user-circle.png';
import groupIcon from "../../assets/images/icons/Group 21730.png";
import groupIcon2 from "../../assets/images/icons/Group 21756.png";
import API from "../../Api/Api";

const Playground = () => {
  const [Mental_HealthList, setMental_HealthList] = useState([]);
  const [Videos, setVideos] = useState([]);
  const [Assessment_List, setAssessment_List] = useState([]);
  const [loadingMental_HealthList, setLoadingMental_HealthList] =
    useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loadingAssessment_Lists, setLoadingAssessment_List] = useState(false);

  //get all data
  useEffect(() => {
    getMntal_HealthList();
    get_VideoList();
    get_AssesmentList();
  }, []);

  //api call for Mental Health  list
  const getMntal_HealthList = () => {
    setLoadingMental_HealthList(true);
    try {
      API.PlayGround.Mental_HealthAPI({
        health: "health",
      }).then((response) => {
        // console.log("Mental health", response?.data?.data_all);
        if (response?.data.status === 1) {
          setMental_HealthList(response?.data?.data_all);
        }
        setLoadingMental_HealthList(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingMental_HealthList(false);
    }
  };

  //api call for videos

  //api call for Mental Health  list
  const get_VideoList = () => {
    setLoadingVideos(true);
    try {
      API.PlayGround.Videos_API({
        kind: "kind",
      }).then((response) => {
        // console.log("Vedio", response?.data?.data_all);
        if (response?.data.status === 1) {
          setVideos(response?.data?.data_all);
        }
        setLoadingVideos(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingVideos(false);
    }
  };

  //api  call for self assessment

  const get_AssesmentList = () => {
    setLoadingAssessment_List(true);
    try {
      API.PlayGround.SelfAsses_API({
        selfAsses: "selfAsses",
      }).then((response) => {
        // console.log("setAssessment_List", response?.data?.data_all);
        if (response?.data.status === 1) {
          setAssessment_List(response?.data?.data_all);
        }
        setLoadingAssessment_List(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingAssessment_List(false);
    }
  };

  //   const get_video = (e, video) => {
  //     console.log(video);
  //     const response = {
  //       file_path: video,
  //     };
  //     window.open(response.file_path);
  //   };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header isBack={true} header={"Playground"}></Header>
        <MobMenuBar></MobMenuBar>
        <div className="sub-head px-3 d-flex mt-3">
          <div>
            {/* <p className="sub-lbl">
              Here are list of your upcoming post and past sessions
            </p> */}
          </div>
        </div>
        <div
          className="dashboard-container pt-0"
          style={{ maxHeight: "calc(100% - 196px)" }}
        >
          <div className="sub-head mt-3 px-3">
            <p className="sub-heading">Mental Health Resource</p>
          </div>
          {/* ==== Mental Health ===== */}
          <div className="common-list-hor px-3">
            {loadingMental_HealthList ? (
              <span>loading...</span>
            ) : Mental_HealthList.length === 0 ? (
              <span>Data not found</span>
            ) : (
              Mental_HealthList?.length &&
              Mental_HealthList?.map((val, i) => {
                return (
                  <div
                    key={i}
                    className="group-card"
                    style={{ borderRadius: "14px" }}
                  >
                    <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                      <img
                        style={{
                          objectFit: "cover",
                          maxHeight: "95px",
                          width: "143px",
                        }}
                        // src={groupIcon2}
                        src={
                          `${commoan.baseurl}` +
                          `images/` +
                          val?.backgroundimagename
                        }
                        alt={
                          `${commoan.baseurl}` +
                          `images/` +
                          val?.backgroundimagename
                        }
                      />
                    </div>

                    <p className="mb-0 mt-2 group-card-lbl">
                      {/* Break The Silence */}
                      {val?.header}
                    </p>
                    <p className="mb-0">Author: {val?.header}</p>
                    <p className="mb-0" style={{ fontSize: "13px" }}>
                      {/* Jitesh Naidu */}
                      {val?.name}
                    </p>
                  </div>
                );
              })
            )}
          </div>
          {/* ==== Mental Health Ends===== */}

          <div className="sub-head mt-3 px-3">
            <p className="sub-heading">Videos</p>
          </div>

          <div className="common-list-hor px-3">
            {loadingVideos ? (
              <span>loading...</span>
            ) : Videos.length === 0 ? (
              <span>Data not found</span>
            ) : (
              Videos?.map((val, i) => {
                return (
                  <div
                    key={i}
                    className="group-card"
                    style={{ borderRadius: "14px" }}
                  >
                    <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                      <img
                        style={{
                          objectFit: "cover",
                          maxHeight: "95px",
                          width: "143px",
                        }}
                        // src={groupIcon2}
                        src={
                          `${commoan.baseurl}` +
                          `images/` +
                          val?.backgroundimagename
                        }
                        alt={
                          `${commoan.baseurl}` +
                          `images/` +
                          val?.backgroundimagename
                        }
                      />
                    </div>

                    <p className="mb-0 mt-2 group-card-lbl">{val?.header}</p>
                    <p className="mb-0">Author:</p>
                    <p className="mb-0" style={{ fontSize: "13px" }}>
                      {val?.name}
                    </p>
                  </div>
                );
              })
            )}
          </div>

          <div className="sub-head mt-3 px-3">
            <p className="sub-heading">Self Assessment Test</p>
          </div>
          <div className="common-list-hor px-3">
            {loadingAssessment_Lists ? (
              <span>loading...</span>
            ) : Assessment_List.length === 0 ? (
              <span>Data not found</span>
            ) : (
              Assessment_List?.map((val, i) => {
                return (
                  <div
                    key={i}
                    className="group-card"
                    style={{ borderRadius: "14px" }}
                  >
                    <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                      <img
                        style={{
                          objectFit: "cover",
                          maxHeight: "95px",
                          width: "143px",
                        }}
                        // src={groupIcon2}
                        src={
                          `${commoan.baseurl}` +
                          `images/` +
                          val?.backgroundimagename
                        }
                        alt={
                          `${commoan.baseurl}` +
                          `images/` +
                          val?.backgroundimagename
                        }
                      />
                    </div>

                    <p className="mb-0 mt-2 group-card-lbl">{val?.header}</p>
                    <p className="mb-0">Author:{val?.header}</p>
                    <p className="mb-0" style={{ fontSize: "13px" }}>
                      {val?.name}
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playground;
