import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userImg from "../../assets/images/icons/userIMG.jpg";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import API from "../../Api/Api";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import RouteName from "../../Route/RouteDetails";
import SideBarMenu from "../Common/SideBarMenu";
import moment from "moment/moment";

const Profile = () => {
  const navigate = useNavigate();
  const [UserInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          // console.log(response?.data?.data);
          setUserInfo(response?.data?.data);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>

      <div className="content">
        <Header isBack={true} header={"Profile"} isSetting={true}></Header>

        <div className="desc-container">
          {loading ? (
            <div className="loader_orange"></div>
          ) : (
            <>
              {" "}
              <div>
                <h3 className="sub-heading">Personal Details</h3>

                <p className="field-lbl mb-0">
                  Here is an overview of your sessions letest activity.
                </p>
              </div>
              <div className="img-container py-3 px-2">
                <span className="custom-drag-box">
                  <img
                    className="reg-user-img form-upload-photo-preview2 "
                    src={UserInfo?.image ? UserInfo?.image : userImg}
                  />
                </span>

                {/* <p className="mb-3 mt-2 redText text-10">
                  Note: The pic should be 100 x 100 pixels.
                </p> */}
              </div>
              <div className="field-container-main">
                <div className="field-conteiner w-100">
                  <p>
                    <label className="field-lbl">Full Name:</label>
                    <label className="field-desc">{UserInfo?.fullname}</label>
                  </p>

                  <p>
                    <label className="field-lbl">Email Id:</label>
                    <label className="field-desc">{UserInfo?.email}</label>
                  </p>

                  <p>
                    <label className="field-lbl">Contact No:</label>
                    <label className="field-desc">{UserInfo?.phone}</label>
                  </p>
                </div>

                <div className="field-conteiner w-100">
                  {/* <p>
                    <label className="field-lbl">Age:</label>
                    <label className="field-desc">{UserInfo?.age} years</label>
                  </p> */}

                  <p>
                    <label className="field-lbl">Date of Birth:</label>
                    <label className="field-desc">
                      {moment(UserInfo?.dob).format("DD/MM/YYYY")}
                    </label>
                  </p>

                  <p>
                    <label className="field-lbl">Gender:</label>
                    <label className="field-desc">{UserInfo?.gender}</label>
                  </p>

                  <p>
                    <label className="field-lbl">Years of experience:</label>
                    <label className="field-desc">
                      {UserInfo?.experience} Years
                    </label>
                  </p>
                </div>

                <p>
                  <label className="field-lbl">Expertise:</label>
                  <p className="field-desc">{UserInfo?.experties}</p>
                </p>
              </div>
              <div>
                <h4>Quote you resonate with</h4>
                <p>&quot;{UserInfo?.qote}&quot;</p>
              </div>
              <div className="bottom-btn-container w-100">
                <button
                  className="sec-btn"
                  onClick={() => navigate(`../${RouteName?.Auth?.ProfileEdit}`)}
                >
                  Edit Profile
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <MobMenuBar></MobMenuBar>
    </div>
  );
};

export default Profile;
