export const formatDateTime = (inputDate) => {
    const today = new Date();
    const date = new Date(inputDate);

    // Check if the date is today
    if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    ) {
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return 'Today ' + date.toLocaleTimeString(undefined, options);
    }

    // Check if the date is yesterday
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
    ) {
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return 'Yesterday ' + date.toLocaleTimeString(undefined, options);
    }

    // If not today or yesterday, return the actual date and time
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    inputDate = date.toLocaleDateString(undefined, options)
    const parts = inputDate.split(' at ');

    if (parts.length === 2) {
        const datePart = parts[0];
        const timePart = parts[1];
        return `${datePart} ${timePart}`;
    }

    // If the input format is not as expected, return the original string
    return inputDate;
    // return date.toLocaleDateString(undefined, options);
};


export const checkDate = (dateString) => {
    // Create Date objects for the target date and the current date
    const targetDate = new Date(dateString);
    const currentDate = new Date();

    // Compare the two dates
    if (targetDate > currentDate) {
        return true
    } else if (targetDate < currentDate) {
        return false
    } else {
        return true
    }
}

export const checkButtonRange = (newStartDate, newEndDate) => {
    const currentDate = new Date();
    const startDateTime = new Date(newStartDate);
    const endDateTime = new Date(newEndDate);

    const isStartDateValid = startDateTime > currentDate;
    const isEndDateValid = endDateTime > currentDate;
    console.log(isStartDateValid); // true start date future
    console.log(isEndDateValid); // true end date future
    if (!isStartDateValid && isEndDateValid) {
        return true
    } else {
        return false
    }
};

// combination date and time return
export const combineDateTime = (dateString, timeString) => {
    const date = new Date(dateString);

    // Extract hours, minutes, and AM/PM from the time string
    const [time, ampm] = timeString.split(' ');
    const [hours, minutes] = time.split(':');

    // Convert hours to 24-hour format if needed
    const adjustedHours = ampm === 'PM' ? parseInt(hours, 10) + 12 : parseInt(hours, 10);

    // Set the time in the date object
    date.setHours(adjustedHours);
    date.setMinutes(parseInt(minutes, 10));

    // Format the combined date and time
    const combinedDateTime = date.toISOString();

    return combinedDateTime;
};


// return 15 before time
export function get15MinutesBefore(dateString) {
    // Convert date string to Date object
    const givenDate = new Date(dateString);

    // Subtract 15 minutes (900,000 milliseconds) from the given date
    const fifteenMinutesBefore = new Date(givenDate.getTime() - 15 * 60 * 1000);

    // Format the result as a string
    const formattedResult = fifteenMinutesBefore.toISOString().slice(0, 19).replace("T", " ");

    return formattedResult;
}


export function get1HourFuture(dateString) {
   // Convert date string to Date object
   const givenDate = new Date(dateString);

   // Add 1 hour (3600 seconds) to the given date
   const oneHourFuture = new Date(givenDate.getTime() + 60 * 60 * 1000);

   // Use toISOString() to get the result in the same format
   return oneHourFuture.toISOString();
}