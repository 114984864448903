import React, { useContext } from "react";
import HomeIcon from "../../assets/images/icons/Icon feather-home.png";
import ClientIcon from "../../assets/images/icons/Path 10368.png";
import SessionsIcon from "../../assets/images/icons/mentalhealthart-01.png";
import Groupsicon from "../../assets/images/icons/mentalhealthart-31.png";
import AiIcon from "../../assets/images/icons/mentalhealthart-19.png";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { SideBarContext } from "../../context/SideBarContext";
import logoutArrow from "../../assets/images/icons/back-arrow.png";

const SideBarMenu = () => {
  const navigate = useNavigate();
  const { activeTab, setActiveTab } = useContext(SideBarContext);
  // const [activeTab, setActiveTab] = useState(1);

  const SwichTab = (e, tabindex) => {
    e.preventDefault();
    if (tabindex === 1) {
      setActiveTab(1);
      navigate(`../${AdminRoute.Auth.Dashboard}`);
    } else if (tabindex === 2) {
      setActiveTab(2);
      navigate(`../${AdminRoute.Auth.ClientList}`);
    } else if (tabindex === 3) {
      setActiveTab(3);
      navigate(`../${AdminRoute.Auth.Sessions}`);
    } else if (tabindex === 4) {
      setActiveTab(4);
      navigate(`../${AdminRoute.Auth.Groups}`);
    } else if (tabindex === 5) {
      setActiveTab(5);
      navigate(`../${AdminRoute.Auth.Playground}`);
    } else if (tabindex === 6) {
      setActiveTab(6);
      navigate(`../${AdminRoute.Auth.AiAsistance}`);
      // navigate(`../${AdminRoute.Auth.AIAssistanceChat}`);
    } else if (tabindex === 6) {
      setActiveTab(6);
      localStorage.clear();
      navigate(`../${AdminRoute?.Auth?.Login}`);
      // navigate(`../${AdminRoute.Auth.AiAsistance}`);
      // navigate(`../${AdminRoute.Auth.AIAssistanceChat}`);
    }
  };

  const LogoutFun = () => {
    localStorage.removeItem("TherapistUserData");
    localStorage.removeItem("AuthTokenTherapist");
    localStorage.removeItem("Mobileno");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("RegUserData");
    localStorage.removeItem("ProfileImage");
    navigate(`../${AdminRoute?.Auth?.Login}`);
  };

  return (
    <div className="side-menu-container">
      <div
        className="side-manu-item cursor-pointer"
        onClick={(e) => SwichTab(e, 1)}
        style={{
          background:
            activeTab === 1 ? "rgb(242,186,63)" : "rgba(230,226,222,255)",
          color: activeTab === 1 ? "white" : "black",
        }}
      >
        <img src={HomeIcon} />
        Home
      </div>

      <div
        className="side-manu-item cursor-pointer"
        onClick={(e) => SwichTab(e, 2)}
        style={{
          background:
            activeTab === 2 ? "rgb(242,186,63)" : "rgba(230,226,222,255)",
          color: activeTab === 2 ? "white" : "black",
        }}
      >
        <img src={ClientIcon} />
        Client
      </div>

      <div
        className="side-manu-item cursor-pointer"
        onClick={(e) => SwichTab(e, 3)}
        style={{
          background:
            activeTab === 3 ? "rgb(242,186,63)" : "rgba(230,226,222,255)",
          color: activeTab === 3 ? "white" : "black",
        }}
      >
        <img src={SessionsIcon} />
        Sessions
      </div>
      <div
        className="side-manu-item cursor-pointer"
        onClick={(e) => SwichTab(e, 4)}
        style={{
          background:
            activeTab === 4 ? "rgb(242,186,63)" : "rgba(230,226,222,255)",
          color: activeTab === 4 ? "white" : "black",
        }}
      >
        <img src={Groupsicon} />
        Groups
      </div>
      <div
        className="side-manu-item cursor-pointer"
        onClick={(e) => SwichTab(e, 5)}
        style={{
          background:
            activeTab === 5 ? "rgb(242,186,63)" : "rgba(230,226,222,255)",
          color: activeTab === 5 ? "white" : "black",
        }}
      >
        <img src={AiIcon} />
        Playground
      </div>
      <div
        className="side-manu-item cursor-pointer"
        onClick={(e) => SwichTab(e, 6)}
        style={{
          background:
            activeTab === 6 ? "rgb(242,186,63)" : "rgba(230,226,222,255)",
          color: activeTab === 6 ? "white" : "black",
        }}
      >
        <img src={AiIcon} />
        Ai Assistance
      </div>
      <div
        className="side-manu-item cursor-pointer"
        onClick={() => LogoutFun()}
      >
        <img src={logoutArrow} />
        Logout
      </div>
    </div>
  );
};

export default SideBarMenu;
