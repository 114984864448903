import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";
import GoogleLogin from "./../Firebase/firebase";
// import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const initialValues = { InputType: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [ApiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const mobileRegex = /^\d+$/;

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};

    if (!values?.InputType) {
      errors.InputType = "Email-Id or Mobile no is Required!";
    }
    if (mobileRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Mobile Number!";
    }
    if (emailRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Email-ID!";
    }
    if (
      mobileRegex.test(values?.InputType) &&
      emailRegex.test(values?.InputType)
    ) {
      errors.InputType = "Invalid Input!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (emailRegex.test(formValues?.InputType)) {
        setLoading(true);
        try {
          API.Auth.LoginAPIEmail({
            data: {
              email: formValues?.InputType,
              role: "Therapist",
              formname: "Login",
            },
          }).then((response) => {
            localStorage.setItem("EmailId", formValues?.InputType);
            if (
              response?.data?.status === 1 &&
              response?.data?.message === "The OTP has been sent successfully."
            ) {
              setApiError(response?.data?.message);
              navigate(
                `../${AdminRoute?.Auth?.verifyOtpMail}/1?email=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "User not registered."
            ) {
              navigate(
                `../${AdminRoute?.Auth?.Registration1}?email=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
            } else if (
              response?.data?.status === 2 &&
              response?.data?.message === "User is not authoriser."
            ) {
              setApiError(response?.data?.text);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "Please complete the registration."
            ) {
              // ================================================================
              // handleResendOtpEmail(response?.data?.data[0]?.email);    // Testing Remaing because of otp not sent
              // ====================================================================
              localStorage.setItem("Mobileno", response?.data?.data?.phone);

              if (response?.data?.data?.is_verifiedp === true) {
                handleResendOtpEmail(response?.data?.data?.email);
                navigate(
                  `../${
                    AdminRoute?.Auth?.verifyOtpMail
                  }/2?email=${JSON.stringify(formValues?.InputType)}`
                );
              } else {
                handleResendOtp(response?.data?.data?.phone);
                navigate(
                  `../${
                    AdminRoute?.Auth?.VerifyOtp
                  }/2?mobile_no=${JSON.stringify(response?.data?.data?.phone)}`
                );
              }
              setApiError(response?.data?.message);
            }
            // setApiError(response?.data?.message);
            setLoading(false);
          });
        } catch (error) {
          setApiError(error.message);
          console.log(error);
          setLoading(false);
        }
      }

      if (mobileRegex.test(formValues?.InputType)) {
        setLoading(true);
        try {
          API.Auth.LoginAPIMobile({
            data: {
              phone: formValues?.InputType,
              role: "Therapist",
              formname: "Login",
            },
          }).then((response) => {
            localStorage.setItem("Mobileno", formValues?.InputType);
            if (
              response?.data?.status === 1 &&
              response?.data?.message === "The OTP has been sent successfully."
            ) {
              navigate(
                `../${AdminRoute?.Auth?.VerifyOtp}/1?mobile_no=${JSON.stringify(
                  formValues?.InputType
                )}`
              );
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "User not registered."
            ) {
              navigate(
                `../${
                  AdminRoute?.Auth?.Registration1
                }?mobile_no=${JSON.stringify(formValues?.InputType)}`
              );
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 2 &&
              response?.data?.message === "User is not authoriser."
            ) {
              setApiError(response?.data?.text);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message ===
                "Please wait for some time, unable to send message."
            ) {
              setApiError(response?.data?.message);
            } else if (
              response?.data?.status === 0 &&
              response?.data?.message === "Please complete registration..."
            ) {
              // ====================================================================
              // handleResendOtp(response?.data?.data?.phone);   // Testing Remaing because of otp not sent
              // ==========================================================================
              localStorage.setItem("EmailId", response?.data?.data[0]?.email);
              if (response?.data?.data[0]?.is_verifiedp === true) {
                handleResendOtpEmail(response?.data?.data[0]?.email);
                navigate(
                  `../${
                    AdminRoute?.Auth?.verifyOtpMail
                  }/2?email=${JSON.stringify(response?.data?.data[0]?.email)}`
                );
              } else {
                handleResendOtp(response?.data?.data[0]?.phone);
                navigate(
                  `../${
                    AdminRoute?.Auth?.VerifyOtp
                  }/2?mobile_no=${JSON.stringify(formValues?.InputType)}`
                );
              }

              setApiError(response?.data?.message);
            }
            // setApiError(response?.data?.message);
            setLoading(false);
          });
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    }
  }, [formErrors]);

  const handleResendOtp = (mobile) => {
    try {
      API.Auth.LoginAPIMobile({
        data: {
          phone: mobile,
          role: "Therapist",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          setApiError(response?.data?.message);
        } else {
          setApiError(response?.data?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOtpEmail = (email) => {
    try {
      API.Auth.LoginAPIEmail({
        data: {
          email: email,
          role: "Therapist",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          setApiError(response?.data?.message);
        } else {
          setApiError(response?.data?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitMobile = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const goToRegister = (e) => {
    const emailRegexCheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegexCheck = /^\d{10}$/;

    if (!formValues?.InputType) {
      navigate(`../${AdminRoute?.Auth?.Registration1}`);
    }
    if (emailRegexCheck.test(formValues?.InputType)) {
      localStorage.setItem("EmailId", formValues?.InputType);
      navigate(
        `../${AdminRoute?.Auth?.Registration1}?email=${JSON.stringify(
          formValues?.InputType
        )}`
      );
    }
    if (mobileRegexCheck.test(formValues?.InputType)) {
      localStorage.setItem("Mobileno", formValues?.InputType);
      navigate(
        `../${AdminRoute?.Auth?.VerifyOtp}/1?mobile_no=${JSON.stringify(
          formValues?.InputType
        )}`
      );
    }
  };

  return (
    <div className="land-container">
      <div className="logo-container">
        <img className="logo" src={rtylifeLogo} />
      </div>
      <div className="login-container">
        <div className="register-therapist-div register-therapist">
          <form>
            <h2 className="heading fw-600 py-2">Login</h2>

            <div>
              <label className="pb-2">
                * Enter registered E-Mail ID/ Mobile No.
              </label>
              <input
                type="text"
                name="InputType"
                placeholder="Enter your E-Mail ID/Mobile No."
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <p className="text-danger mb-0 mt-2">{formErrors?.InputType}</p>
              <p className="text-danger mb-0 mt-2">{ApiError}</p>
            </div>

            <button
              type="submit"
              className="button_common w-100 my-3 text-center"
              disabled={
                formValues.InputType === "" || isSubmit === true ? true : false
              }
              onClick={(e) => handleSubmitMobile(e)}
            >
              Verify via OTP
            </button>
            {loading ? <div className="loader_orange"></div> : <></>}
            <div className="form-group">
              {/* <div className="custom-control custom-checkbox d-flex align-item-center ">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label
                  className="custom-control-label ps-2"
                  htmlFor="customCheck1"
                >
                  Stay logged in
                </label>
              </div> */}
            </div>
            {/* <h6 className="or-text text-center">OR</h6>
            <div className="cursor-pointer">
              {" "}
              <GoogleLogin />
            </div>
            <p className="account-text text-center pb-2 mb-0">
              Don&apos;t have an account?
            </p> */}
            <p
              className="text-center cursor-pointer"
              onClick={(e) => goToRegister(e)}
            >
              <span className="register-text tgoToRegisterext-dark fw-500">
                Let's Register{" "}
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
