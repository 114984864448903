
const config = {
  uid: 0,
  appId: "0f99f7c838314484830e1ce6f0c5de79",
  channelName: "test",
  rtcToken: "007eJxTYEjL6or9fLz1ZnRu7BGrd8rbotIinPc+Ll4ko/S7bOfp2UsUGAzSLC3TzJMtjC2MDU1MLEwsjA1SDZNTzdIMkk1TUs0ts/esTm0IZGQwPnmbkZGBkYEFiEF8JjDJDCZZoGRJanEJAwMAMR0j8g==",
  serverUrl: "",
  proxyUrl: "http://localhost:8080/",
  tokenExpiryTime: 600,
  token: "",
  encryptionMode: "aes-128-gcm2",
  salt: "",
  encryptionKey: "",
  destChannelName: "",
  destChannelToken: "",
  destUID: 2,
  secondChannel: "",
  secondChannelToken: "",
  secondChannelUID: 2,
  selectedProduct: "rtc"
};

export default config;