import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import Modal from "react-modal";
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import AdminRoute from "../../Route/RouteDetails";
import plusIcon from "../../assets/images/icons/Icon feather-plus.png";
import API from "../../Api/Api";
import moment from "moment";
import SideBarMenu from "../Common/SideBarMenu";

const MyReferrel = () => {
  const navigate = useNavigate();
  const [MyRefferalList, setMyRefferalList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    try {
      API.Refer.MyreferralList({}).then((response) => {
        // console.log("My Refferral list", response?.data?.data_all);
        setMyRefferalList(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header isBack={true} header={"My Referrels"}></Header>
        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 20%)" }}
        >
          <div className="sub-head px-3" style={{ position: "relative" }}>
            <p className="sub-heading">
              Clients: {MyRefferalList?.length ? MyRefferalList?.length : null}
            </p>
            <p className="sub-lbl">Here is an overview of all your clients</p>
            <span>
              <img
                className="add-ref"
                src={plusIcon}
                onClick={() => navigate(`${AdminRoute?.Auth?.SelectReferral1}`)}
              />
            </span>
          </div>
          <div
            className="client-name-list pt-0 px-3"
            style={{ maxHeight: "calc(100% - 10%)" }}
          >
            {loading ? (
              <div className="loader_orange"></div>
            ) : !MyRefferalList?.length ? (
              <div className="alert alert-warning" role="alert">
                Client Not Available
              </div>
            ) : (
              MyRefferalList?.length &&
              MyRefferalList?.map((val, i) => {
                return (
                  <div className="client-name-card" key={i}>
                    <div>
                      <div className="d-flex justify-content-between">
                        <label className="client-lbl1-ref fw-bold">
                          {`${val?.user[0]?.name} ${val?.user[0]?.last_name}`}
                        </label>
                        {/* <label>10/12/2023 10:00 PM</label> */}
                        <label>
                          {" "}
                          {moment(val?.user[0]?.updated_at).format("l")}
                        </label>
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <label className="blue-text">
                          Referring to: {`${val?.user[0]?.email}`}
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="client-lbl1 fw-bold">
                          {/* advik.jain@gmail.com */}
                          {`${val?.user[0]?.email}`}
                        </label>
                        <label className="blue-text">
                          <i className="fa fa-phone"></i> {val?.user[0]?.phone}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyReferrel;
