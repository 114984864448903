import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../Route/RouteDetails";
import config from "../agora-manager/config";
import API from "../Api/Api";
import moment from "moment";
// import { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";

const JoinSession = ({ currentSession }) => {
  const navigate = useNavigate();
  // const agoraEngine = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: config.selectedProduct }));
  const [joined, setJoined] = useState(false);
  const [UpcommingList, setUpcommingList] = useState([]);
  // const [ClientName, setClientName] = useState("");
  const [is_loading, setis_Loading] = useState(false);

  const upcomingListfun = () => {
    setis_Loading(true);
    try {
      API.Client.UpcomingListdashboard({}).then((response) => {
        console.log("Sessions====>", response?.data?.data_all);
        if (response?.data?.status === 1) {
          setUpcommingList(response?.data?.data_all[0]);

          // setClientName(
          //   `${response?.data?.data_all[0]?.client[0]?.name} ${response?.data?.data_all[0]?.client[0]?.last_name}`
          // );
        }
        setis_Loading(false);
      });
    } catch (error) {
      setis_Loading(false);
      // console.log(error);
    }
  };

  const handleJoinClick = () => {
    setJoined(true);
  };

  const handleLeaveClick = () => {
    setJoined(false);
  };

  const renderActionButton = (SessionId) => {
    // return joined ? (
    //     <button onClick={handleLeaveClick}>Leave</button>
    // ) : (
    //     <button onClick={handleJoinClick}>Join</button>
    // );

    try {
      API.JoinSession.Joinmeeting({
        data: {
          role: "Therapist",
          meetingId: UpcommingList?.mettingname,
        },
      }).then((response) => {
        console.log(response?.data);
        if (response?.data?.status === 1) {
          navigate(
            `../${AdminRoute.Auth.VideoCall}/${SessionId}?id=${btoa(
              UpcommingList?.meetingtoken
            )}&name=${UpcommingList?.mettingname}`
          );
        }
      });
    } catch (error) {
      console.log(error);
    }

    // ---------------------------------------------------------
  };
  config.selectedProduct = "rtc";

  useEffect(() => {
    upcomingListfun();
  }, []);

  return (
    <>
      <div className="content w-100">
        <div className="dashboard-container pt-1">
          <div className="px-3">
            <div className="d-flex justify-content-between mb-2">
              <label className="sub-lbl">
                <strong>{moment(UpcommingList?.date).format("ll")}</strong>
              </label>
              <label
                className="sub-lbl cursor-pointer"
                onClick={() => navigate(`../${AdminRoute.Auth.Sessions}`)}
              >
                View All
              </label>
            </div>

            {is_loading ? (
              <div className="loader_orange"></div>
            ) : UpcommingList?.client ? (
              <>
                {" "}
                <div className="d-flex justify-content-between align-items-center session-cont">
                  <div className="time-div">
                    <strong>{UpcommingList?.timestart}</strong>
                  </div>
                  <label className="session-user-lbl text-white">
                    {/* {ClientName}  */}
                    {`${
                      UpcommingList?.client
                        ? UpcommingList?.client[0]?.name
                        : ""
                    } ${
                      UpcommingList?.client
                        ? UpcommingList?.client[0]?.last_name
                        : ""
                    }`}
                  </label>
                  <button
                    className="common-btn"
                    //   onClick={() =>
                    //     navigate(
                    //       `../${AdminRoute?.Auth?.JoinSession}/${UpcommingList?._id}`
                    //     )
                    //   }
                    onClick={() => renderActionButton(UpcommingList?._id)}
                  >
                    Join
                  </button>
                </div>
                <br />
              </>
            ) : (
              <h4>No sessions scheduled.</h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinSession;
