import React, { useState, useEffect } from "react";
import userImage from "../../assets/images/icons/Path 15093.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import PersonalityTests from "../../assets/images/Images/personality-tests.jpg";
import WellBeingTest from "../../assets/images/Images/well-being-test.jpg";
import LineChart from "../../assets/images/Images/line-chart.jpg";
import API from "../../Api/Api";
import { useParams } from "react-router-dom";
import moment from "moment";
import RadarChart from "../Common/RadarChart";

const ClientViewDetDemo = () => {
  const params = useParams();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [ClientDetails, setClientDetails] = useState([]);

  //   ===== Graph data ======
  const [loading, setLoading] = useState(false);
  const [GraphData, setGraphData] = useState({});
  const [GraphOneData, setGraphOneData] = useState({});
  const [GraphTwoData, setGraphTwoData] = useState({});
  const [GraphThreeData, setGraphThreeData] = useState({});

  const config = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      labels: ["Engagement", "Leadership", "Burnout"],
    },
    series: [
      {
        name: "OHEP Index",
        data: [4.9, 3.9, 3.6],
      },
    ],
  };

  let config1 = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      labels: ["Engagement", "Leadership", "Burnout"],
    },
    series: [
      {
        name: "OHEP Index",
        data: [4.9, 3.9, 3.6],
      },
    ],
  };

  let config2 = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      labels: ["Engagement", "Leadership", "Burnout"],
    },
    series: [
      {
        name: "OHEP Index",
        data: [4.9, 3.9, 3.6],
      },
    ],
  };

  //   ======================
  const upcomingListfun = () => {
    setLoading(true);
    try {
      API.Client.UpcomingClientList({
        UserId: UserData?.userdata?._id,
        isApproved: params?.isActiveId,
        ClientId: params?.ClientId,
        isDelete: params?.isDelete,
      }).then((response) => {
        // console.log("Client Deatils", response?.data?.data_all[0]?.client[0]);
        setClientDetails(response?.data?.data_all[0]?.client[0]);
        // setupcommingClientCount(response?.data?.data_all?.length);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    upcomingListfun();
  }, []);

  // ----------------------Graph Data---------------------------------------

  useEffect(() => {
    setLoading(true);
    try {
      API.Survey.AnswerSurvey({
        ClientId: params?.ClientId,
      }).then((response) => {
        // console.log("Servet data", response?.data);
        if (response.data.status === 1) {
          if (response?.data.graph1.length) {
            config.options.labels = response.data.graph1[0].keys;
            config.series[0].data = response.data.graph1[0].values;
            setGraphOneData(config);
          }
          if (response?.data.graph2.length) {
            config1.options.labels = Object.keys(response?.data.graph2[0]);
            config1.series[0].data = Object.values(response?.data.graph2[0]);
            setGraphTwoData(config1);
          }
          if (response.data.graph3.length) {
            // config2.options.labels = response.data.graph3[0].keys;
            // config2.series[0].data = response.data.graph3[0].values;
            setGraphThreeData(config2);
          }
          setGraphData(response?.data);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
        <div className="dashboard-container" style={{ maxHeight: "100%" }}>
          <div className="sub-head px-3 d-flex">
            <img
              style={{ height: "40px", marginRight: "10px" }}
              src={userImage}
            />
            <div>
              <p className="sub-heading">
                <strong>{`${ClientDetails?.name} ${ClientDetails?.last_name}`}</strong>
              </p>
              <p className="sub-lbl">Here are all the details of your client</p>
            </div>
          </div>
          {loading ? (
            <div className="loader_orange"></div>
          ) : (
            <div className="mb-2 client-view-cont pt-2">
              <p><span className="fw-bold me-2">First Name:</span> {ClientDetails?.name}</p>
              <p><span className="fw-bold me-2">Last Name:</span> {ClientDetails?.last_name}</p>
              <p>
                <span className="fw-bold me-2">Date of birth:</span> {moment(ClientDetails?.dob).format("MMM Do YY")}
              </p>
              <p><span className="fw-bold me-2">email:</span> {ClientDetails?.email}</p>

              <p><span className="fw-bold me-2">Mobile No.: </span> +91 {ClientDetails?.phone}</p>

              {/* ============== New Chart Data =============== */}

              {/* Chart One  */}

              <>
                <div className="row mt-4">
                  <div className="col-12 col-md-4 h-100">
                    <div
                      className="client-name-card h-100 client-graph"
                      style={{ background: "rgb(255 255 255)" }}
                    >
                      <div>
                        {GraphData?.graph1?.length &&
                          Object.keys(GraphData?.graph1[0]).length !== 0 ? (
                          <RadarChart
                            options={GraphOneData?.options}
                            series={GraphOneData?.series}
                            type="radar"
                          />
                        ) : (
                          <>
                            {/* <h3>Data not Available</h3> */}
                            <div className="playground-img-div bg-grey">
                              <span className="img">
                                <img
                                  src={PersonalityTests}
                                  alt="PersonalityTests"
                                />
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      <p
                        className="px-2 my-1 mt-5"
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                      >
                        Personality Test
                      </p>
                      {/* <p className="px-2 m-0">Published Date: 01 Sept, 2023</p> */}
                    </div>
                  </div>

                  {/* ******** Chart Two *********** */}
                  <div className="col-12 col-md-4 h-100">
                    <div
                      className="client-name-card h-100 client-graph"
                      style={{ background: "rgb(255 255 255)" }}
                    >
                      <div>
                        {/* <Radar data={traingelData} options={config} /> */}
                        {GraphData?.graph2?.length &&
                          Object.keys(GraphTwoData).length !== 0 ? (
                          <RadarChart
                            options={GraphTwoData.options}
                            series={GraphTwoData.series}
                            type="radar"
                          />
                        ) : (
                          <div className="playground-img-div bg-grey">
                            <span className="img">
                              <img src={WellBeingTest} alt="WellBeingTest" />
                            </span>
                          </div>
                        )}
                      </div>
                      <p
                        className="px-2 my-1 mt-5"
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                      >
                        Well being Test
                      </p>
                      {/* <p className="px-2 m-0">Published Date: 01 Sept, 2023</p> */}
                    </div>
                  </div>

                  {/* ******** Chart Three *********** */}
                  <div className="col-12 col-md-4 h-100">
                    <div
                      className="client-name-card h-100 client-graph"
                      style={{ background: "rgb(255 255 255)" }}
                    >
                      <div className="card-body">
                        {GraphData?.graph3?.length &&
                          Object.keys(GraphThreeData).length !== 0 ? (
                          <RadarChart
                            options={GraphThreeData.options}
                            series={GraphThreeData.series}
                            type="line"
                          />
                        ) : (
                          <div className="playground-img-div bg-grey mb-3">
                            <span className="img">
                              <img src={LineChart} alt="LineChart" />
                            </span>
                          </div>
                        )}
                        <div className="support-div-text">
                          {/* <h3 className="card-heading text-left">Line Chart</h3> */}
                          <p className="card-text mb-2 text-muted published-date">
                            {" "}
                            Published Date: 01 Sept, 2023
                          </p>
                        </div>
                      </div>
                      <p
                        className="px-2 my-1"
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                      >
                        Line Chart
                      </p>
                      {/* <p className="px-2 m-0">Published Date: 01 Sept, 2023</p> */}
                    </div>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientViewDetDemo;
