import React, { useState, useEffect } from "react";
import userImage from "../../assets/images/icons/mentalhealthart-01.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import MobMenuBar from "../Common/MobMenuBar";
import API from "../../Api/Api";
import { useNavigate, useParams } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import moment from "moment/moment";

const AddEditSessionDetails = () => {
  const navigate = useNavigate();
  const param = useParams();

  const [SessionDetails, setSessionDetails] = useState([]);

  const GetSessionDetails = () => {
    try {
      API.Client.SessionDetails({
        SessionId: param?.SessionId,
      }).then((response) => {
        console.log("SessionDetails", response?.data);
        if (response?.data?.status === 1) {
          setSessionDetails(response?.data?.data_all[0]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetSessionDetails();
  }, []);

  const initialValues = {
    SessionSummary: "",
    Observations: "",
    Interventions: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitPublishsummary, setIsSubmitPublishsummary] = useState(false);
  const [IsSubmitInProgressSummary, setIsSubmitInProgressSummary] =
    useState(false);
  const [SummaryStatus, setSummaryStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const RegisterValidate = (values) => {
    const errors = {};

    if (!values?.SessionSummary) {
      errors.SessionSummary = "Session Summary is required !";
    }
    if (!values?.Observations) {
      errors.Observations = "Observations is required !";
    }
    if (!values?.Interventions) {
      errors.Interventions = "Interventions is required !";
    }
    return errors;
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmitPublishsummary) {
      try {
        API.Client.UpdateSession({
          data: {
            summary: formData?.SessionSummary,
            observation: formData?.Observations,
            intervention: formData?.Interventions,
            SummaryStatus: "Publish",
          },
          clientId: param?.SessionId,
        }).then((response) => {
          // console.log("Session", response?.data);
          if (response?.data?.status === 1) {
            navigate(
              `../${AdminRoute.Auth.SessionDetails}/${param?.SessionId}`
            );
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  const handleSubmitPublish = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setSummaryStatus("Publish");
    setIsSubmitPublishsummary(true);
  };

  useEffect(() => {
    if (IsSubmitInProgressSummary) {
      try {
        API.Client.UpdateSession({
          data: {
            summary: formData?.SessionSummary,
            observation: formData?.Observations,
            intervention: formData?.Interventions,
            SummaryStatus: "Inprogress",
          },
          clientId: param?.SessionId,
        }).then((response) => {
          // console.log("Session", response?.data);
          if (response?.data?.status === 1) {
            navigate(
              `../${AdminRoute.Auth.SessionDetails}/${param?.SessionId}`
            );
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [IsSubmitInProgressSummary]);

  const handleSubmitinProgress = (e) => {
    e.preventDefault();
    setSummaryStatus("Inprogress");
    setIsSubmitInProgressSummary(true);
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header header={"Edit Session Detials"}></Header>

        <MobMenuBar></MobMenuBar>
        <div className="dashboard-container">
          <div className="sub-head px-3 d-flex">
            <img
              style={{ height: "40px", marginRight: "10px" }}
              src={userImage}
            />
            <div>
              <p className="sub-heading">
                {/* <strong>31 July 2023 11:00 AM</strong> */}
                <strong>
                  {moment(SessionDetails?.date).format("LL")} |{" "}
                  {SessionDetails?.timestart}
                </strong>
              </p>
              <p className="sub-lbl">Here are all your session details.</p>
            </div>
          </div>
          <div className="mb-2 client-view-cont">
            <p className="my-2" style={{ color: "#32665F" }}>
              <strong>Session Summary:</strong>
            </p>
            <textarea
              name="SessionSummary"
              className="session-inp-text"
              rows={5}
              onChange={(e) => handleChange(e)}
            ></textarea>
            <p className="text-danger">{formErrors?.SessionSummary}</p>
            <p className="my-2" style={{ color: "#32665F" }}>
              <strong>Observations:</strong>
            </p>
            <textarea
              name="Observations"
              className="session-inp-text"
              rows={5}
              onChange={(e) => handleChange(e)}
            ></textarea>
            <p className="text-danger">{formErrors?.Observations}</p>

            <p className="my-2" style={{ color: "#32665F" }}>
              <strong>Interventions:</strong>
            </p>
            <textarea
              name="Interventions"
              className="session-inp-text"
              rows={5}
              onChange={(e) => handleChange(e)}
            ></textarea>
            <p className="text-danger">{formErrors?.Interventions}</p>
          </div>
          <div className="d-flex justify-content-around">
            <button
              className="session-btns sv-inp-btn"
              onClick={(e) => handleSubmitinProgress(e)}
            >
              Save as Draft
            </button>
            <button
              className="session-btns sv-btn"
              onClick={(e) => handleSubmitPublish(e)}
            >
              Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditSessionDetails;
