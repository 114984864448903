import React, { useState, useEffect } from "react";
import MobMenuBar from "../Common/MobMenuBar";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import userImage from "../../assets/images/icons/Path 15093.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import { useNavigate, useParams } from "react-router-dom";
import plusIcon from "../../assets/images/icons/+.png";
import sendIcon from "../../assets/images/icons/Icon feather-send.png";
import moment from "moment";
import pdfIcon from "../../assets/images/icons/pdf.png";
import baseApi from "../../Api/config";
import Dropdown from "react-bootstrap/Dropdown";
import { socket } from "../../socket";
import { formatDateTime } from "../Common/Functions";
// import { NavLink } from "react-router-dom";

// import Modal from "react-modal";
// import plusIconClose from "../../assets/images/icons/Icon feather-plus.png";
import userChatIcon from "../../assets/images/icons/Icon awesome-user-circle-dark.png";
// const socket = io("https://ai.rytlife.com", { path: "/api/socket.io" });

// const socket = io(process.env.REACT_APP_BASE_URL_SOCKET, {
//   path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
// });

// console.log("process.env.WDS_SOCKET_HOST", process.env.WDS_SOCKET_HOST);

// const TimeSlotS = [
//   "09:00 AM",
//   "10:00 AM",
//   "11:00 AM",
//   "12:00 PM",
//   "01:00 PM",
//   "02:00 PM",
//   "03:00 PM",
//   "04:00 PM",
//   "05:00 PM",
//   "06:00 PM",
//   "07:00 PM",
//   "08:00 PM",
// ];

// const TimeSlotE = [
//   "09:00 AM",
//   "10:00 AM",
//   "11:00 AM",
//   "12:00 PM",
//   "01:00 PM",
//   "02:00 PM",
//   "03:00 PM",
//   "04:00 PM",
//   "05:00 PM",
//   "06:00 PM",
//   "07:00 PM",
//   "08:00 PM",
// ];

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     // height: "500px",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     background: "#FFF",
//     borderRadius: "20px",
//     paddingTop: "70px",
//     width: "330px",
//     boxShadow: "0px 0px 24px -10px #bdbdbd",
//   },
// };

const ClientDetails = () => {
  // const location = useLocation();
  // const socket =
  //   location.pathname.includes("client-details") &&
  //   io(process.env.REACT_APP_BASE_URL_SOCKET, {
  //     path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
  //   });
  const params = useParams();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const navigate = useNavigate();
  const [activeIndex, setIndex] = useState(0);
  const [DigitalNotes, setDigital_Notes] = useState([]);
  const [Session_History, setSession_History] = useState([]);
  const [ClientDetails, setClientDetails] = useState([]);
  const [UpcommingSession, setUpcommingSession] = useState([]);
  const [currMessage, setCurrMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [rows, setRows] = useState(1);
  const [room] = useState("messageroom");
  const [EventListNew, setEventListNew] = useState([]);
  const [mediaType, setmediaType] = useState([]);
  const [MediaList, setMediaList] = useState([]);
  const [loading, setLoading] = useState(true);

  // ************ Initial functions ************
  const [menuVisible, setMenuVisible] = useState(false);
  // const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [createPollmodalIsOpen, setPollModel] = useState(false);
  // const [createQuizmodalIsOpen, setQuizModel] = useState(false);

  // function openModal() {
  //   setMenuVisible(false);
  //   setIsOpen(true);
  // }
  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  // }
  // function closeModal() {
  //   setIsOpen(false);
  // }

  // function openPollModal() {
  //   setMenuVisible(false);
  //   setPollModel(true);
  // }
  // function afterPollOpenModal() {
  //   // references are now sync'd and can be accessed.
  // }
  // function closePollModal() {
  //   setPollModel(false);
  // }

  // function openQuizModal() {
  //   setMenuVisible(false);
  //   setQuizModel(true);
  // }
  // function afterQuizOpenModal() {
  //   // references are now sync'd and can be accessed.
  // }
  // function closeQuizModal() {
  //   setQuizModel(false);
  // }

  // console.log("UserData",UserData)

  const handleClickMenu = (stat) => {
    setMenuVisible(!stat);
  };

  // ***************** Media List *********************

  // ***************** Resources list temparary ******************

  const MediaListFun = () => {
    setLoading(true);
    try {
      API.Event.MediaListIndividual({
        // GroupId: GroupId,
        UserId: params?.ClientId,
      }).then((response) => {
        // console.log("media List  ===>", response?.data);
        setMediaList(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    MediaListFun();
  }, []);

  // **************** Upload multimedia and upload pdf start ******************

  const handleUpload = (e) => {
    // console.log(e.target.files[0].name);
    // console.log("type", type);
    // setmediaType(type);
    // setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => CreateMediafun(JSON.parse(result).data)
          // setSpinner(false)
        );
    } catch (err) {
      console.log(err.res);
      // setSpinner(false);
    }
  };
  const CreateMediafun = (media) => {
    // console.log("Create media function", media);
    let mediaType = media?.filename.split(".").pop();

    try {
      API.Event.CreateMediaIndividual({
        data: {
          mediafile: media?.filename,
          type: mediaType === "pdf" ? "pdf" : "mediafile",
          created_by: UserData?.userdata?._id,
          send_to: params?.ClientId,
          originalfilename: media?.originalname,
        },
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          handleClickMenu(menuVisible);
          MediaListFun();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ********************************************************

  // ********** Create Activities for individual client ************

  // const initialValuesEvent = {
  //   EAskQuestion: "",
  //   EDescription: "",
  //   ESTime: "",
  //   EETime: "",
  //   ESDate: "",
  //   EEDate: "",
  // };

  // const [formDataEvent, setFormDataEvent] = useState(initialValuesEvent);
  // const [formEventErrors, setFormEventErrors] = useState({});
  // const [isEventSubmit, setIsEventSubmit] = useState(false);

  // const handleChangeEvent = (e) => {
  //   const { name, value } = e.target;
  //   setFormDataEvent({ ...formDataEvent, [name]: value });
  // };

  // const EventValidate = (values) => {
  //   // console.log("===> ok", values);
  //   const errors = {};

  //   if (!values?.EAskQuestion) {
  //     errors.EAskQuestion = "Question is required";
  //   }
  //   if (!values?.EDescription) {
  //     errors.EDescription = "Description is required";
  //   }
  //   if (!values?.ESTime) {
  //     errors.ESTime = "Start Time is required";
  //   }
  //   if (!values?.EETime) {
  //     errors.EETime = "End Time is required";
  //   }
  //   if (!values?.ESDate) {
  //     errors.ESDate = "Start Date is required";
  //   }
  //   if (!values?.EEDate) {
  //     errors.EEDate = "End Date is required";
  //   }

  //   return errors;
  // };

  // useEffect(() => {
  //   if (Object.keys(formEventErrors).length === 0 && isEventSubmit) {
  //     try {
  //       API.Event.CreateEvent({
  //         data: {
  //           question: formDataEvent?.EAskQuestion,
  //           description: formDataEvent?.EDescription,
  //           startTime: formDataEvent?.ESTime,
  //           endTime: formDataEvent?.EETime,
  //           startDate: formDataEvent?.ESDate,
  //           endDate: formDataEvent?.EEDate,
  //           created_by: UserData?.userdata?._id,
  //           type: "Event",
  //           send_to: params?.ClientId,
  //           eventtype: "individual",
  //         },
  //       }).then((response) => {
  //         // console.log(response?.data);
  //         if (response?.data?.status === 1) {
  //           closeModal();
  //           EventListNewFun();
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     // console.log("formDataEvent", formDataEvent);
  //   }
  // }, [formEventErrors]);

  // const handleSubmitEvent = (e) => {
  //   e.preventDefault();
  //   setFormEventErrors(EventValidate(formDataEvent));
  //   setIsEventSubmit(true);
  // };

  // *************** Create Event End here ***************

  // *************** Create Poll ***************

  // const initialValuesPoll = {
  //   PollQuestion: "",
  //   PollOption1: "",
  //   PollOption2: "",
  //   PollOption3: "",
  // };

  // const [formDataPoll, setFormDataPoll] = useState(initialValuesPoll);
  // const [formPollErrors, setFormPollErrors] = useState({});
  // const [isPollSubmit, setIsPollSubmit] = useState(false);

  // const handleChangePoll = (e) => {
  //   const { name, value } = e.target;
  //   setFormDataPoll({ ...formDataPoll, [name]: value });
  // };

  // const PollValidate = (values) => {
  //   // console.log("===> Poll values", values);
  //   const pollerrors = {};

  //   if (!values?.PollQuestion) {
  //     pollerrors.PollQuestion = "PollQuestion is required";
  //   }
  //   if (!values?.PollOption1) {
  //     pollerrors.PollOption1 = "PollOption1 is required";
  //   }
  //   if (!values?.PollOption2) {
  //     pollerrors.PollOption2 = "PollOption1 is required";
  //   }
  //   if (!values?.PollOption3) {
  //     pollerrors.PollOption3 = "PollOption1 is required";
  //   }

  //   return pollerrors;
  // };

  // useEffect(() => {
  //   if (Object.keys(formPollErrors).length === 0 && isPollSubmit) {
  //     try {
  //       API.Event.CreateEvent({
  //         data: {
  //           question: formDataPoll?.PollQuestion,
  //           option1: formDataPoll?.PollOption1,
  //           option2: formDataPoll?.PollOption2,
  //           option3: formDataPoll?.PollOption3,
  //           created_by: UserData?.userdata?._id,
  //           type: "Poll",
  //           send_to: params?.ClientId,
  //           eventtype: "individual",
  //         },
  //       }).then((response) => {
  //         // console.log(response?.data);
  //         if (response?.data?.status === 1) {
  //           closePollModal();
  //           EventListNewFun();
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     // console.log("formDataPoll", formDataPoll);
  //   }
  // }, [formPollErrors]);

  // const handleSubmitPoll = (e) => {
  //   e.preventDefault();
  //   setFormPollErrors(PollValidate(formDataPoll));
  //   setIsPollSubmit(true);
  // };

  // ************* Create Poll Ends **********************

  //  *********** Create Quiz *****************

  // const initialValuesQuiz = {
  //   QuizQuestion: "",
  //   QuizOption1: "",
  //   QuizOption2: "",
  //   QuizOption3: "",
  //   QuizAnswer: "",
  // };

  // const [formDataQuiz, setFormDataQuiz] = useState(initialValuesQuiz);
  // const [formQuizErrors, setFormQuizErrors] = useState({});
  // const [isQuizSubmit, setIsQuizSubmit] = useState(false);

  // const handleChangeQuiz = (e) => {
  //   const { name, value } = e.target;
  //   setFormDataQuiz({ ...formDataQuiz, [name]: value });
  // };

  // const QuizValidate = (values) => {
  //   // console.log("===> Quiz values", values);
  //   const Quizerrors = {};

  //   if (!values?.QuizQuestion) {
  //     Quizerrors.QuizQuestion = "Quiz Question is required";
  //   }
  //   if (!values?.QuizOption1) {
  //     Quizerrors.QuizOption1 = "Quiz Option1 is required";
  //   }
  //   if (!values?.QuizOption2) {
  //     Quizerrors.QuizOption2 = "Quiz Option2 is required";
  //   }
  //   if (!values?.QuizOption3) {
  //     Quizerrors.QuizOption3 = "Quiz Option3 is required";
  //   }

  //   if (!values?.QuizAnswer) {
  //     Quizerrors.QuizAnswer = "Quiz Answer is required";
  //   }

  //   return Quizerrors;
  // };

  // useEffect(() => {
  //   if (Object.keys(formQuizErrors).length === 0 && isQuizSubmit) {
  //     try {
  //       API.Event.CreateEvent({
  //         data: {
  //           question: formDataQuiz?.QuizQuestion,
  //           option1: formDataQuiz?.QuizOption1,
  //           option2: formDataQuiz?.QuizOption2,
  //           option3: formDataQuiz?.QuizOption3,
  //           answer: formDataQuiz?.QuizAnswer,
  //           created_by: UserData?.userdata?._id,
  //           send_to: params?.ClientId,
  //           eventtype: "individual",
  //           type: "Quiz",
  //         },
  //       }).then((response) => {
  //         // console.log(response?.data);
  //         if (response?.data?.status === 1) {
  //           closeQuizModal();
  //           EventListNewFun();
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     // console.log("formDataQuiz", formDataQuiz);
  //   }
  // }, [formQuizErrors]);

  // const handleSubmitQuiz = (e) => {
  //   e.preventDefault();
  //   setFormQuizErrors(QuizValidate(formDataQuiz));
  //   setIsQuizSubmit(true);
  // };

  // *********************** Create Quiz Ends ******************

  // ******* To Handle Tabs **********

  const handleTabs = (e) => {
    setIndex(e);
    // console.log("===>", e);
    if (e === 0) {
      getSessionHistory_List();
    } else if (e === 1) {
      getDigitalNotes_List();
    } else if (e === 2) {
      // EventListNewFun();
    }
  };

  // ************ to get user data ***************

  const upcomingListfun = () => {
    setLoading(true);
    try {
      API.Client.UpcomingClientList({
        UserId: UserData?.userdata?._id,
        isApproved: params?.isActiveId,
        ClientId: params?.ClientId,
        isDelete: params?.isDelete,
      }).then((response) => {
        console.log("Client Deatils", response?.data);
        setClientDetails(response?.data?.data_all[0]?.client[0]);
        setLoading(false);
        // setupcommingClientCount(response?.data?.data_all?.length);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // ********* APi call for Session_History ************
  const getSessionHistory_List = () => {
    setLoading(true);
    try {
      API.Client.SessionHistory({
        ClientId: params?.ClientId,
        TherapistID: UserData?.userdata?._id,
      }).then((response) => {
        // console.log("Session_History", response?.data?.data_all);
        setSession_History(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // ********** APi call for Digital Notes **************

  const getDigitalNotes_List = () => {
    setLoading(true);
    try {
      API.Client.Digital_Notes({
        UserId: params?.ClientId,
      }).then((response) => {
        // console.log("ClientActiveList", response?.data?.data_all);
        setDigital_Notes(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //************** Activity List *******************

  // const EventListNewFun = () => {
  //   try {
  //     API.Event.SingleUserEventListNew({
  //       UserId: params?.ClientId,
  //     }).then((response) => {
  //       // console.log("Event List new  ===>", response?.data?.data_all);
  //       setEventListNew(response?.data?.data_all);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // ******* Chat Functionalty ********

  const getAllChats = (val) => {
    setLoading(true);
    API.MyThreapist.ChatList()
      .then((response) => {
        if (response.data.status === 1) {
          setMessageList(response?.data?.data_all);
          // console.log(response?.data?.data_all);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // console.log(currMessage);
      setRows(1);
      sendMessage(e);
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        currMessage.substring(0, cursorPosition) +
        "\n" +
        currMessage.substring(cursorPosition);
      setCurrMessage(newInputValue);
      e.preventDefault();
    }
  };

  const sendMessage = async () => {
    // console.log(
    //   "send Message",
    //   /*UserData?.data_theropist[0].theropistId*/ "therapist id"
    // );
    if (currMessage !== "") {
      const messageData = {
        author: `${UserData?.userdata?.fullname}`,
        created_id: UserData?.userdata?._id,
        receiver_id: params?.ClientId,
        message: currMessage,
        type: "individual",
        time: new Date(),
        // type: "all",
      };
      await socket.emit(room, messageData);
    }
    setCurrMessage("");
  };

  // useEffect(() => {
  //   socket.on(room, (data) => {
  //     console.log(data);
  //     setMessageList((list) => [...list, data]);
  //   });
  //   return () => {
  //     // socket.removeListener(room);
  //     socket.off(room); // Remove the listener when the component unmounts or the effect is re-run
  //   };
  // }, [socket]);

  useEffect(() => {
    if (!socket || !room) return;
    socket.connect();

    const handleData = (data) => {
      setMessageList((list) => [...list, data]);
    };

    socket.on(room, handleData);

    return () => {
      socket.off(room, handleData); // Remove the listener using the same function reference
      socket.disconnect();
    };
  }, [room, socket]);

  // ======= test msg =====

  const checkMSGCondition = () => {
    // console.log(
    //   "First Condition ==================>",
    //   messageList[0]?.created_user[0]?._id === UserData?.userdata?._id
    // );

    // console.log(
    //   "Second Condition ==================>",
    //   messageList[81]?.receiver_id === UserData?.userdata?._id
    // );

    // console.log(
    //   "Third Condition =======>",
    //   params?.ClientId === messageList[0]?.created_user[0]?._id
    // );

    messageList.forEach((element) => {
      // console.log(
      //   " ==================>",
      //   messageList[0]?.created_user[0]?._id === UserData?.userdata?._id &&
      //     messageList[81]?.receiver_id === UserData?.userdata?._id &&
      //     params?.ClientId === messageList[0]?.created_user[0]?._id
      // );
      // console.log(
      //   "===============>",
      //   params?.ClientId,
      //   element?.created_user[0]?._id
      // );
    });
  };

  useEffect(() => {
    // console.log("messageList", messageList);
    checkMSGCondition();
  }, [messageList]);

  // ****** use effect for apis ************
  useEffect(() => {
    getSessionHistory_List();
    getDigitalNotes_List();
    upcomingListfun();
    // EventListNewFun();
    getAllChats();
  }, []);

  //jsx
  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
        <MobMenuBar></MobMenuBar>
        <div className="dashboard-container">
          <div className="sub-head px-3 d-flex">
            <img
              style={{ height: "40px", marginRight: "10px" }}
              src={userImage}
            />
            <div>
              {loading ? (
                <div className="pt-3">
                  <div className="loader_orange"></div>
                </div>
              ) : (
                <p className="sub-heading">
                  <strong>{`${ClientDetails?.name} ${ClientDetails?.last_name}`}</strong>
                </p>
              )}
              <p className="sub-lbl">Here are all the details of your client</p>
            </div>
            <button
              className="common-sec-btn"
              onClick={() =>
                navigate(
                  `../${AdminRoute.Auth.ClientViewDetDemo}/${params?.ClientId}/isActive/${params?.isActiveId}/isDelete/${params?.isDelete}`
                )
              }
            >
              View Details
            </button>
          </div>
          <div className="common-tabs cursor-pointer">
            <div
              className="common-tab-item"
              onClick={() => handleTabs(0)}
              style={
                activeIndex == 0
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Session Histroy
            </div>

            <div
              className="common-tab-item"
              onClick={() => handleTabs(1)}
              style={
                activeIndex == 1
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Digital Note
            </div>

            <div
              className="common-tab-item"
              onClick={() => handleTabs(2)}
              style={
                activeIndex == 2
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Activity
            </div>

            <div
              className="common-tab-item"
              onClick={() => handleTabs(3)}
              style={
                activeIndex == 3
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Chats
            </div>
          </div>
        </div>
        {activeIndex === 0 ? (
          <>
            <div
              className="chats-main activity-tab"
              style={{
                height: "calc(100% - 245px)",
                width: "98%",
                marginLeft: "1%",
              }}
            >
              <div className="client-name-list cursor-pointer">
                {loading ? (
                  <div className="pt-5">
                    <div className="loader_orange"></div>
                  </div>
                ) : !Session_History?.length ? (
                  <div className="alert alert-warning" role="alert">
                    No Session Available
                  </div>
                ) : (
                  Session_History?.length &&
                  Session_History?.map((val, i) => {
                    return (
                      <div
                        key={i}
                        style={{ padding: "18px 15px" }}
                        className="client-name-card d-flex flex-row"
                        onClick={() =>
                          navigate(
                            `../${AdminRoute?.Auth?.SessionDetails}/${val?._id}`
                          )
                        }
                      >
                        <div className="w-half">
                          <p className="m-0 client-lbl1 m-0">Date</p>
                          <p className="client-lbl2 m-0">
                            {" "}
                            {moment(val?.date).format("ll")}
                          </p>
                        </div>
                        <div className="w-half">
                          <p className="m-0 client-lbl1 m-0">Time</p>
                          <p className="client-lbl2 m-0">
                            {moment(val?.updatedAt).format("LT")}
                          </p>
                        </div>
                        <img
                          style={{ top: "32px" }}
                          src={rightArrow}
                          className="right-arr cursor-pointer"
                          //  NavLink to={`/session-details`}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </>
        ) : activeIndex === 1 ? (
          <>
            <div
              className="chats-main activity-tab"
              style={{
                height: "calc(100% - 245px)",
                width: "98%",
                marginLeft: "1%",
              }}
            >
              <div className="client-name-list">
                {loading ? (
                  <div className="pt-5">
                    <div className="loader_orange"></div>
                  </div>
                ) : !DigitalNotes?.length ? (
                  <div className="alert alert-warning" role="alert">
                    Notes Not Available
                  </div>
                ) : (
                  DigitalNotes?.length &&
                  DigitalNotes?.map((val, i) => {
                    return (
                      <div
                        className="client-name-card"
                        key={i}
                        onClick={(e) =>
                          navigate(
                            `../${AdminRoute?.Auth?.DigitalNotes}/${val?._id}`
                          )
                        }
                      >
                        <p className="client-lbl2 ">{val?.title}</p>
                        <p className="client-lbl3 m-0">
                          {" "}
                          {moment(val?.createdAt).format("LL")}
                        </p>
                        <img
                          style={{ top: "38%" }}
                          src={rightArrow}
                          className="right-arr cursor-pointer"
                          onClick={(e) =>
                            navigate(
                              `../${AdminRoute?.Auth?.DigitalNotes}/${val?._id}`
                            )
                          }
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </>
        ) : activeIndex === 2 ? (
          <>
            <div
              className="chats-main activity-tab"
              style={{
                height: "calc(100% - 245px)",
                width: "98%",
                marginLeft: "1%",
              }}
            >
              {/* ******** Plus Icon Menu **************** */}
              {/* <div
                className="menu-container"
                style={{ display: menuVisible ? "block" : "none" }}
              >


                <div className="menu-items-wrap p-2"> */}


                  {/* <div
                    className="menu-item px-2 py-1 cursor-pointer"
                    onClick={() => openModal()}
                  >
                    <img src={plusIcon} style={{ marginRight: "10px" }} />
                    Create Events
                  </div>
                  <div
                    className="menu-item px-2 py-1 cursor-pointer"
                    onClick={() => openPollModal()}
                  >
                    <img src={plusIcon} style={{ marginRight: "10px" }} />
                    Create Poll
                  </div>
                  <div
                    className="menu-item px-2 py-1 cursor-pointer"
                    onClick={() => openQuizModal()}
                  >
                    <img src={plusIcon} style={{ marginRight: "10px" }} />
                    Create Quiz
                  </div> */}


                  {/* <div className="menu-item px-2 py-2 cursor-pointer">
                    <img htmlFor="uploadpdf" src={plusIcon} />
                    <input
                      type="file"
                      id="uploadpdf"
                      accept="application/pdf"
                      className="custom-file-input cursor-pointer"
                      title=" "
                      onChange={(e) => handleUpload(e)}
                    />
                    <label htmlFor="uploadpdf">Upload pdf</label>
                  </div>
                  <div className="menu-item px-2 py-2 cursor-pointer">
                    <img htmlFor="uploadmedia" src={plusIcon} />
                    <input
                      type="file"
                      id="uploadmedia"
                      className="custom-file-input cursor-pointer"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      title=" "
                      onChange={(e) => handleUpload(e)}
                    />
                    <label htmlFor="uploadmedia">Upload Multimedia</label>
                  </div>
                </div>
              </div> */}

              {/* ************* Add PDF and Image Dropdown ********************** */}

              <Dropdown className="float-end">
                <Dropdown.Menu>
                  <p className="mb-2">
                    <i class="fa fa-plus"></i>
                    <input
                      type="file"
                      id="uploadpdf"
                      accept="application/pdf"
                      className="custom-file-input cursor-pointer"
                      title=" "
                      onChange={(e) => handleUpload(e)}
                    />
                    <label htmlFor="uploadpdf">Upload pdf</label>
                  </p>

                  <p className="mb-0">
                    <i class="fa fa-plus"></i>
                    <input
                      type="file"
                      id="uploadmedia"
                      className="custom-file-input cursor-pointer"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      title=" "
                      onChange={(e) => handleUpload(e)}
                    />
                    <label htmlFor="uploadmedia">Upload Multimedia</label>
                  </p>
                </Dropdown.Menu>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <div className="icon-div me-0">
                    <i class="fa fa-plus"></i>
                  </div>
                </Dropdown.Toggle>
              </Dropdown>

              {/* ************* Event List ********************** */}

              <div
                className="scroller"
                style={{ maxHeight: "calc(100% - 73px)" }}
              >
                <div className="scroller-content p-2" id="scrollerContent">
                  {/* {!EventListNew?.length ? (
                    <div className="alert alert-warning" role="alert">
                      Events Not Available
                    </div>
                  ) : (
                    EventListNew?.length &&
                    EventListNew?.map((val, i) => {
                      return (
                        <>
                          {val?.data?.type === "Event" ? (
                            <>
                              <div key={i} className="item my-3 send-msg">
                                <div
                                  className="msg-card sent-mssg-card"
                                  style={{ background: "white" }}
                                >
                                  <div className="mx-2 my-2 user-lbl">
                                    <img
                                      src={userChatIcon}
                                      className="mx-1 user-chat-img"
                                    />
                                    Me
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {val?.data?.question}
                                  </div>
                                  <div className="mx-2 my-2 msg-lbl">
                                    {val?.data?.description}
                                  </div>

                                  <>
                                    <div className="bacground-load">
                                      <div className="first-load">
                                        <div className="d-flex justify-content-between final-fi">
                                          <label>
                                            Start Date :{" "}
                                            {moment(
                                              val?.data?.startDate
                                            ).format("l")}
                                          </label>
                                          <label>
                                            End Date :{" "}
                                            {moment(val?.data?.endDate).format(
                                              "l"
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bacground-load">
                                      <div className="first-load">
                                        <div className="d-flex justify-content-between final-fi">
                                          <label>
                                            Start Time : {val?.data?.startTime}
                                          </label>
                                          <label>
                                            End Time: {val?.data?.endTime}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </>

                                  <div className="d-flex justify-content-between time-lbl ">
                                    <label>Here is the result so far</label>
                                    <label>11:00 Am</label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : val?.data?.type === "Poll" ? (
                            <>
                              <div className="item my-3 send-msg">
                                <div
                                  className="msg-card sent-mssg-card"
                                  style={{ background: "white" }}
                                >
                                  <div className="mx-2 my-2 user-lbl">
                                    <img
                                      src={userChatIcon}
                                      className="mx-1 user-chat-img"
                                    />
                                    Me
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {val?.data?.question}
                                  </div>

                                  <>
                                    <div className="bacground-load">
                                      <div
                                        className="first-load fi-bg "
                                        style={{
                                          width:
                                            val?.option1 === true
                                              ? "100%"
                                              : "0%",
                                        }}
                                      >
                                        <div className="d-flex justify-content-between final-fi">
                                          <label>{val?.data?.option1}</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bacground-load">
                                      <div
                                        className="first-load fi-bg "
                                        style={{
                                          width:
                                            val?.option2 === true
                                              ? "100%"
                                              : "0%",
                                        }}
                                      >
                                        <div className="d-flex justify-content-between final-fi">
                                          <label>{val?.data?.option2}</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bacground-load">
                                      <div
                                        className="first-load fi-bg "
                                        style={{
                                          width:
                                            val?.option3 === true
                                              ? "100%"
                                              : "0%",
                                        }}
                                      >
                                        <div className="d-flex justify-content-between final-fi">
                                          <label>{val?.data?.option3}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </>

                                  <div className="d-flex justify-content-between time-lbl ">
                                    <label>Here is the result so far</label>
                                    <label>11:00 Am</label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : val?.data?.type === "Quiz" ? (
                            <>
                              <div className="item my-3 send-msg">
                                <div
                                  className="msg-card sent-mssg-card"
                                  style={{ background: "white" }}
                                >
                                  <div className="mx-2 my-2 user-lbl">
                                    <img
                                      src={userChatIcon}
                                      className="mx-1 user-chat-img"
                                    />
                                    Me
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {val?.data?.question}
                                  </div>

                                  <>
                                    <div className="bacground-load">
                                      <div
                                        className="first-load Quiz-bg "
                                        style={{
                                          width:
                                            val?.option1 === true
                                              ? "100%"
                                              : "0%",
                                        }}
                                      >
                                        <div className="d-flex justify-content-between final-fi">
                                          <label>{val?.data?.option1}</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bacground-load">
                                      <div
                                        className="first-load Quiz-bg "
                                        style={{
                                          width:
                                            val?.option2 === true
                                              ? "100%"
                                              : "0%",
                                        }}
                                      >
                                        <div className="d-flex justify-content-between final-fi">
                                          <label>{val?.data?.option2}</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bacground-load">
                                      <div
                                        className="first-load Quiz-bg "
                                        style={{
                                          width:
                                            val?.option3 === true
                                              ? "100%"
                                              : "0%",
                                        }}
                                      >
                                        <div className="d-flex justify-content-between final-fi">
                                          <label>{val?.data?.option3}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </>

                                  <div className="d-flex justify-content-between time-lbl ">
                                    <label>Here is the result so far</label>
                                    <label>11:00 Am</label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })
                  )} */}

                  {loading ? (
                    <div className="pt-5">
                      <div className="loader_orange"></div>
                    </div>
                  ) : !MediaList?.length ? (
                    // <h4 className="py-3">Data not found</h4>
                    <div className="alert alert-warning my-3" role="alert">
                      Data not found
                    </div>
                  ) : (
                    MediaList?.length &&
                    MediaList?.map((val, i) => {
                      return (
                        <div
                          key={i}
                          className="scroller-content"
                          id="scrollerContent"
                        >
                          <div className="item my-3 send-msg">
                            {val?.type === "pdf" ? (
                              <div
                                className="msg-card sent-mssg-card"
                                style={{ background: "#E0DAD2" }}
                              >
                                <div className="file-div justify-content-evenly">
                                  <img
                                    src={pdfIcon}
                                    style={{ height: "38px" }}
                                  />
                                  <p
                                    style={{
                                      maxWidth: "100px",
                                      wordWrap: "break-word",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {val?.originalfilename}
                                  </p>
                                </div>
                                <p
                                  className="my-1 mt-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  {val?.originalfilename}
                                </p>
                                {/* <p
                                  className="my-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  Media Type: Blog
                                </p> */}
                                <p
                                  className="my-1"
                                  style={{ fontSize: "11px" }}
                                >
                                  {/* Today, 12:00PM */}
                                  {moment(val?.createdAt).format("ll")}
                                </p>
                              </div>
                            ) : (
                              <div
                                className="msg-card sent-mssg-card"
                                style={{ background: "#E0DAD2" }}
                              >
                                <div className="file-div justify-content-evenly">
                                  {/* {console.log(
                                    `${baseApi?.baseurl}images/${val?.mediafile}`
                                  )} */}
                                  <img
                                    src={`${baseApi?.baseurl}images/${val?.mediafile}`}
                                    style={{
                                      maxHeight: "49px",
                                      maxWidth: "88px",
                                    }}
                                  />
                                  {val?.originalfilename}
                                </div>
                                <p
                                  className="my-1 mt-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  {val?.originalfilename}
                                </p>
                                <p
                                  className="my-1"
                                  style={{ fontSize: "11px" }}
                                >
                                  {/* Today, 12:00PM */}
                                  {moment(val?.createdAt).format("ll")}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}

                  {/* <div className="chat-input-box chat-input-box-client justify-content-evenly">
                    <img
                      className="plus-img"
                      src={plusIcon}
                      onClick={() => handleClickMenu(menuVisible)}
                    />
                    <input
                      className="chat-input-text"
                      type="text"
                      id="uploadFile"
                      accept="image/png, image/gif, image/jpeg"
                      tabIndex="1"
                      title=" "
                      disabled
                    // value={currMessage}
                    // onChange={(event) => setCurrMessage(event.target.value)}
                    // onKeyDown={handleKeyPress}
                    />
                    <button
                      className="send-btn text-center me-0"
                    // onClick={sendMessage}
                    >
                      <img src={sendIcon} alt="" />
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </>
        ) : activeIndex === 4 ? (
          <>
            <div className="client-name-list">
              {loading ? (
                <div className="pt-5">
                  <div className="loader_orange"></div>
                </div>
              ) : (
                UpcommingSession?.length &&
                UpcommingSession?.map((val, i) => {
                  return (
                    <div
                      key={i}
                      style={{ padding: "18px 15px" }}
                      className="client-name-card d-flex flex-row"
                      onClick={() =>
                        navigate(
                          `../${AdminRoute?.Auth?.SessionDetails}/${val?._id}`
                        )
                      }
                    >
                      <div className="w-half">
                        <p className="m-0 client-lbl1 m-0">Date</p>
                        <p className="client-lbl2 m-0">
                          {" "}
                          {moment(val?.date).format("ll")}
                        </p>
                      </div>
                      <div className="w-half">
                        <p className="m-0 client-lbl1 m-0">Time</p>
                        <p className="client-lbl2 m-0">
                          {moment(val?.updatedAt).format("LT")}
                        </p>
                      </div>
                      <img
                        style={{ top: "32px" }}
                        src={rightArrow}
                        className="right-arr cursor-pointer"
                        //  NavLink to={`/session-details`}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className="chats-main"
              style={{
                height: "calc(100% - 245px)",
                width: "98%",
                marginLeft: "1%",
              }}
            >
              {/* ************* Chat List ********************** */}

              <div
                className="scroller client-chat"
                style={{ maxHeight: "calc(100% - 73px)" }}
              >
                <div className="scroller-content p-2" id="scrollerContent">
                  {/* ============= for Backup ========================= */}
                  {/* {messageList.length != 0 ? (
                    messageList.map((item, i) =>
                      item?.created_user?.length &&
                      item?.receiver_user?.length ? (
                        <div
                          key={item?._id}
                          className={
                            item?.created_user[0]?._id ===
                            UserData?.userdata?._id
                              ? "item send-msg"
                              : "item my-3 recive-msg"
                          }
                          style={{ margin: "10px 0px" }}
                        >
                          <div
                            className={
                              item?.created_user[0]?._id ===
                              UserData?.userdata?._id
                                ? "msg-card sent-mssg-card"
                                : "msg-card recived-msg-card"
                            }
                          >
                            <div className="mx-2 my-2 user-lbl">
                              <img src={"userChatIcon"} className="mx-1 user-chat-img" />
                              {item?.created_user?.length ? (
                                item?.created_user[0]?.fullname
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="mx-2 my-2 msg-lbl">
                              {item?.message}
                            </div>
                            <div className="d-flex justify-content-end time-lbl ">
                              {item?.createdAt?.slice(11, 19)}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <></>
                  )} */}
                  {/* ================================================== */}
                  {loading ? (
                    <div className="pt-5">
                      <div className="loader_orange"></div>
                    </div>
                  ) : (
                    messageList &&
                    messageList?.map((messageContent) => {
                      return (
                        // messageContent?.created_user?._id === UserData?.userdata?._id
                        <div key={messageContent?._id}>
                          {messageContent?.created_id === params?.ClientId ||
                          messageContent?.receiver_id === params?.ClientId ? (
                            <div
                              className={
                                UserData?.userdata?._id ===
                                messageContent?.created_id
                                  ? "item send-msg"
                                  : "item my-3 recive-msg"
                              }
                              // className="item send-msg"
                              // className="item my-3 recive-msg"
                              style={{ margin: "10px 0px" }}
                            >
                              <div
                                className={
                                  UserData?.userdata?._id ===
                                  messageContent?.created_id
                                    ? "msg-card sent-mssg-card"
                                    : "msg-card recived-msg-card"
                                }
                                // className="msg-card sent-mssg-card"
                                // className="msg-card recived-msg-card"
                              >
                                <div className="mx-2 my-2 user-lbl">
                                  <img
                                    src={
                                      UserData?.userdata?._id ===
                                      messageContent?.created_id
                                        ? UserData?.userdata?.image
                                          ? UserData?.userdata?.image
                                          : userChatIcon 
                                        : ClientDetails?.image ? ClientDetails?.image :userChatIcon
                                    }
                                    className="mx-1 user-chat-img"
                                  />
                                  <span className="ml-piv">
                                    {UserData?.userdata?._id ===
                                    messageContent?.created_id
                                      ? UserData?.userdata?.fullname
                                      : `${ClientDetails?.name} ${ClientDetails?.last_name}`}{" "}
                                  </span>
                                </div>
                                <div className="mx-2 my-2 msg-lbl">
                                  {messageContent?.message}
                                </div>
                                <div className="d-flex justify-content-end time-lbl text-dark">
                                  {messageContent?.createdAt
                                    ? formatDateTime(messageContent?.createdAt)
                                    : formatDateTime(new Date())}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })
                  )}

                  {/* <div className="item send-msg" style={{ margin: "10px 0px" }}>
                    <div className="msg-card sent-mssg-card">
                      <div className="mx-2 my-2 user-lbl">
                        <img
                          src={userChatIcon}
                          className="mx-1 user-chat-img"
                        />
                        Me
                      </div>
                      <div className="mx-2 my-2 msg-lbl">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Omnis dolores explicabo iure repudiandae, quo
                        repellat. Reiciendis facere maxime in commodi
                        voluptatibus quos hic optio, culpa magni, a, dolorem
                        recusandae cumque.
                      </div>
                      <div className="d-flex justify-content-end time-lbl ">
                        11:00 Am
                      </div>
                    </div>
                  </div> */}

                  {/* -------------------------------- */}

                  <div className="chat-input-box chat-input-box-client justify-content-evenly">
                    <input
                      className="chat-input-text"
                      type="text"
                      id="uploadFile"
                      accept="image/png, image/gif, image/jpeg"
                      tabIndex="1"
                      title=" "
                      value={currMessage}
                      onChange={(event) => setCurrMessage(event.target.value)}
                      onKeyDown={handleKeyPress}
                      autoComplete="off"
                    />
                    <button
                      className="send-btn text-center me-0"
                      onClick={sendMessage}
                    >
                      <img src={sendIcon} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* ========================== Event Model =============== */}
      {/* <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="pop-up-com-header ">
          <label>Create Event</label>
          <button onClick={closeModal} className="close-btn-client">
            <img src={plusIconClose} />{" "}
          </button>
        </div>

        <div>
          <p className="commmon-heading mt-2">Event</p>
          <input
            type="text"
            placeholder="Enter event title"
            className="mb-3"
            name="EAskQuestion"
            onChange={(e) => handleChangeEvent(e)}
          />
          <p className="text-danger">{formEventErrors?.EAskQuestion}</p>
          <p className="commmon-heading"> Enter event description</p>
          <textarea
            rows="10"
            name="EDescription"
            className="form-input-text-area"
            placeholder="Event Description"
            onChange={(e) => handleChangeEvent(e)}
          ></textarea>
          <p className="text-danger">{formEventErrors?.EDescription}</p>

          <div className="d-flex justify-content-around">
            <div>
              <p className="commmon-heading">* Start Time</p>
              <select
                name="ESTime"
                className="time-select"
                style={{ width: "130px" }}
                onChange={(e) => handleChangeEvent(e)}
              >
                {TimeSlotS.map((ele, i) => {
                  return (
                    <option key={i} value={ele}>
                      {ele}
                    </option>
                  );
                })}
              </select>
              <p className="text-danger">{formEventErrors?.ESTime}</p>
            </div>

            <div>
              <p className="commmon-heading">* End Time</p>

              <select
                className="time-select"
                name="EETime"
                style={{ width: "130px" }}
                onChange={(e) => handleChangeEvent(e)}
              >
                {TimeSlotE.map((ele, i) => {
                  return (
                    <>
                      <option key={i} value={ele}>
                        {ele}
                      </option>
                    </>
                  );
                })}
              </select>
              <p className="text-danger">{formEventErrors?.EETime}</p>
            </div>
          </div>

          <div className="d-flex justify-content-around">
            <div>
              <p className="commmon-heading">Start Date</p>
              <input
                type="date"
                placeholder="My Task"
                className="mb-3"
                style={{ width: "130px", fontSize: "14px" }}
                name="ESDate"
                onChange={(e) => handleChangeEvent(e)}
              />
              <p className="text-danger">{formEventErrors?.ESDate}</p>
            </div>
            <div>
              <p className="commmon-heading">End Date</p>
              <input
                type="date"
                placeholder="My Task"
                style={{ width: "130px", fontSize: "14px" }}
                className="mb-3"
                name="EEDate"
                onChange={(e) => handleChangeEvent(e)}
              />
              <p className="text-danger">{formEventErrors?.EEDate}</p>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
          onClick={(e) => handleSubmitEvent(e)}
        >
          Publish
        </button>
      </Modal> */}
      {/* ==================== Poll Model =================== */}
      {/* <Modal
        isOpen={createPollmodalIsOpen}
        onAfterOpen={afterPollOpenModal}
        onRequestClose={closePollModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="pop-up-com-header ">
          <label>Create Poll</label>
          <button onClick={closePollModal} className="close-btn-client">
            <img src={plusIconClose} />{" "}
          </button>
        </div>
        <p className="commmon-heading mt-2">Ask Question</p>
        <input
          type="text"
          placeholder="Enter question"
          className="mb-3"
          name="PollQuestion"
          onChange={(e) => handleChangePoll(e)}
        />
        <p className="text-danger">{formPollErrors?.PollQuestion}</p>
        <p className="commmon-heading">Options</p>
        <input
          type="text"
          placeholder="Enter Option 1"
          className="mb-3"
          name="PollOption1"
          onChange={(e) => handleChangePoll(e)}
        />
        <p className="text-danger">{formPollErrors?.PollOption1}</p>
        <input
          type="text"
          placeholder="Enter Option 2"
          className="mb-3"
          name="PollOption2"
          onChange={(e) => handleChangePoll(e)}
        />
        <p className="text-danger">{formPollErrors?.PollOption2}</p>
        <input
          type="text"
          placeholder="Enter Option 3"
          className="mb-3"
          name="PollOption3"
          onChange={(e) => handleChangePoll(e)}
        />
        <p className="text-danger">{formPollErrors?.PollOption3}</p>

        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
          onClick={(e) => handleSubmitPoll(e)}
        >
          Publish
        </button>
      </Modal> */}

      {/* ============== Quiz Model ===================== */}
      {/* <Modal
        isOpen={createQuizmodalIsOpen}
        onAfterOpen={afterQuizOpenModal}
        onRequestClose={closeQuizModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="pop-up-com-header ">
          <label>Create Quiz</label>
          <button onClick={closeQuizModal} className="close-btn-client">
            <img src={plusIconClose} />{" "}
          </button>
        </div>
        <p className="commmon-heading mt-2">Ask Question</p>
        <input
          type="text"
          placeholder="Enter question"
          className="mb-3"
          name="QuizQuestion"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizQuestion}</p>
        <p className="commmon-heading">Options</p>
        <input
          type="text"
          placeholder="Enter Option 1"
          className="mb-3"
          name="QuizOption1"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizOption1}</p>
        <input
          type="text"
          placeholder="Enter Option 2"
          className="mb-3"
          name="QuizOption2"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizOption2}</p>
        <input
          type="text"
          placeholder="Enter Option 3"
          className="mb-3"
          name="QuizOption3"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizOption3}</p>
        <p className="commmon-heading">Correct Answer</p>
        <input
          type="text"
          placeholder="Enter currect answer"
          className="mb-3"
          name="QuizAnswer"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizAnswer}</p>

        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
          onClick={(e) => handleSubmitQuiz(e)}
        >
          Publish
        </button>
      </Modal> */}
    </div>
  );
};

export default ClientDetails;
