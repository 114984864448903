import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import plusIcon from "../../assets/images/icons/+.png";
import plusIconClose from "../../assets/images/icons/Icon feather-plus.png";
import sendIcon from "../../assets/images/icons/Icon feather-send.png";
import userChatIcon from "../../assets/images/icons/Icon awesome-user-circle.png";
import userChatIconDark from "../../assets/images/icons/Icon awesome-user-circle-dark.png";
import io from "socket.io-client";
import Modal from "react-modal";
import Header from "../Common/Header";
import { useParams, NavLink, useNavigate, useLocation } from "react-router-dom";
import pdfIcon from "../../assets/images/icons/pdf.png";
import baseApi from "../../Api/config";
import API from "../../Api/Api";
import Button from "react-bootstrap/Button";
import moment from "moment";
import AdminRoute from "../../Route/RouteDetails";
import config from "../../agora-manager/config";
import SideBarMenu from "../Common/SideBarMenu";
import Dropdown from "react-bootstrap/Dropdown";
import { socket } from "../../socket";
import { formatDateTime } from "../Common/Functions";

// const socket = io(process.env.REACT_APP_BASE_URL_SOCKET, {
//   path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
// });

const ChatBox = () => {
  // const location = useLocation();
  // const socket =
  //   location.pathname.includes("ChatBox") &&
  //   io(process.env.REACT_APP_BASE_URL_SOCKET, {
  //     path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
  //   });
  const navigate = useNavigate();
  const [room] = useState("chatmessage");
  const [currMessage, setCurrMessage] = useState("");
  const [ResourseUrl, setResourseUrl] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuVisible2, setMenuVisible2] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [loadingChat, setLoadingChat] = useState(true);
  const { GroupId } = useParams();
  const [mediaType, setmediaType] = useState("");
  const [loading, setLoading] = useState(true);
  const [is_loading, setis_loading] = useState(false);

  // ************ Initial functions *******************

  // const TimeSlotS = [
  //   "Start Time",
  //   "09:00 AM",
  //   "10:00 AM",
  //   "11:00 AM",
  //   "12:00 PM",
  //   "01:00 PM",
  //   "02:00 PM",
  //   "03:00 PM",
  //   "04:00 PM",
  //   "05:00 PM",
  //   "06:00 PM",
  //   "07:00 PM",
  //   "08:00 PM",
  // ];

  // const TimeSlotE = [
  //   "09:00 AM",
  //   "10:00 AM",
  //   "11:00 AM",
  //   "12:00 PM",
  //   "01:00 PM",
  //   "02:00 PM",
  //   "03:00 PM",
  //   "04:00 PM",
  //   "05:00 PM",
  //   "06:00 PM",
  //   "07:00 PM",
  //   "08:00 PM",
  // ];

  const TimeSlotS = [
    "Start Time",
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  const TimeSlotE = [
    "Start Time",
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height: "500px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#FFF",
      borderRadius: "20px",
      paddingTop: "70px",
      width: "330px",
      boxShadow: "0px 0px 24px -10px #bdbdbd",
      overflowX: "hidden",
      backdropFilter: "blur(10px)",
    },
  };

  const [GroupDetail, setGroupDetail] = useState([]);

  const GroupDetails = () => {
    API.Group.GroupDetails({
      GroupId: GroupId,
    })
      .then((response) => {
        console.log("Group Details", response?.data?.data[0]);
        setGroupDetail(response?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
        setLoadingChat(false);
      });
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [createPollmodalIsOpen, setPollModel] = React.useState(false);
  const [createQuizmodalIsOpen, setQuizModel] = React.useState(false);

  function openModal() {
    setMenuVisible(false);
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openPollModal() {
    setMenuVisible(false);
    setPollModel(true);
  }
  function afterPollOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closePollModal() {
    setPollModel(false);
  }

  function openQuizModal() {
    setMenuVisible(false);
    setQuizModel(true);
  }
  function afterQuizOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeQuizModal() {
    setQuizModel(false);
  }

  const handleClickMenu = (stat) => {
    setMenuVisible(!stat);
  };

  const handleClickMenu2 = (stat) => {
    setMenuVisible2(!stat);
  };

  // **************** Create Event ***************

  const initialValuesEvent = {
    EAskQuestion: "",
    EDescription: "",
    ESTime: "",
    EETime: "",
    ESDate: "",
    // EEDate: "",
  };

  const [formDataEvent, setFormDataEvent] = useState(initialValuesEvent);
  const [formEventErrors, setFormEventErrors] = useState({});
  const [isEventSubmit, setIsEventSubmit] = useState(false);

  const handleChangeEvent = (e) => {
    const { name, value } = e.target;
    setFormDataEvent({ ...formDataEvent, [name]: value });
  };

  const EventValidate = (values) => {
    // console.log("===> ok", values);
    const errors = {};

    if (!values?.EAskQuestion) {
      errors.EAskQuestion = "Question is required";
    }
    if (!values?.EDescription) {
      errors.EDescription = "Description is required";
    }
    if (!values?.ESTime) {
      errors.ESTime = "Start time is required";
    }
    if (!values?.EETime) {
      errors.EETime = "End time is required";
    }
    if (!values?.ESDate) {
      errors.ESDate = "Start date is required";
    }
    // if (!values?.EEDate) {
    //   errors.EEDate = "End date is required";
    // }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formEventErrors).length === 0 && isEventSubmit) {
      setis_loading(true);
      try {
        API.Event.CreateEvent({
          data: {
            question: formDataEvent?.EAskQuestion,
            description: formDataEvent?.EDescription,
            startTime: formDataEvent?.ESTime,
            endTime: formDataEvent?.EETime,
            startDate: formDataEvent?.ESDate,
            endDate: formDataEvent?.ESDate,
            created_by: UserData?.userdata?._id,
            group_id: GroupId,
            type: "Event",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            closeModal();
            EventListFun();
          }
          setis_loading(false);
        });
      } catch (error) {
        console.log(error);
        setis_loading(false);
      }
      // console.log("formDataEvent", formDataEvent);
    }
  }, [formEventErrors]);

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    setFormEventErrors(EventValidate(formDataEvent));
    setIsEventSubmit(true);
  };

  // *************** Create Event End here ***************

  // ***************** Activity List ******************

  const [EventList, setEventList] = useState([]);

  const EventListFun = () => {
    try {
      API.Event.EventList({
        GroupId: GroupId,
      }).then((response) => {
        console.log("Event List  ===>", response?.data?.data_all);
        setEventList(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // *************** Create Poll ***************

  const initialValuesPoll = {
    PollQuestion: "",
    PollOption1: "",
    PollOption2: "",
    PollOption3: "",
  };

  const [formDataPoll, setFormDataPoll] = useState(initialValuesPoll);
  const [formPollErrors, setFormPollErrors] = useState({});
  const [isPollSubmit, setIsPollSubmit] = useState(false);

  const handleChangePoll = (e) => {
    const { name, value } = e.target;
    setFormDataPoll({ ...formDataPoll, [name]: value });
  };

  const PollValidate = (values) => {
    // console.log("===> Poll values", values);
    const pollerrors = {};

    if (!values?.PollQuestion) {
      pollerrors.PollQuestion = "Poll question is required!";
    }
    if (!values?.PollOption1) {
      pollerrors.PollOption1 = "Poll Option 1 is required!";
    }
    if (!values?.PollOption2) {
      pollerrors.PollOption2 = "Poll Option 2 is required!";
    }
    if (!values?.PollOption3) {
      pollerrors.PollOption3 = "Poll Option 3 is required!";
    }

    return pollerrors;
  };

  useEffect(() => {
    if (Object.keys(formPollErrors).length === 0 && isPollSubmit) {
      setis_loading(true);
      try {
        API.Event.CreateEvent({
          data: {
            question: formDataPoll?.PollQuestion,
            option1: formDataPoll?.PollOption1,
            option2: formDataPoll?.PollOption2,
            option3: formDataPoll?.PollOption3,
            created_by: UserData?.userdata?._id,
            group_id: GroupId,
            type: "Poll",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            closePollModal();
            EventListFun();
          }
          setis_loading(false);
        });
      } catch (error) {
        console.log(error);
        setis_loading(false);
      }
      // console.log("formDataPoll", formDataPoll);
    }
  }, [formPollErrors]);

  const handleSubmitPoll = (e) => {
    e.preventDefault();
    setFormPollErrors(PollValidate(formDataPoll));
    setIsPollSubmit(true);
  };

  // ************* Create Poll Ends **********************

  //  *********** Create Quiz *****************

  const initialValuesQuiz = {
    QuizQuestion: "",
    QuizOption1: "",
    QuizOption2: "",
    QuizOption3: "",
    QuizAnswer: "",
  };

  const [formDataQuiz, setFormDataQuiz] = useState(initialValuesQuiz);
  const [formQuizErrors, setFormQuizErrors] = useState({});
  const [isQuizSubmit, setIsQuizSubmit] = useState(false);

  const handleChangeQuiz = (e) => {
    const { name, value } = e.target;
    setFormDataQuiz({ ...formDataQuiz, [name]: value });
  };

  const QuizValidate = (values) => {
    // console.log("===>  values", values);

    const Quizerrors = {};

    if (!values?.QuizQuestion) {
      Quizerrors.QuizQuestion = "Quiz question is required!";
    }
    if (!values?.QuizOption1) {
      Quizerrors.QuizOption1 = "Quiz option 1 is required!";
    }
    if (!values?.QuizOption2) {
      Quizerrors.QuizOption2 = "Quiz option 2 is required!";
    }
    if (!values?.QuizOption3) {
      Quizerrors.QuizOption3 = "Quiz option 3 is required!";
    }

    // =====================================

    if (!values?.QuizAnswer) {
      Quizerrors.QuizAnswer = "Quiz answer is required!";
    } else if (
      values?.QuizOption1 !== values?.QuizAnswer &&
      values?.QuizOption2 !== values?.QuizAnswer &&
      values?.QuizOption3 !== values?.QuizAnswer
    ) {
      Quizerrors.QuizAnswer = "Answer should be match with options!";
    }

    return Quizerrors;
  };

  useEffect(() => {
    if (Object.keys(formQuizErrors).length === 0 && isQuizSubmit) {
      setis_loading(true);
      try {
        API.Event.CreateEvent({
          data: {
            question: formDataQuiz?.QuizQuestion,
            option1: formDataQuiz?.QuizOption1,
            option2: formDataQuiz?.QuizOption2,
            option3: formDataQuiz?.QuizOption3,
            answer: formDataQuiz?.QuizAnswer,
            created_by: UserData?.userdata?._id,
            group_id: GroupId,
            type: "Quiz",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.status === 1) {
            closeQuizModal();
            EventListFun();
          }
          setis_loading(false);
        });
      } catch (error) {
        console.log(error);
        setis_loading(false);
      }
      // console.log("formDataQuiz", formDataQuiz);
    }
  }, [formQuizErrors]);

  const handleSubmitQuiz = (e) => {
    e.preventDefault();
    setFormQuizErrors(QuizValidate(formDataQuiz));
    setIsQuizSubmit(true);
  };

  // *********************** Create Quiz Ends ******************

  // **************** Upload multimedia and upload pdf start ******************

  const handleUpload = async (e) => {
    try {
      var formdata = new FormData();
      await formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => CreateMediafun(JSON.parse(result).data)
          // setSpinner(false)
        );
    } catch (err) {
      console.log(err.res);
      // setSpinner(false);
    }
  };
  const CreateMediafun = async (media) => {
    // console.log("Create media function", media);
    // console.log("type", mediaType);
    console.log("media", media?.filename);
    // console.log(media?.filename.split(".").pop());
    let mediaType = media?.filename.split(".").pop();

    try {
      await API.Event.CreateMedia({
        data: {
          mediafile: media?.filename,
          type: mediaType === "pdf" ? "pdf" : "mediafile",
          group_id: GroupId,
          originalfilename: media?.originalname,
        },
      }).then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === 1) {
          handleClickMenu2(menuVisible2);
          MediaListFun();
          ToggleF();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const uploadurl = (e) => {
    e.preventDefault();
    // alert("upload url");
    if (ResourseUrl) {
      try {
        API.Event.CreateMedia({
          data: {
            mediafile: ResourseUrl,
            type: "url",
            group_id: GroupId,
          },
        }).then((response) => {
          console.log(response?.data);
          if (response?.data?.status === 1) {
            handleClickMenu2(menuVisible2);
            MediaListFun();
            setResourseUrl("");
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // ********************************************************

  // ***************** Resources list ******************

  const [MediaList, setMediaList] = useState([]);

  const MediaListFun = () => {
    setLoading(true);
    try {
      API.Event.MediaList({
        GroupId: GroupId,
      }).then((response) => {
        // console.log("media List  ===>", response?.data);
        setMediaList(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // **************** Chat Functionality Starts *********************

  const getGropAllChats = () => {
    setLoadingChat(true);
    API.Group.GroupsChatList({
      group_id: GroupId,
    })
      .then((response) => {
        if (response.data.status === 1) {
          // console.log("Msg===>", response.data.data_all);
          setMessageList(response.data.data_all);
          setLoadingChat(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingChat(false);
      });
  };

  //socket .io
  const sendMessage = async () => {
    // console.log("calll");
    if (currMessage !== "") {
      const messageData = {
        room_name: room,
        author: `${UserData?.userdata?.fullname}`,
        user_id: UserData?.userdata?._id,
        group_id: GroupId,
        message: currMessage,
        // time:
        //   new Date(Date.now()).getHours() +
        //   ":" +
        //   new Date(Date.now()).getMinutes(),
        time: new Date(),
      };
      await socket.emit(room, messageData);
    }
    setCurrMessage("");
  };

  // useEffect(() => {
  //   socket.on(room, (data) => {
  //     setMessageList((list) => [...list, data]);
  //   });
  //   return () => {
  //     // socket.removeListener(room);
  //     socket.off(room); // Remove the listener when the component unmounts or the effect is re-run
  //   };
  // }, [socket]);

  useEffect(() => {
    if (!socket || !room) return;
    socket.connect();

    const handleData = (data) => {
      // if (selectedGroup && selectedGroup._id === data.group_id) {
      setMessageList((list) => [...list, data]);
      // }
    };

    socket.on(room, handleData);

    return () => {
      socket.off(room, handleData); // Specify the same handler to properly remove it
      socket.disconnect();
    };
  }, [socket, room]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage(e);
    }
  };

  // **************** Chat Functionality Ends *********************

  //get Previous chat data
  useEffect(() => {
    getGropAllChats();
    EventListFun();
    MediaListFun();
    GroupDetails();
  }, []);

  const renderActionButton = (SessionId, meetingtoken, mettingname) => {
    // return joined ? (
    //     <button onClick={handleLeaveClick}>Leave</button>
    // ) : (
    //     <button onClick={handleJoinClick}>Join</button>
    // );

    try {
      API.JoinSession.Joinmeeting({
        data: {
          role: "Therapist",
          meetingId: mettingname,
        },
      }).then((response) => {
        console.log(response?.data);
        if (response?.data?.status === 1) {
          navigate(
            `../${AdminRoute.Auth.VideoCall}/${SessionId}?id=${btoa(
              meetingtoken
            )}&name=${mettingname}`
          );
        }
      });
    } catch (error) {
      console.log(error);
    }

    // -------------------------------------------------------------------------
  };
  config.selectedProduct = "rtc";

  // const HandleMsgCount = () => {
  //   API.Group.Groupcount({
  //     data: {
  //       groupid: GroupId,
  //       // count: val.msgunread ? val.msgunread : 0,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.data.status === 1) {
  //         // setLoadingSubmitAns(false);
  //         // getAllEventData(val.group[0]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       // setLoadingSubmitAns(false);
  //     });
  // };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");

    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const [StateToggle, setStateToggle] = useState(false);

  const ToggleF = () => {
    setStateToggle(!StateToggle);
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header
          isBack={true}
          header={GroupDetail?.name}
          isJoinSessionbtn={false}
          isGroupImg={GroupDetail?.logo}
          GroupId={GroupDetail?._id}
          // onClick={() => navigate(`../${AdminRoute?.Auth?.GroupDetails}`)}
        ></Header>
        {/* <Header isBack={true} header={"Group Details"} isSetting={true}></Header> */}
        <div className="chats-container">
          <div className="tabs-container">
            <div
              className="tab-item cursor-pointer"
              style={{
                borderBottom:
                  activeIndex === 0 ? "solid 3px rgb(249,221,163)" : "",
              }}
              onClick={() => setActiveIndex(0)}
            >
              Discussion
            </div>
            <div
              className="tab-item cursor-pointer"
              onClick={() => setActiveIndex(1)}
              style={{
                borderBottom:
                  activeIndex === 1 ? "solid 3px rgb(249,221,163)" : "",
              }}
            >
              Activity
            </div>
            <div
              className="tab-item cursor-pointer"
              onClick={() => setActiveIndex(2)}
              style={{
                borderBottom:
                  activeIndex === 2 ? "solid 3px rgb(249,221,163)" : "",
              }}
            >
              Resource
            </div>
          </div>
          <div className="chats-main">
            {/* <div
              className="menu-container menu-container-chatbox"
              style={{ display: menuVisible ? "block" : "none" }}
            >
              <div className="menu-items-wrap p-2">
                <div
                  className="menu-item px-2 py-1 cursor-pointer"
                  onClick={() => openModal()}
                >
                  <img src={plusIcon} style={{ marginRight: "10px" }} />
                  Create Events ---
                </div>
                <div
                  className="menu-item px-2 py-1 cursor-pointer"
                  onClick={() => openPollModal()}
                >
                  <img src={plusIcon} style={{ marginRight: "10px" }} />
                  Create Poll
                </div>
                <div
                  className="menu-item px-2 py-1 cursor-pointer"
                  onClick={() => openQuizModal()}
                >
                  <img src={plusIcon} style={{ marginRight: "10px" }} />
                  Create Quiz
                </div>
              </div>
            </div> */}

            {/* ======================================================= */}
            {/* 
            <div
              className="menu-container menu-container-chatbox"
              style={{ display: menuVisible2 ? "block" : "none" }}
            >
              <div className="menu-items-wrap p-2">
                <div className="menu-item px-2 py-1 cursor-pointer">
                  <img htmlFor="uploadpdf" src={plusIcon} />
                  <input
                    type="file"
                    id="uploadpdf"
                    accept="application/pdf"
                    className="custom-file-input"
                    title=" "
                    onChange={(e) => handleUpload(e)}
                  />
                  <label htmlFor="uploadpdf">Upload pdf</label>
                </div>
                <div className="menu-item px-2 py-1 cursor-pointer">
                  <img htmlFor="uploadmedia" src={plusIcon} />
                  <input
                    type="file"
                    id="uploadmedia"
                    className="custom-file-input"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    title=" "
                    onChange={(e) => handleUpload(e)}
                  />
                  <label htmlFor="uploadmedia">Upload Multimedia</label>
                </div>
              </div>
            </div> */}

            {/* ================================================================= */}

            {loading ? (
              <div className="loader_orange"></div>
            ) : activeIndex === 0 ? (
              <>
                <div className="scroller group-chatbox">
                  <div className="scroller-content" id="scrollerContent">
                    {/* ===================================================== */}
                    {/* {messageList.map((messageContent, i) => {
                      return (
                        <li
                          className={
                            UserData?.userdata?._id === messageContent.user_id
                              ? "send-message "
                              : "receive-message"
                          }

                          // className="receive-message"
                          key={messageContent.author + i}
                        >
                          <div className="message other-message float-right">
                            <h6>
                              <span>
                                <img
                                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg"
                                  alt="avatar"
                                />
                              </span>
                              <span className="ml-piv">
                                {messageContent.author
                                  ? messageContent.author
                                  : messageContent?.user[0]?.fullname}
                              </span>
                            </h6>
                            <p>{messageContent.message}</p>
                            <div className="message-data-time message-data align-right">
                              {messageContent.time}, Today
                            </div>
                          </div>
                        </li>
                      );
                    })} */}

                    {/* ============================================ */}

                    {/* recieve messge  */}

                    {messageList.map((messageContent, i) => {
                      return (
                        <div
                          key={messageContent?.author + i}
                          className={
                            UserData?.userdata?._id === messageContent?.user_id
                              ? "item my-3 send-msg"
                              : "item my-3 recive-msg"
                          }
                        >
                          <div
                            className={
                              UserData?.userdata?._id ===
                              messageContent?.user_id
                                ? "msg-card sent-mssg-card"
                                : "msg-card recived-msg-card"
                            }
                          >
                            <div className="mx-1 my-2 user-lbl">
                              <p className="me-2 user-chat-img d-flex justify-content-center align-items-center mb-0">
                                <img
                                  // src={userChatIcon}
                                  src={
                                    UserData?.userdata?._id ===
                                    messageContent?.user_id
                                      ? UserData?.userdata?.image
                                        ? UserData?.userdata?.image
                                        : userChatIcon
                                      : messageContent?.user[0]?.image ? messageContent?.user[0]?.image : userChatIconDark
                                  }
                                />
                              </p>
                              {messageContent?.author
                                ? messageContent?.author
                                : messageContent?.user[0]?.fullname
                                ? messageContent?.user[0]?.fullname
                                : `${messageContent?.user[0]?.name} ${messageContent?.user[0]?.last_name}`}
                            </div>
                            <div className="mx-1 my-2 msg-lbl">
                              {messageContent?.message}
                            </div>
                            <div className="d-flex justify-content-end time-lbl text-dark">
                              {/* {moment(messageContent?.time).format("LT")} */}
                              {messageContent?.createdAt
                                ? formatDateTime(messageContent?.createdAt)
                                : formatDateTime(new Date())}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* ===== Receive msg end ====== */}

                    {/* ========== Sent messeges Start====== */}

                    {/* <div className="item send-msg">
                      <div className="msg-card sent-mssg-card">
                        <div className="mx-2 my-2 user-lbl">
                          <img
                            src={userChatIcon}
                            className="mx-1 user-chat-img"
                          />
                          Me
                        </div>
                        <div className="mx-2 my-2 msg-lbl">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Omnis dolores explicabo iure repudiandae, quo
                          repellat. Reiciendis facere maxime in commodi
                          voluptatibus quos hic optio, culpa magni, a, dolorem
                          recusandae cumque.
                        </div>
                        <div className="d-flex justify-content-end time-lbl ">
                          11:00 Am
                        </div>
                      </div>
                    </div> */}

                    {/* ==========Sent messeges end ====== */}
                  </div>
                  {loadingChat ? <div className="loader_orange"></div> : <></>}
                </div>
              </>
            ) : activeIndex === 1 ? (
              <>
                <div className="scroller group-chatbox activity-tab">
                  <div className="scroller-content" id="scrollerContent">
                    {/* Event  */}

                    {!EventList?.length ? (
                      // <h4 className="py-3">Data not found</h4>
                      <div className="alert alert-warning my-3" role="alert">
                        Data not found
                      </div>
                    ) : (
                      EventList?.length &&
                      EventList?.map((val, i) => {
                        return (
                          <>
                            <div key={i}>
                              {val?.data?.type === "Event" ? (
                                <>
                                  <div className="item my-3 send-msg">
                                    <div
                                      className="msg-card sent-mssg-card"
                                      style={{ background: "white" }}
                                    >
                                      <div className="mx-2 my-2 user-lbl">
                                        <p className="me-2 user-chat-img d-flex justify-content-center align-items-center mb-0">
                                          <img
                                            src={
                                              UserData?.userdata?.image
                                                ? UserData?.userdata?.image
                                                : userChatIcon
                                            }
                                          />
                                        </p>
                                        Me
                                      </div>
                                      <div
                                        className="m-2"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {val?.data?.question}
                                      </div>
                                      <div className="mx-2 my-2 msg-lbl">
                                        {val?.data?.description}
                                      </div>

                                      <>
                                        <div className="bacground-load mb-2">
                                          <div className="first-load">
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                Start Date :{" "}
                                                {moment(
                                                  val?.data?.startDate
                                                ).format("l")}
                                              </label>
                                              {/* <label>
                                                End Date :{" "}
                                                {moment(
                                                  val?.data?.endDate
                                                ).format("l")}
                                              </label> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bacground-load mb-3">
                                          <div className="first-load">
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                Start Time :{" "}
                                                {val?.data?.startTime}
                                              </label>
                                              <label>
                                                End Time: {val?.data?.endTime}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="button">
                                          <Button
                                            type="submit"
                                            onClick={() =>
                                              renderActionButton(
                                                1,
                                                val?.data.meetingtoken,
                                                val?.data.mettingname
                                              )
                                            }
                                            className="button_common m-0 p-2 w-100"
                                          >
                                            <span className="white-text">
                                              Join Group Session
                                            </span>
                                          </Button>
                                        </div>
                                      </>

                                      <div className="d-flex justify-content-between time-lbl text-dark mx-2 my-1 mt-2">
                                        {/* <label>Here is the result so far</label> */}
                                        <label>
                                          {moment(val?.data?.createdAt).format(
                                            "LLL"
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : val?.data?.type === "Poll" ? (
                                <>
                                  <div className="item my-3 send-msg">
                                    <div
                                      className="msg-card sent-mssg-card"
                                      style={{ background: "white" }}
                                    >
                                      <div className="mx-2 my-2 user-lbl">
                                        <p className="me-2 user-chat-img d-flex justify-content-center align-items-center mb-0">
                                          <img
                                            src={
                                              UserData?.userdata?.image
                                                ? UserData?.userdata?.image
                                                : userChatIcon
                                            }
                                          />
                                        </p>
                                        Me
                                      </div>
                                      <div
                                        className="m-2"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {val?.data?.question}
                                      </div>

                                      <>
                                        <div className="bacground-load mb-2">
                                          <div
                                            className="first-load fi-bg "
                                            // style={{ width: "40%" }}
                                            style={{
                                              width: `${
                                                ((val?.score?.option1 || 0) /
                                                  (val?.totaloption?.total ||
                                                    1)) *
                                                100
                                              }%`,
                                            }}
                                          >
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                {val?.data?.option1}
                                              </label>
                                              <label>{`${
                                                ((val?.score?.option1 || 0) /
                                                  (val?.totaloption?.total ||
                                                    1)) *
                                                100
                                              }%`}</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bacground-load mb-2">
                                          <div
                                            className="first-load fi-bg "
                                            // style={{ width: "30%" }}
                                            style={{
                                              width: `${
                                                ((val?.score?.option2 || 0) /
                                                  (val?.totaloption?.total ||
                                                    1)) *
                                                100
                                              }%`,
                                            }}
                                          >
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                {val?.data?.option2}
                                              </label>
                                              <label>{`${
                                                ((val?.score?.option2 || 0) /
                                                  (val?.totaloption?.total ||
                                                    1)) *
                                                100
                                              }%`}</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bacground-load mb-2">
                                          <div
                                            className="first-load fi-bg "
                                            // style={{ width: "30%" }}
                                            style={{
                                              width: `${
                                                ((val?.score?.option3 || 0) /
                                                  (val?.totaloption?.total ||
                                                    1)) *
                                                100
                                              }%`,
                                            }}
                                          >
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                {val?.data?.option3}
                                              </label>
                                              <label>{`${
                                                ((val?.score?.option3 || 0) /
                                                  (val?.totaloption?.total ||
                                                    1)) *
                                                100
                                              }%`}</label>
                                            </div>
                                          </div>
                                        </div>
                                      </>

                                      <div className="d-flex justify-content-between time-lbl text-dark mx-2 my-1">
                                        {/* <label>Here is the result so far</label> */}
                                        <label>
                                          {moment(val?.data?.createdAt).format(
                                            "LLL"
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : val?.data?.type === "Quiz" ? (
                                <>
                                  <div className="item my-3 send-msg">
                                    <div
                                      className="msg-card sent-mssg-card"
                                      style={{ background: "white" }}
                                    >
                                      <div className="mx-2 my-2 user-lbl">
                                        <p className="me-2 user-chat-img d-flex justify-content-center align-items-center mb-0">
                                          <img
                                            src={
                                              UserData?.userdata?.image
                                                ? UserData?.userdata?.image
                                                : userChatIcon
                                            }
                                          />
                                        </p>
                                        Me
                                      </div>
                                      <div
                                        className="m-2"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {/* Which of the following method do you
                                      prefer ? */}
                                        {val?.data?.question}
                                      </div>

                                      <>
                                        <div className="bacground-load mb-2">
                                          <div
                                            className="first-load Quiz-bg "
                                            style={{
                                              width:
                                                val?.data?.option1 ===
                                                val?.data?.answer
                                                  ? "100%"
                                                  : "0%",
                                            }}
                                          >
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                {val?.data?.option1}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bacground-load mb-2">
                                          <div
                                            className="first-load Quiz-bg "
                                            style={{
                                              width:
                                                val?.data?.option2 ===
                                                val?.data?.answer
                                                  ? "100%"
                                                  : "0%",
                                            }}
                                          >
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                {val?.data?.option2}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bacground-load mb-2">
                                          <div
                                            className="first-load Quiz-bg "
                                            style={{
                                              width:
                                                val?.data?.option3 ===
                                                val?.data?.answer
                                                  ? "100%"
                                                  : "0%",
                                            }}
                                          >
                                            <div className="d-flex justify-content-between final-fi">
                                              <label>
                                                {val?.data?.option3}
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </>

                                      <div className="d-flex justify-content-between time-lbl text-dark mx-2 my-1">
                                        {/* <label>Here is the result so far</label> */}
                                        <label>
                                          {moment(val?.data?.createdAt).format(
                                            "LLL"
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        );
                      })
                    )}

                    {/* sample card  */}

                    {/* <div className="item my-3 send-msg">
                      <div
                        className="msg-card sent-mssg-card"
                        style={{ background: "white" }}
                      >
                        <div className="mx-2 my-2 user-lbl">
                          <img
                            src={userChatIcon}
                            className="mx-1 user-chat-img"
                          />
                          Me
                        </div>
                        <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Which of the following method do you prefer ?
                        </div>
                        <div className="mx-2 my-2 msg-lbl">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Omnis dolores explicabo iure repudiandae, quo
                          repellat. Reiciendis facere maxime in commodi
                          voluptatibus quos hic optio, culpa magni, a, dolorem
                          recusandae cumque.
                        </div>

                        {!showRes ? (
                          <>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(true)}
                            >
                              <div
                                className="first-load"
                                style={{ width: "70%", background: "white" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>Journal Writing</label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(true)}
                            >
                              <div
                                className="first-load"
                                style={{
                                  width: "30%",
                                  backgroundColor: "white",
                                }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>One on One Discussion</label>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(false)}
                            >
                              <div
                                className="first-load fi-bg "
                                style={{ width: "70%" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>Journal Writing</label>
                                  <label>70%</label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(false)}
                            >
                              <div
                                className="first-load fi-bg "
                                style={{ width: "30%" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>One on One Discussion</label>
                                  <label>30%</label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="d-flex justify-content-between time-lbl ">
                          <label>Here is the result so far</label>
                          <label>11:00 Am</label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {loading ? <div className="loader_orange"></div> : <></>}

                  {/* ************* Add PDF and Image Dropdown ********************** */}

                  <Dropdown className="float-end">
                    <Dropdown.Menu>
                      <p className="mb-2" onClick={() => openModal()}>
                        <i class="fa fa-plus me-2"></i>
                        Create Events
                      </p>

                      <p className="mb-2" onClick={() => openPollModal()}>
                        <i class="fa fa-plus me-2"></i>
                        Create Poll
                      </p>

                      <p className="mb-0" onClick={() => openQuizModal()}>
                        <i class="fa fa-plus me-2"></i>
                        Create Quiz
                      </p>
                    </Dropdown.Menu>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <div className="icon-div me-0">
                        <i class="fa fa-plus"></i>
                      </div>
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </>
            ) : activeIndex === 2 ? (
              <>
                <div className="scroller group-chatbox">
                  {!MediaList?.length ? (
                    // <h4 className="py-3">Data not found</h4>
                    <div className="alert alert-warning my-3" role="alert">
                      Data not found
                    </div>
                  ) : (
                    MediaList?.length &&
                    MediaList?.map((val, i) => {
                      return (
                        <div
                          key={i}
                          className="scroller-content"
                          id="scrollerContent"
                        >
                          <div className="item my-3 send-msg">
                            {val?.type === "pdf" ? (
                              <div
                                className="msg-card sent-mssg-card"
                                style={{ background: "#E0DAD2" }}
                              >
                                <div className="file-div justify-content-start px-2">
                                  <img
                                    src={pdfIcon}
                                    style={{ height: "70px" }}
                                  />
                                  <p
                                    style={{
                                      maxWidth: "100px",
                                      wordWrap: "break-word",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {val?.originalfilename}
                                  </p>
                                </div>
                                <p
                                  className="my-1 mt-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  {val?.originalfilename}
                                </p>
                                <p
                                  className="my-1"
                                  style={{ fontSize: "12px" }}
                                ></p>
                                <p
                                  className="my-1"
                                  style={{ fontSize: "11px" }}
                                >
                                  {/* Today, 12:00PM */}
                                  {moment(val?.createdAt).format("ll")}
                                </p>
                              </div>
                            ) : val?.type === "url" ? (
                              <>
                                <div
                                  className="msg-card sent-mssg-card"
                                  style={{ background: "#E0DAD2" }}
                                >
                                  <div className="file-div justify-content-start px-2">
                                    <a target="_blank" href={val?.mediafile}>
                                      {val?.mediafile}
                                    </a>
                                  </div>
                                  <p
                                    className="my-1"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {moment(val?.createdAt).format("ll")}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <div
                                className="msg-card sent-mssg-card"
                                style={{ background: "#E0DAD2" }}
                              >
                                <div className="file-div justify-content-start px-2">
                                  <img
                                    src={`${baseApi?.baseurl}images/${val?.mediafile}`}
                                    style={{
                                      maxHeight: "70px",
                                      maxWidth: "100px",
                                    }}
                                  />
                                  <span className="ms-2">
                                    {" "}
                                    {val?.originalfilename}
                                  </span>
                                </div>
                                <p
                                  style={{
                                    maxWidth: "100px",
                                    wordWrap: "break-word",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {val?.originalfilename}
                                </p>
                                <p
                                  className="my-1"
                                  style={{ fontSize: "11px" }}
                                >
                                  {moment(val?.createdAt).format("ll")}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}

                  {/* <div className="scroller-content" id="scrollerContent">
                    <div className="item my-3 recive-msg">
                      <div
                        className="msg-card recived-msg-card"
                        style={{ background: "#E0DAD2" }}
                      >
                        <div className="file-div">
                          <img src={pdfIcon} style={{ height: "80px" }} />
                          image.png
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </>
            ) : (
              <></>
            )}

            {/* ==== input box ===== */}
            {activeIndex === 0 ? (
              <div className="chat-input-box chat-input-box-client justify-content-evenly cursor-pointer mb-0">
                {/* <img
                  className="plus-img"
                  src={plusIcon}
                // onClick={() => handleClickMenu(menuVisible)}
                /> */}
                <input
                  className="chat-input-text"
                  type="text"
                  id="uploadFile"
                  accept="image/png, image/gif, image/jpeg"
                  tabIndex="1"
                  title=" "
                  cols={50}
                  value={currMessage}
                  onChange={(event) => setCurrMessage(event.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <button className="send-btn" onClick={() => sendMessage()}>
                  <img src={sendIcon} alt="" />
                </button>
              </div>
            ) : activeIndex === 1 ? (
              <></>
            ) : // <div className="chat-input-box chat-input-box-client justify-content-evenly cursor-pointer mb-0">
            //   <img
            //     className="plus-img"
            //     src={plusIcon}
            //     onClick={() => handleClickMenu(menuVisible)}
            //   />
            //   <input
            //     className="chat-input-text"
            //     type="text"
            //     id="uploadFile"
            //     accept="image/png, image/gif, image/jpeg"
            //     tabIndex="1"
            //     title=" "
            //     cols={50}
            //     disabled
            //     // value={currMessage}
            //     // onChange={(event) => setCurrMessage(event.target.value)}
            //     // onKeyDown={handleKeyPress}
            //   />
            //   <button
            //     className="send-btn"
            //     disabled
            //     // onClick={() => alert("test")}

            //     // onClick={() => alert("Activity")}
            //   >
            //     <img src={sendIcon} alt="" />
            //   </button>
            // </div>
            activeIndex === 2 ? (
              <div className="chat-input-box chat-input-box-client justify-content-evenly cursor-pointer mb-0 group-activity activity-tab">
                {/* <img
                  className="plus-img"
                  src={plusIcon}
                  onClick={() => handleClickMenu2(menuVisible2)}
                /> */}

                <Dropdown className="float-end" show={StateToggle}>
                  <Dropdown.Menu>
                    <p className="mb-2">
                      <i class="fa fa-plus"></i>
                      <input
                        type="file"
                        id="uploadpdf"
                        accept="application/pdf"
                        className="custom-file-input cursor-pointer"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                      <label htmlFor="uploadpdf">Upload pdf</label>
                    </p>

                    <p className="mb-0">
                      <i class="fa fa-plus"></i>
                      <input
                        type="file"
                        id="uploadmedia"
                        className="custom-file-input cursor-pointer"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                      <label htmlFor="uploadmedia">Upload Multimedia</label>
                    </p>
                  </Dropdown.Menu>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <div className="icon-div me-0">
                      <i class="fa fa-plus" onClick={() => ToggleF()}></i>
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
                <input
                  className="chat-input-text"
                  type="text"
                  placeholder="Enter url"
                  tabIndex="1"
                  title=" "
                  // cols={50}
                  value={ResourseUrl}
                  // disabled
                  onChange={(event) => setResourseUrl(event.target.value)}

                  // onChange={(event) => setCurrMessage(event.target.value)}
                  // onKeyDown={handleKeyPress}
                />
                <button
                  className="send-btn"
                  // disabled
                  // onClick={() => alert("Resource")}
                  onClick={(e) => uploadurl(e)}
                >
                  <img src={sendIcon} alt="" />
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* ========================== Event Model =============== */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="pop-up-com-header ">
          <label>Create Event</label>
          <button onClick={closeModal} className="close-btn close-btn-white">
            <img src={plusIconClose} />{" "}
          </button>
        </div>

        <div>
          <p className="commmon-heading mt-2">Event Title</p>
          <input
            type="text"
            placeholder="Enter event title"
            className="mb-3"
            name="EAskQuestion"
            onChange={(e) => handleChangeEvent(e)}
          />
          <p className="text-danger">{formEventErrors?.EAskQuestion}</p>
          <p className="commmon-heading"> Enter event description</p>
          <textarea
            rows="10"
            name="EDescription"
            className="form-input-text-area"
            placeholder="Event Description"
            onChange={(e) => handleChangeEvent(e)}
          ></textarea>
          <p className="text-danger">{formEventErrors?.EDescription}</p>
          {/* <p>Start Time</p> */}
          <div className="d-flex justify-content-around">
            <div>
              <p className="commmon-heading">* Start Time</p>
              <select
                name="ESTime"
                className="time-select"
                style={{ width: "130px" }}
                onChange={(e) => handleChangeEvent(e)}
              >
                {TimeSlotS.map((ele, i) => {
                  return (
                    <option key={i} value={ele}>
                      {ele}
                    </option>
                  );
                })}
              </select>
              <p className="text-danger">{formEventErrors?.ESTime}</p>
            </div>

            <div>
              <p className="commmon-heading">* End Time</p>

              <select
                className="time-select"
                name="EETime"
                style={{ width: "130px" }}
                onChange={(e) => handleChangeEvent(e)}
              >
                {TimeSlotE.map((ele, i) => {
                  return (
                    <>
                      <option key={i} value={ele}>
                        {ele}
                      </option>
                    </>
                  );
                })}
              </select>
              <p className="text-danger">{formEventErrors?.EETime}</p>
            </div>
          </div>
          {/* <input
            type="time"
            placeholder="My Task"
            className="mb-3"
            name="ESTime"
            onChange={(e) => handleChangeEvent(e)}
          /> */}
          {/* <p className="text-danger">{formEventErrors?.ESTime}</p> */}
          <div className="d-flex justify-content-around">
            <div>
              <p className="commmon-heading">Start Date</p>
              <input
                type="date"
                placeholder="My Task"
                className="mb-3"
                style={{ width: "130px", fontSize: "14px" }}
                name="ESDate"
                onChange={(e) => handleChangeEvent(e)}
                min={disablePastDate()}
              />
              <p className="text-danger">{formEventErrors?.ESDate}</p>
            </div>
            {/* <div>
              <p className="commmon-heading">End Date</p>
              <input
                type="date"
                placeholder="My Task"
                style={{ width: "130px", fontSize: "14px" }}
                className="mb-3"
                name="EEDate"
                onChange={(e) => handleChangeEvent(e)}
              />
              <p className="text-danger">{formEventErrors?.EEDate}</p>
            </div> */}
          </div>
        </div>
        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
          onClick={(e) => handleSubmitEvent(e)}
        >
          Publish
        </button>
        {is_loading ? <div className="loader_orange"></div> : <></>}
      </Modal>
      {/* ==================== Poll Model =================== */}
      <Modal
        isOpen={createPollmodalIsOpen}
        onAfterOpen={afterPollOpenModal}
        onRequestClose={closePollModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="pop-up-com-header">
          <label>Create Poll</label>
          <button
            onClick={closePollModal}
            className="close-btn close-btn-white"
          >
            <img src={plusIconClose} />
            {""}
          </button>
        </div>
        <p className="commmon-heading mt-2">Ask Question</p>
        <input
          type="text"
          placeholder="Enter question"
          className="mb-3"
          name="PollQuestion"
          onChange={(e) => handleChangePoll(e)}
        />
        <p className="text-danger">{formPollErrors?.PollQuestion}</p>
        <p className="commmon-heading">Options</p>
        <input
          type="text"
          placeholder="Enter Option 1"
          className="mb-3"
          name="PollOption1"
          onChange={(e) => handleChangePoll(e)}
        />
        <p className="text-danger">{formPollErrors?.PollOption1}</p>
        <input
          type="text"
          placeholder="Enter Option 2"
          className="mb-3"
          name="PollOption2"
          onChange={(e) => handleChangePoll(e)}
        />
        <p className="text-danger">{formPollErrors?.PollOption2}</p>
        <input
          type="text"
          placeholder="Enter Option 3"
          className="mb-3"
          name="PollOption3"
          onChange={(e) => handleChangePoll(e)}
        />
        <p className="text-danger">{formPollErrors?.PollOption3}</p>

        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
          onClick={(e) => handleSubmitPoll(e)}
        >
          Publish
        </button>
        {is_loading ? <div className="loader_orange"></div> : <></>}
      </Modal>

      {/* ============== Quiz Model ===================== */}
      <Modal
        isOpen={createQuizmodalIsOpen}
        onAfterOpen={afterQuizOpenModal}
        onRequestClose={closeQuizModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="pop-up-com-header ">
          <label>Create Quiz</label>
          <button
            onClick={closeQuizModal}
            className="close-btn close-btn-white"
          >
            <img src={plusIconClose} />{" "}
          </button>
        </div>
        <p className="commmon-heading mt-2">Ask Question</p>
        <input
          type="text"
          placeholder="Enter question"
          className="mb-3"
          name="QuizQuestion"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizQuestion}</p>
        <p className="commmon-heading">Options</p>
        <input
          type="text"
          placeholder="Enter Option 1"
          className="mb-3"
          name="QuizOption1"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizOption1}</p>
        <input
          type="text"
          placeholder="Enter Option 2"
          className="mb-3"
          name="QuizOption2"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizOption2}</p>
        <input
          type="text"
          placeholder="Enter Option 3"
          className="mb-3"
          name="QuizOption3"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizOption3}</p>
        <p className="commmon-heading">Correct Answer</p>
        <input
          type="text"
          placeholder="Enter currect answer"
          className="mb-3"
          name="QuizAnswer"
          onChange={(e) => handleChangeQuiz(e)}
        />
        <p className="text-danger">{formQuizErrors?.QuizAnswer}</p>

        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
          onClick={(e) => handleSubmitQuiz(e)}
        >
          Publish
        </button>
        {is_loading ? <div className="loader_orange"></div> : <></>}
      </Modal>
    </div>
  );
};

export default ChatBox;
