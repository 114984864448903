import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userImg from "../../assets/images/icons/userIMG.jpg";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import baseApi from "../../Api/config";
import { toast } from "react-toastify";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import moment from "moment";

const ProfileEdit = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("TherapistUserData"));
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [UserInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateofbirth, setdateofbirth] = useState("");

  useEffect(() => {
    setLoading(true);
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          console.log("GetProfileData", response?.data?.data);
          setUserInfo(response?.data?.data);
          setLoading(false);
          setFormData({ ...formData, Gender: response?.data?.data?.gender });
          setdateofbirth(
            moment(response?.data?.data?.dob).format("YYYY-MM-DD")
          );
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const initialValues = {
    Name: UserInfo?.fullname
      ? UserInfo?.fullname
      : UserData?.userdata?.fullname,
    Email: UserInfo?.email ? UserInfo?.email : UserData?.userdata?.email,
    PhoneNumber: UserInfo?.phone ? UserInfo?.phone : UserData?.userdata?.phone,
    // Age: UserInfo?.age ? UserInfo?.age : UserData?.userdata?.age,
    // DOB: dateofbirth ? dateofbirth : UserData?.userdata?.dob,
    Gender: UserInfo?.gender ? UserInfo?.gender : UserData?.userdata?.gender,
    YearOfExp: UserInfo?.experience
      ? UserInfo?.experience
      : UserData?.userdata?.experience,
    Expertise: UserInfo.experties
      ? UserInfo.experties
      : UserData?.userdata?.experties,
    Quote: UserInfo?.qote ? UserInfo?.qote : UserData?.userdata?.qote,
    Image: UserInfo?.image ? UserInfo?.image : UserData?.userdata?.image,
  };

  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleUpload = (e) => {
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const RegisterValidate = (values) => {
    const errors = {};

    if (!values?.Name) {
      errors.Name = "Full Name is required";
    }
    // if (!values?.Age) {
    //   errors.Age = "Age is required";
    // }
    if (!dateofbirth) {
      errors.dateofbirth = "DOB is required";
    }
    if (!values?.Gender) {
      errors.Gender = "Gender is required";
    }
    if (!values?.YearOfExp) {
      errors.YearOfExp = "Experience is required";
    }
    if (values?.YearOfExp?.length > 2) {
      errors.YearOfExp = "Invalid Years!";
    }
    if (!values?.Expertise) {
      errors.Expertise = "Expertise is required";
    }
    if (values?.Expertise?.length >= 50) {
      errors.Expertise = "Character length exceeds 50";
    }
    if (!values?.Quote) {
      errors.Quote = "Quote is required";
    }
    if (values?.Quote?.length >= 50) {
      errors.Quote = "Character length exceeds 50";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        API.Profile.UpdateProfileAPI({
          data: {
            fullname: formData?.Name ? formData?.Name : UserInfo?.fullname,
            email: formData?.Email ? formData?.Email : UserInfo?.email,
            phone: formData?.PhoneNumber
              ? formData?.PhoneNumber
              : UserInfo?.phone,
            dob: dateofbirth,
            // age: formData?.Age ? formData?.Age : UserInfo?.age,
            gender: formData?.Gender ? formData?.Gender : UserInfo?.gender,
            experience: formData?.YearOfExp
              ? formData?.YearOfExp
              : UserInfo?.experience,
            experties: formData?.Expertise
              ? formData?.Expertise
              : UserInfo?.experties,
            qote: formData?.Quote ? formData?.Quote : UserInfo?.qote,
            image: formData?.Image
              ? formData?.Image
              : UserInfo?.image
              ? UserInfo?.image
              : ProfileImage,
          },
          UserId: UserInfo?._id,
        }).then((response) => {
          // console.log(response?.data);
          // toast.success("update successfull");
          navigate(`../${AdminRoute?.Auth?.Dashboard}`);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  useEffect(() => {
    console.log("dateofbirth", dateofbirth);
    console.log("UserInfo?.userdata?.dob", UserInfo?.dob);
  }, [UserInfo]);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content profile-edit">
        <Header></Header>

        <div className="desc-container">
          {loading ? (
            <div className="loader_orange"></div>
          ) : (
            <>
              {" "}
              <div>
                <h3>Edit Profile</h3>
              </div>
              <div className="d-flex justify-content-center position-relative edit-profile-image mx-auto">
                {spinner ? (
                  <>
                    <span className="custom-drag-box">
                      <div
                        className="spinner-grow text-warning mx-auto"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      <input
                        type="file"
                        id="uploadFile"
                        accept="image/png, image/gif, image/jpeg"
                        tabIndex="1"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                    </span>
                  </>
                ) : ProfileImage ? (
                  <>
                    <span className="custom-drag-box">
                      <div className="d-flex flex-column align-items-center w-100 h-100">
                        <i
                          className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                          aria-hidden="true"
                        ></i>
                        <div
                          className="form-upload-photo-preview2"
                          style={{
                            backgroundImage: `url(${
                              UserInfo?.image ? UserInfo?.image : ProfileImage
                            })`,
                          }}
                        ></div>
                        <span className="custom-drag-box-text d-none">
                          Image Uploaded
                        </span>
                        <input
                          type="file"
                          id="uploadFile"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          tabIndex="1"
                          title=" "
                          onChange={(e) => handleUpload(e)}
                        />
                      </div>
                    </span>
                  </>
                ) : (
                  <>
                    <p className="edit-icon position-absolute">
                      <i className="fa fa-edit"></i>
                    </p>
                    <span className="custom-drag-box">
                      <img
                        className="reg-user-img"
                        src={UserInfo?.image ? UserInfo?.image : userImg}
                      />
                      <input
                        type="file"
                        id="uploadFile"
                        accept="image/png, image/gif, image/jpeg"
                        tabIndex="1"
                        title=" "
                        onChange={(e) => handleUpload(e)}
                      />
                    </span>
                  </>
                )}
              </div>
              <div className="input-container">
                <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> Full Name
                  </label>
                  <input
                    type="text"
                    name="Name"
                    defaultValue={UserInfo?.fullname}
                    placeholder="Enter your full Name"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <p className="text-danger mb-0">{formErrors?.Name}</p>
                </div>

                <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> EmailId
                  </label>
                  <input
                    type="email"
                    name="Email"
                    defaultValue={UserInfo?.email}
                    placeholder="Enter your E-Mail ID"
                    readOnly
                  />
                  <p className="text-danger mb-0">{formErrors?.Email}</p>
                </div>
                <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> Contact Number
                  </label>
                  <input
                    type="number"
                    name="PhoneNumber"
                    defaultValue={UserInfo?.phone}
                    placeholder="Enter your contact number"
                    readOnly
                  />
                  <p className="text-danger mb-0">{formErrors?.PhoneNumber}</p>
                </div>

                {/* <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> Age
                  </label>
                  <input
                    type="text"
                    name="Age"
                    defaultValue={UserInfo?.age}
                    placeholder="Enter your age"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <p className="text-danger mb-0">{formErrors?.Age}</p>
                </div> */}

                <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> DOB
                  </label>
                  <input
                    type="date"
                    name="dateofbirth"
                    // defaultValue={moment(UserData?.userdata?.dob).format("L")}
                    value={dateofbirth}
                    required
                    onChange={(e) => setdateofbirth(e.target.value)}
                  />
                  <p className="text-danger mb-0">{formErrors?.DOB}</p>
                </div>

                {/* <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> Gender
                  </label>
                  <input
                    type="text"
                    name="Gender"
                    defaultValue={UserInfo?.gender}
                    placeholder="Enter your gender"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <p className="text-danger mb-0">{formErrors?.Gender}</p>
                </div> */}

                <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> Gender
                  </label>
                  {/* <input
                    type="text"
                    name="Gender"
                    defaultValue={UserInfo?.gender}
                    placeholder="Enter your gender"
                    required
                    onChange={(e) => handleChange(e)}
                  /> */}

                  <select
                    name="Gender"
                    className="gender-select"
                    value={formData?.Gender}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Your Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  <p className="text-danger mb-0">{formErrors?.Gender}</p>
                </div>

                <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> Years of experince
                  </label>
                  <input
                    type="text"
                    name="YearOfExp"
                    defaultValue={UserInfo?.experience}
                    placeholder="Enter your years of experince"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <p className="text-danger mb-0">{formErrors?.YearOfExp}</p>
                </div>

                <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> Expertise
                  </label>
                  <textarea
                    type="text"
                    name="Expertise"
                    defaultValue={UserInfo?.experties}
                    rows={5}
                    className="form-input-text-area"
                    placeholder="Enter your expertise"
                    required
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                  <p className="text-danger mb-0">{formErrors?.Expertise}</p>
                </div>

                <div className="form-el mt-2 px-0">
                  <label className="pb-2">
                    <span className="text-danger">*</span> Quote you resonate
                    with
                  </label>
                  <input
                    type="text"
                    defaultValue={UserInfo?.qote}
                    name="Quote"
                    placeholder="Enter quote you resonate with"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  <p className="text-danger mb-0">{formErrors?.Quote}</p>
                </div>
              </div>
              <div className="bottom-btn-container mt-3">
                <button className="sec-btn" onClick={(e) => handleSubmit(e)}>
                  Save Changes
                </button>
                {spinner ? <div className="loader_orange"></div> : <></>}
              </div>
            </>
          )}
        </div>
      </div>
      <MobMenuBar></MobMenuBar>
    </div>
  );
};

export default ProfileEdit;
